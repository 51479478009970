// import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
// import { db } from '../firebase'
// import {
//     query,
//     collection,
//     onSnapshot,
//     updateDoc,
//     doc,
//     addDoc,
//     deleteDoc,
//     setDoc,
// } from 'firebase/firestore'
// import { Dialog, Transition } from '@headlessui/react'
// import { StoryContext } from '/AppStoryPlayer'
// import { useParams, useLocation } from "react-router-dom";
// import Slider from 'react-input-slider'

// const StoryEditCamera = ({ scene, updateSceneFunction, activeIndex }) => {

//     const { storyId } = useParams()
//     const [sceneCamoffsetPositionZ, setSceneCamoffsetPositionZ] = useState(scene.camOffset.position.z || 0)
//     const [sceneCamoffsetTargetZ, setSceneCamoffsetTargetZ] = useState(scene.camOffset.target.z || 0)
//     const [sceneLayout, setSceneLayout] = useState(scene.layout || false)
//     const [camPosXY, setCamPosXY] = useState({ x: scene.camOffset.position.x, y: scene.camOffset.position.y })
//     const [camTarXY, setCamTarXY] = useState({ x: scene.camOffset.target.x, y: scene.camOffset.target.y })

//     useEffect(() => {
//         setSceneLayout(scene.layout || false)
//         setCamPosXY({ x: scene.camOffset.position.x || 0, y: scene.camOffset.position.y || 0 })
//         setCamTarXY({ x: scene.camOffset.target.x || 0, y: scene.camOffset.target.y || 0 })
//         setSceneCamoffsetPositionZ(scene.camOffset.position.z || 0)
//     }, [scene]);


//     const SelectCameraPreset = (pz, px, py, tx, ty) => {
//         setSceneCamoffsetPositionZ(pz)
//         setCamPosXY({ x: px, y: py })
//         setCamTarXY({ x: tx, y: ty })
//     }


//     const updateSceneName = () => {
//         scene.camOffset.position.x = camPosXY.x //sceneCamoffsetPositionX
//         scene.camOffset.position.y = camPosXY.y //sceneCamoffsetPositionY
//         scene.camOffset.position.z = sceneCamoffsetPositionZ
//         scene.camOffset.target = camTarXY // { x: tx, y: ty }
//         scene.camOffset.target.z = sceneCamoffsetTargetZ
//         scene.layout = sceneLayout // true
//         //scene.platformPosition = 'manual'
//         const sceneRef = doc(db, 'stories', storyId, 'scenes', scene.id);
//         updateDoc(sceneRef, scene)
//         updateSceneFunction(activeIndex)
//     }

//     const checkHandlerLayout = (value) => {
//         setSceneLayout(value)
//     }

//     useEffect(() => {
//         updateSceneName()
//     }, [sceneLayout]);

//     return (
//         <div className="w-full">
//             <div className="pb-4 ">

//                 <div className="flex rounded-md w-full p-1 bg-white/5 gap-1 ">
//                     <div
//                         className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
//                           ${!sceneLayout ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
//                         onClick={() => {
//                             checkHandlerLayout(false)
//                         }}>
//                         Automatic
//                     </div>
//                     <div
//                         className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
//                           ${sceneLayout ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}

//                         onClick={() => {
//                             checkHandlerLayout(true)
//                         }}>
//                         Manual
//                     </div>
//                 </div>

//                 {sceneLayout ?
//                     <div>

//                         <div className="grid grid-cols-5 gap-1 mb-8 mt-4 text-xs items-stretch">
//                             <div className="bg-white/5  text-gray-50 rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1, 0, 140, 0, 0)}>
//                                 <img src="/util/app/cam-top-close.png" className="w-full rounded-sm " />
//                                 <div className="p-2">Top Close</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1, 0, 800, 0, 0)}>
//                                 <img src="/util/app/cam-top-far.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Top Far</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50   rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(600, 0, 20, 0, 0)}>
//                                 <img src="/util/app/cam-side-far.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Side Far</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, 0, -90, 0, 0)}>
//                                 <img src="/util/app/cam-side-close.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Side Close</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(260, 0, -10, 0, -5)}>
//                                 <img src="/util/app/cam-side-close-2.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Side Close 2</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(550, -550, 550, 0, 0)}>
//                                 <img src="/util/app/cam-left-corner-far.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Left Corner Far</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, -240, 240, 0, 0)}>
//                                 <img src="/util/app/cam-left-corner-close.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Left Corner Close</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(550, 550, 550, 0, 0)}>
//                                 <img src="/util/app/cam-right-corner-far.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Right Corner Far</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, 240, 240, 0, 0)}>
//                                 <img src="/util/app/cam-right-corner-close.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Right Corner Close</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(300, 0, 0, 0, 0)}>
//                                 <img src="/util/app/cam-default.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Reset</div>
//                             </div>
//                         </div>

//                         <button
//                             type="button"
//                             className="w-full justify-center rounded-md  px-3 py-2
//                       font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
//                       focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
//                             onClick={() => {
//                                 updateSceneName();
//                             }}
//                         >
//                             Update
//                         </button>


//                     </div>

//                     : null}


//             </div>

//         </div >

//     );
// };


// export default StoryEditCamera



import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
    setDoc,
} from 'firebase/firestore'
import { Dialog, Transition } from '@headlessui/react'
import { StoryContext } from '/AppStoryPlayer'
import { useParams, useLocation } from "react-router-dom";
import Slider from 'react-input-slider'
//import { useThree } from "@react-three/fiber";
//import { set } from "animejs";

const StoryEditCamera = ({ scene, updateSceneFunction, activeIndex }) => {

    // const {
    //     camera,
    //     gl: { domElement },
    //   } = useThree();

    const { storyId } = useParams()
    const [sceneCamoffsetPositionZ, setSceneCamoffsetPositionZ] = useState(scene.camOffset.position.z || 0)
    const [sceneCamoffsetTargetZ, setSceneCamoffsetTargetZ] = useState(scene.camOffset.target.z || 0)
    const [sceneLayout, setSceneLayout] = useState(scene.layout || false)
    const [camPosXY, setCamPosXY] = useState({ x: scene.camOffset.position.x, y: scene.camOffset.position.y })
    const [camTarXY, setCamTarXY] = useState({ x: scene.camOffset.target.x, y: scene.camOffset.target.y })

    useEffect(() => {
        setSceneLayout(scene.layout || false)
        setCamPosXY({ x: scene.camOffset.position.x || 0, y: scene.camOffset.position.y || 0 })
        setCamTarXY({ x: scene.camOffset.target.x || 0, y: scene.camOffset.target.y || 0 })
        setSceneCamoffsetPositionZ(scene.camOffset.position.z || 0)
    }, [scene]);


    const SelectCameraPreset = (pz, px, py, tx, ty) => {
        setSceneCamoffsetPositionZ(pz)
        setCamPosXY({ x: px, y: py })
        setCamTarXY({ x: tx, y: ty })
        // setTimeout(() => {
        //     updateSceneName()
        // }, 1000);
    }


    // const [camPosXYOld, setCamPosXYOld] = useState({ x: scene.camOffset.position.x, y: scene.camOffset.position.y })

    // useEffect(() => {
    //     //console.log('NEW camOffset')
    //     if (camPosXY.x == camPosXYOld.x && camPosXY.y == camPosXYOld.y) {

    //     }
    //     else {
    //         updateSceneName()
    //     }
    //     setCamPosXYOld(camPosXY)
    // }, [camPosXY]);


    const updateSceneName = () => {
        scene.camOffset.position.x = camPosXY.x //sceneCamoffsetPositionX
        scene.camOffset.position.y = camPosXY.y //sceneCamoffsetPositionY
        scene.camOffset.position.z = sceneCamoffsetPositionZ
        scene.camOffset.target = camTarXY // { x: tx, y: ty }
        scene.camOffset.target.z = sceneCamoffsetTargetZ
        scene.layout = sceneLayout // true
        const sceneRef = doc(db, 'stories', storyId, 'scenes', scene.id);
        updateDoc(sceneRef, scene)
        updateSceneFunction(activeIndex)
    }

    // const deleteScene = async () => {
    //     const sceneRef = doc(db, 'stories', storyId, 'scenes', scene.id);
    //     await deleteDoc(sceneRef)
    //     setOpenSceneEdit(false)
    // }

    // const checkHandler = () => {
    //     setSceneNavMainOmit(!sceneNavMainOmit)
    // }

    const checkHandlerLayout = (value) => {
        setSceneLayout(value)
        // console.log('Scene Layout')
        // console.log(sceneLayout)
    }

    useEffect(() => {
        updateSceneName()
    }, [sceneLayout]);

    return (
        <div className="w-full">
            <div className="pb-4 ">

                {/* <div className="flex gap-4 mb-2 text-sm text-red-300">
                    {scene.id}
                </div> */}

                <div className="flex rounded-md w-full p-1 bg-white/5 gap-1 ">
                    <div
                        // className={`flex-auto  text-center  items-center rounded-md  hover:bg-white/10 px-3 py-2 text-sm  hover:bg-gray-50  cursor-pointer
                        //   ${!sceneLayout ? 'bg-white/10 text-gray-200 font-semibold' : ' text-gray-300'}`}

                        className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                          ${!sceneLayout ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                        onClick={() => {
                            checkHandlerLayout(false)
                        }}>
                        Automatic
                    </div>
                    <div
                        className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                          ${sceneLayout ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}

                        onClick={() => {
                            checkHandlerLayout(true)
                        }}>
                        Manual
                    </div>
                </div>

                {sceneLayout ?
                    <div>

                        <div className="grid grid-cols-5 gap-1 mb-8 mt-4 text-xs items-stretch">
                            <div className="bg-white/5  text-gray-50 rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1, 0, 140, 0, 0)}>
                                <img src="/util/app/cam-top-close.png" className="w-full rounded-sm " />
                                <div className="p-2">Top Close</div>
                            </div>
                            <div className="bg-white/5 text-gray-50  rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1, 0, 800, 0, 0)}>
                                <img src="/util/app/cam-top-far.png" className="w-full rounded-sm" />
                                <div className="p-2">Top Far</div>
                            </div>
                            <div className="bg-white/5 text-gray-50   rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(600, 0, 20, 0, 0)}>
                                <img src="/util/app/cam-side-far.png" className="w-full rounded-sm" />
                                <div className="p-2">Side Far</div>
                            </div>
                            <div className="bg-white/5 text-gray-50  rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, 0, -90, 0, 0)}>
                                <img src="/util/app/cam-side-close.png" className="w-full rounded-sm" />
                                <div className="p-2">Side Close</div>
                            </div>
                            <div className="bg-white/5 text-gray-50 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(260, 0, -10, 0, -5)}>
                                <img src="/util/app/cam-side-close-2.png" className="w-full rounded-sm" />
                                <div className="p-2">Side Close 2</div>
                            </div>
                            <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(550, -550, 550, 0, 0)}>
                                <img src="/util/app/cam-left-corner-far.png" className="w-full rounded-sm" />
                                <div className="p-2">Left Corner Far</div>
                            </div>
                            <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, -240, 240, 0, 0)}>
                                <img src="/util/app/cam-left-corner-close.png" className="w-full rounded-sm" />
                                <div className="p-2">Left Corner Close</div>
                            </div>
                            <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(550, 550, 550, 0, 0)}>
                                <img src="/util/app/cam-right-corner-far.png" className="w-full rounded-sm" />
                                <div className="p-2">Right Corner Far</div>
                            </div>
                            <div className="bg-white/5 text-gray-50 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, 240, 240, 0, 0)}>
                                <img src="/util/app/cam-right-corner-close.png" className="w-full rounded-sm" />
                                <div className="p-2">Right Corner Close</div>
                            </div>
                            <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(300, 0, 0, 0, 0)}>
                                <img src="/util/app/cam-default.png" className="w-full rounded-sm" />
                                <div className="p-2">Reset</div>
                            </div>
                        </div>

                        <div className="  mt-8  ">
                            <div className="col-span-1">

                                <div className="flex mt-2 mb-2 justify-between">
                                    <div className="text-gray-400 font-base text-sm">
                                        Camera Position
                                    </div>
                                    <div className="text-gray-400 font-base text-sm">
                                        {camPosXY.x}, {camPosXY.y}
                                    </div>
                                </div>
                                <div className="w-100p  slider-parent ">
                                    <Slider
                                        axis="xy"
                                        xmin={-800}
                                        xmax={800}
                                        ymin={-820}
                                        ymax={820}
                                        xreverse={false}
                                        yreverse={true}
                                        xstep={10}
                                        ystep={5}
                                        x={camPosXY.x}
                                        y={camPosXY.y}
                                        onChange={setCamPosXY}
                                        styles={{
                                            active: {
                                                backgroundColor: '#333'
                                            },
                                            track: {
                                                backgroundColor: '#1b1e21'
                                            }
                                        }}
                                    />
                                </div>

                            </div>  
                             <div className="col-span-1 hiddden">

                                <div className="flex mt-2 mb-2 justify-between">
                                    <div className="text-gray-400 font-base text-sm">
                                        Target Focus
                                    </div>
                                    <div className="text-gray-400 font-base text-sm">
                                        {camTarXY.x}, {camTarXY.y}
                                    </div>
                                </div>

                                <div className="w-100p  slider-parent "
                                    style={{ backgroundPositionX: 'center' }}
                                >
                                    <Slider
                                        axis="xy"
                                        xmin={-800}
                                        xmax={800}
                                        ymin={-80}
                                        ymax={80}
                                        xstep={10}
                                        ystep={5}
                                        xreverse={false}
                                        yreverse={true}
                                        x={camTarXY.x}
                                        y={camTarXY.y}
                                        onChange={setCamTarXY}
                                        styles={{
                                            active: {
                                                backgroundColor: '#333'
                                            },
                                            track: {
                                                backgroundColor: '#1b1e21'
                                            }
                                        }}
                                    />

                                </div>

                                <div className=" ">
                                    <div className="sm:col-span-1 text-center mt-4 hidden">
                                        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-300">
                                            Z
                                        </label>
                                    </div>
                                    <div className="sm:col-span-2 hidden">
                                        <div className="mt-2">


                                            <input
                                                type="text"
                                                className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
  ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
                                                value={sceneCamoffsetTargetZ}
                                                onChange={(e) => setSceneCamoffsetTargetZ(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> 

                            <div className="w-full col-span-2 mb-5">
                                <div className="  mt-8 mb-2">
                                    <p className="text-gray-400 font-base text-sm">Distance from Platform (not zoom) {sceneCamoffsetPositionZ}</p>
                                </div>
                                <div className="flex text-gray-300 font-semibold text-2xl gap-4">

                                    <div className='  h-9 w-12 mt-0.5 p-0.5 text-center text-lg  cursor-pointer  bg-zinc-300/5 hover:bg-white/10 rounded-full'
                                        onClick={(e) =>
                                            sceneCamoffsetPositionZ >= 10 ? setSceneCamoffsetPositionZ(parseInt(sceneCamoffsetPositionZ) - 10) : 0
                                        }
                                    >-</div>

                                    <div className="w-100p ">
                                        <Slider
                                            axis="x"
                                            xmin={1}
                                            xmax={1000}
                                            xstep={10}
                                            xreverse={false}
                                            x={sceneCamoffsetPositionZ}
                                            onChange={({ x }) => setSceneCamoffsetPositionZ(x)}
                                            styles={{
                                                active: {
                                                    backgroundColor: '#333'
                                                },
                                                track: {
                                                    backgroundColor: '#1b1e21',
                                                    width: '100%'
                                                }
                                            }}
                                        />
                                    </div>
 
                                    <div className='  h-9 w-12 mt-0.5 p-0.5 text-center text-lg  cursor-pointer  bg-zinc-300/5 hover:bg-white/10 rounded-full'
                                        onClick={(e) =>
                                            sceneCamoffsetPositionZ <= 1000 ? setSceneCamoffsetPositionZ(parseInt(sceneCamoffsetPositionZ) + 10) : 0
                                        }>
                                        +
                                    </div>

                                </div>

                                
                            </div>



                        </div>



                        <button
                            type="button"
                            className="w-full justify-center rounded-md  px-3 py-2
                      font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                            onClick={() => {
                                updateSceneName();
                            }}
                        >
                            Update
                        </button>


                    </div>

                    : null}


            </div>
 

        </div >

    );
};


export default StoryEditCamera










// import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
// import { db } from '../firebase'
// import {
//     query,
//     collection,
//     onSnapshot,
//     updateDoc,
//     doc,
//     addDoc,
//     deleteDoc,
//     setDoc,
// } from 'firebase/firestore'
// import { Dialog, Transition } from '@headlessui/react'
// import { StoryContext } from '/AppStoryPlayer'
// import { useParams, useLocation } from "react-router-dom";
// import Slider from 'react-input-slider'

// const StoryEditCamera = ({ scene, updateSceneFunction, activeIndex }) => {

//     const { storyId } = useParams()
//     const [sceneCamoffsetPositionZ, setSceneCamoffsetPositionZ] = useState(scene.camOffset.position.z || 0)
//     const [sceneCamoffsetTargetZ, setSceneCamoffsetTargetZ] = useState(scene.camOffset.target.z || 0)
//     const [sceneLayout, setSceneLayout] = useState(scene.layout || false)
//     const [camPosXY, setCamPosXY] = useState({ x: scene.camOffset.position.x, y: scene.camOffset.position.y })
//     const [camTarXY, setCamTarXY] = useState({ x: scene.camOffset.target.x, y: scene.camOffset.target.y })

//     useEffect(() => {
//         setSceneLayout(scene.layout || false)
//         setCamPosXY({ x: scene.camOffset.position.x || 0, y: scene.camOffset.position.y || 0 })
//         setCamTarXY({ x: scene.camOffset.target.x || 0, y: scene.camOffset.target.y || 0 })
//         setSceneCamoffsetPositionZ(scene.camOffset.position.z || 0)
//     }, [scene]);


//     const SelectCameraPreset = (pz, px, py, tx, ty) => {
//         setSceneCamoffsetPositionZ(pz)
//         setCamPosXY({ x: px, y: py })
//         setCamTarXY({ x: tx, y: ty })
//     }


//     const updateSceneName = () => {
//         scene.camOffset.position.x = camPosXY.x //sceneCamoffsetPositionX
//         scene.camOffset.position.y = camPosXY.y //sceneCamoffsetPositionY
//         scene.camOffset.position.z = sceneCamoffsetPositionZ
//         scene.camOffset.target = camTarXY // { x: tx, y: ty }
//         scene.camOffset.target.z = sceneCamoffsetTargetZ
//         scene.layout = sceneLayout // true
//         //scene.platformPosition = 'manual'
//         const sceneRef = doc(db, 'stories', storyId, 'scenes', scene.id);
//         updateDoc(sceneRef, scene)
//         updateSceneFunction(activeIndex)
//     }

//     const checkHandlerLayout = (value) => {
//         setSceneLayout(value)
//     }

//     useEffect(() => {
//         updateSceneName()
//     }, [sceneLayout]);

//     return (
//         <div className="w-full">
//             <div className="pb-4 ">

//                 <div className="flex rounded-md w-full p-1 bg-white/5 gap-1 ">
//                     <div
//                         className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
//                           ${!sceneLayout ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}
//                         onClick={() => {
//                             checkHandlerLayout(false)
//                         }}>
//                         Automatic
//                     </div>
//                     <div
//                         className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
//                           ${sceneLayout ? 'bg-sky-600 text-gray-200 font-semibold' : ' text-gray-300 hover:bg-sky-600/50 '}`}

//                         onClick={() => {
//                             checkHandlerLayout(true)
//                         }}>
//                         Manual
//                     </div>
//                 </div>

//                 {sceneLayout == 'ignore' ?
//                     <div>

//                         <div className="grid grid-cols-5 gap-1 mb-8 mt-4 text-xs items-stretch">
//                             <div className="bg-white/5  text-gray-50 rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1, 0, 140, 0, 0)}>
//                                 <img src="/util/app/cam-top-close.png" className="w-full rounded-sm " />
//                                 <div className="p-2">Top Close</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1, 0, 800, 0, 0)}>
//                                 <img src="/util/app/cam-top-far.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Top Far</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50   rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(600, 0, 20, 0, 0)}>
//                                 <img src="/util/app/cam-side-far.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Side Far</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded overflow-hidden text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, 0, -90, 0, 0)}>
//                                 <img src="/util/app/cam-side-close.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Side Close</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(260, 0, -10, 0, -5)}>
//                                 <img src="/util/app/cam-side-close-2.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Side Close 2</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(550, -550, 550, 0, 0)}>
//                                 <img src="/util/app/cam-left-corner-far.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Left Corner Far</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, -240, 240, 0, 0)}>
//                                 <img src="/util/app/cam-left-corner-close.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Left Corner Close</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(550, 550, 550, 0, 0)}>
//                                 <img src="/util/app/cam-right-corner-far.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Right Corner Far</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(240, 240, 240, 0, 0)}>
//                                 <img src="/util/app/cam-right-corner-close.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Right Corner Close</div>
//                             </div>
//                             <div className="bg-white/5 text-gray-50  rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(300, 0, 0, 0, 0)}>
//                                 <img src="/util/app/cam-default.png" className="w-full rounded-sm" />
//                                 <div className="p-2">Reset</div>
//                             </div>
//                         </div>

//                         <button
//                             type="button"
//                             className="w-full justify-center rounded-md  px-3 py-2
//                       font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
//                       focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
//                             onClick={() => {
//                                 updateSceneName();
//                             }}
//                         >
//                             Update
//                         </button>


//                     </div>

//                     : null}


//             </div>

//         </div >

//     );
// };


// export default StoryEditCamera






