import React, { useRef } from 'react'
import { useGLTF, Float, useTexture } from '@react-three/drei'
import * as THREE from 'three'

export function ExpModelMatcap(props) {
  const groupRef = useRef()
  const { nodes } = useGLTF(`/components/${props.model}.glb`)
  
  const textureFile = props.matcap
    ? props.matcap.includes('google')
      ? props.matcap
      : `/util/matcap/${props.matcap}.png`
    : 'MC-Sample6.png'
  
  const matcapTexture = useTexture(textureFile)

  const ModelMesh = ({ animation }) => (
    <mesh 
      geometry={nodes[`${props.model}`].geometry} 
      position={props.position} 
      scale={animation ? [1, 1, 1] : (props.scale || 1)}
    >
      <meshMatcapMaterial matcap={matcapTexture} />
    </mesh>
  )

  return (
    <>
      {props.animation ? (
        <Float
          speed={2}
          rotationIntensity={0}
          floatIntensity={1}
          floatingRange={[2, 4]}
        >
          <group ref={groupRef} dispose={null}>
            <ModelMesh animation={true} />
          </group>
        </Float>
      ) : (
        <group ref={groupRef} dispose={null}>
          <ModelMesh animation={false} />
        </group>
      )}
    </>
  )
}

// import React, { useEffect, useRef, useState } from 'react'
// import { useGLTF, useMatcapTexture, Float } from '@react-three/drei'
// import * as THREE from 'three'
// import { useTexture } from '@react-three/drei'
// import { text } from 'd3'


// export function ExpModelMatcap(props) {

//     const groupRef = useRef()
//     const { nodes, materials } = useGLTF('/components/' + props.model + '.glb')
//     let materialNew = new THREE.MeshMatcapMaterial()
//     const [material, setMaterial] = useState(materialNew)

//     //const [matcapTexture] = useMatcapTexture("C9C7BE_55514B_888279_7B6E5F", 1024);

//     // if (props.matcap) {
//     //     const matcapTexture = useTexture('/util/matcap/' + props.matcap + '.png');
//     //     material.matcap = matcapTexture
//     // }
//     let textureFile = 'MC-Sample6.png'
//     if (props.matcap != '') {
//         // const matcapTexture = useTexture('/util/matcap/' + textureFile);
//         // material.matcap = matcapTexture
//         textureFile = props.matcap.includes('google')
//             ? props.matcap
//             : '/util/matcap/' + props.matcap + '.png';
//     }
//     const matcapTexture = useTexture(textureFile);

//     return (
//         <>
//             {props.animation ?
//                 <Float
//                     speed={2} // Animation speed, defaults to 1
//                     rotationIntensity={0} // XYZ rotation intensity, defaults to 1
//                     floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
//                     floatingRange={[2, 4]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
//                 >
//                     <group ref={groupRef} dispose={null}>
//                         <mesh geometry={nodes[props.model + ''].geometry} position={props.position} scale={[1, 1, 1]}>
//                             <meshMatcapMaterial matcap={matcapTexture} />
//                         </mesh>
//                     </group>
//                 </Float>
//                 : (null)}
//             {!props.animation ?
//                 <group ref={groupRef} dispose={null}>
//                     <mesh geometry={nodes[props.model + ''].geometry} position={props.position} scale={props.scale || 1}>
//                         <meshMatcapMaterial matcap={matcapTexture} />
//                     </mesh>
//                 </group>
//                 : (null)}
//         </>

//     )
// }