import React, { useRef, useState, useContext, Fragment, useEffect, Suspense, CSSProperties, useMemo } from "react";
import { analytics, db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  orderBy,
  doc,
  where,
  addDoc,
  deleteDoc, serverTimestamp
} from 'firebase/firestore'
import { Timestamp } from 'firebase/firestore';
import { UserAuth } from '../context/AuthContext';
import BarLoader from "react-spinners/BarLoader";
//import { LineChart, Line, Pie, PieChart, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip, Legend, ResponsiveContainer, ScatterChart, Scatter } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, ScatterChart, Scatter, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
//import { Card, CardHeader, CardContent } from '@/components/ui/card';

import CompInsightsDashboard from "../components/CompInsightsDashboard";

const Insights = (props) => {
  const { user, logOut } = UserAuth();
  //const [timeRange, setTimeRange] = useState(48);

  let [color, setColor] = useState("#0ca5e9")
  const cancelButtonRef = useRef(null)
  const [stories, setStories] = useState([])
  const [shares, setShares] = useState([])
  const [sharesLoaded, setSharesLoaded] = useState(false)
  const [storiesLoaded, setStoriesLoaded] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [analytics, setAnalytics] = useState([])
  const [analyticsCount, setAnalyticsCount] = useState([])
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false)
  const [timeRange, setTimeRange] = useState(48); // Default to 48 hours
  const [selectedShareCodes, setSelectedShareCodes] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      if (user?.uid) {
        const q = query(collection(db, 'shares'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let sharesArr = [];
          querySnapshot.forEach((doc) => {
            sharesArr.push({ ...doc.data(), id: doc.id })
          });
          setShares(sharesArr)
          setSharesLoaded(true)
        });
        return () => unsubscribe()
      }
    }, 250)
  }, [user])

  useEffect(() => {
    setTimeout(() => {
      if (user?.uid) {
        const q = query(collection(db, 'analytics'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let analyticsArr = [];
          querySnapshot.forEach((doc) => {
            analyticsArr.push({ ...doc.data(), id: doc.id })
          });
          console.log('Analytics:', analyticsArr);
          setAnalytics(analyticsArr)
          setTimeout(() => {
            setAnalyticsLoaded(true)
          }, 500)
        });
        return () => unsubscribe()
      }
    }, 250)
  }, [user])

  useEffect(() => {
    setTimeout(() => {
      if (user?.uid) {
        const q = query(collection(db, 'stories'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let storiesArr = [];
          querySnapshot.forEach((doc) => {
            storiesArr.push({ ...doc.data(), id: doc.id })
          });
          setStories(storiesArr);
          setStoriesLoaded(true)
        });
        return () => unsubscribe()
      }
    }, 250)
  }, [user])

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
  };

  const CustomTooltipTime = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip bg-zinc-900 p-4 text-zinc-100 rounded shadow">
          <p className="text-lg text-zinc-200">{data.count}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip bg-zinc-900 p-4 text-zinc-100 rounded shadow">
          <p className="font-semibold">{data.sceneName}</p>
          <p className="text-sm text-zinc-400">Total Time: {formatTime(data.engagementTime)}</p>
          <p className="text-sm text-zinc-400">Total Views: {data.viewCount}</p>
        </div>
      );
    }
    return null;
  };

  const CustomShape = (props) => {
    const { cx, cy, fill, engagementTime, viewCount, maxEngagementTime, maxViewCount } = props;
    const maxSize = 40;
    const minSize = 5;
    const size = minSize + (maxSize - minSize) * (engagementTime / maxEngagementTime) * (viewCount / maxViewCount);
    const minBrightness = 30;
    const maxBrightness = 80;
    const brightness = minBrightness + (maxBrightness - minBrightness) * (engagementTime / maxEngagementTime) * (viewCount / maxViewCount);

    return (
      <circle
        cx={cx}
        cy={cy}
        r={size / 2}
        fill={`hsl(201, 90%, ${brightness}%)`}
        style={{ transition: 'all 0.3s ease' }}
      />
    );
  };

  const TimeRangeSelector = ({ onSelectTimeRange, currentTimeRange }) => (
    <div className="flex space-x-2 mb-4">
      {[
        { label: '24 Hours', value: 24 },
        { label: '48 Hours', value: 48 },
        { label: '1 Week', value: 24 * 7 },
        { label: '1 Month', value: 24 * 30 },
        { label: '3 Months', value: 24 * 90 }
      ].map(({ label, value }) => (
        <button
          key={value}
          type="button"
          onClick={() => onSelectTimeRange(value)}
          className={`
             
            flex rounded items-center justify-center 
            text-sm 
            ${currentTimeRange === value ? 'bg-sky-500 text-sky-100' : 'text-sky-500 bg-black/10  hover:bg-black/20'} 
             
            duration-300 shadow px-4 py-2
          `}
        >
          {label}
        </button>
      ))}
    </div>
  );

  const ShareCodeFilter = ({ shareCodes, selectedCodes, onChange }) => {
    return (
      <div className="flex items-center space-x-2">
        {/* <label className="text-sm text-zinc-400">Filter Share Codes:</label>
        <select
          multiple
          value={selectedCodes}
          onChange={(e) => onChange([...e.target.selectedOptions].map(option => option.value))}
          className="bg-black/20 text-zinc-200 rounded px-2 py-1"
        >
          {shareCodes.map((code) => (
            <option key={code} value={code}>
              {code}
            </option>
          ))}
        </select> */}
      </div>
    );
  };

  const ShareAnalyticsChart = ({ analyticsData, timeRange, selectedShareCodes }) => {
    const chartData = useMemo(() => {
      const now = new Date();
      const pastTime = new Date(now.getTime() - timeRange * 60 * 60 * 1000);

      const parseTimestamp = (input) => {
        const firebaseTimestamp = new Timestamp(
          input.seconds,
          input.nanoseconds
        );
        return firebaseTimestamp.toDate();
      };

      const filteredData = analyticsData.filter(item => {
        const itemDate = parseTimestamp(item.createdAt);
        return itemDate > pastTime && (selectedShareCodes.length === 0 || selectedShareCodes.includes(item.shareCode));
      });

      const shareCodeData = {};
      const timeSlots = {};

      filteredData.forEach(item => {
        const itemDate = parseTimestamp(item.createdAt);
        const hour = itemDate.setMinutes(0, 0, 0);
        
        if (!shareCodeData[item.shareCode]) {
          shareCodeData[item.shareCode] = {};
        }
        
        shareCodeData[item.shareCode][hour] = (shareCodeData[item.shareCode][hour] || 0) + 1;
        timeSlots[hour] = true;
      });

      const sortedTimeSlots = Object.keys(timeSlots).sort((a, b) => parseInt(a) - parseInt(b));

      return sortedTimeSlots.map(timestamp => {
        const dataPoint = {
          time: new Date(parseInt(timestamp)).toLocaleString(),
        };
        Object.keys(shareCodeData).forEach(shareCode => {
          dataPoint[shareCode] = shareCodeData[shareCode][timestamp] || 0;
        });
        return dataPoint;
      });
    }, [analyticsData, timeRange, selectedShareCodes]);

    const shareCodeDataKeys = useMemo(() => {
      return Object.keys(chartData[0] || {}).filter(key => key !== 'time');
    }, [chartData]);

    return (
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} strokeOpacity={0.1} />
          <XAxis 
            dataKey="time" 
            label={{ value: 'Time', position: 'insideBottomRight', offset: -10 }}
            axisLine={false}
          />
          <YAxis
            label={{ value: 'Views', angle: -90, position: 'insideLeft' }}
            axisLine={false}
          />
          <Tooltip />
          <Legend />
          {shareCodeDataKeys.map((shareCode, index) => (
            <Line
              key={shareCode}
              type="monotone"
              dataKey={shareCode}
              stroke={COLORS[index % COLORS.length]}
              //activeDot={{ r: 8 }}
                strokeWidth={3}  strokeDasharray="2 5" dot={{r: 4}} activeDot={{ r: 8 }} 
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const getUniqueShareCodes = useMemo(() => {
    return [...new Set(analytics.map(item => item.shareCode))];
  }, [analytics]);

  const COLORS = ["#48dbfb", "#00d2d3", "#54a0ff", "#22a6b3", "#01a3a4", "#686de0", "#7269ef", "#5f27cd", "#4834d4", "#30336b"];

  const ScenesViewedChart = ({ analyticsData, shareCode, timeRange }) => {
    const chartData = useMemo(() => {
      const now = new Date();
      const pastTime = new Date(now.getTime() - timeRange * 60 * 60 * 1000);

      const parseTimestamp = (input) => {
        const firebaseTimestamp = new Timestamp(
          input.seconds,
          input.nanoseconds
        );
        return firebaseTimestamp.toDate();
      };

      const filteredData = analyticsData.filter(item => {
        if (item.shareCode !== shareCode) return false;
        const itemDate = parseTimestamp(item.createdAt);
        return itemDate > pastTime;
      });

      const hourlyData = {};
      filteredData.forEach(item => {
        const itemDate = parseTimestamp(item.createdAt);
        const hour = itemDate.setMinutes(0, 0, 0);
        hourlyData[hour] = (hourlyData[hour] || 0) + 1;
      });

      return Object.entries(hourlyData)
        .map(([timestamp, count]) => ({
          time: new Date(parseInt(timestamp)),
          count
        }))
        .sort((a, b) => a.time - b.time);
    }, [analyticsData, shareCode, timeRange]);

    const formatXAxis = (tickItem) => {
      const date = new Date(tickItem);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 50,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} strokeOpacity={0.1} />
          <XAxis
            dataKey="time"
            tickFormatter={formatXAxis}
            label={{ value: 'Time', position: 'insideBottomRight', offset: -10 }}
            axisLine={false}
          />
          <YAxis
            label={{ value: 'Count', angle: -90, position: 'insideLeft' }} axisLine={false}
          />
          <Tooltip content={<CustomTooltipTime />} />
          <Line type="monotone" strokeWidth={3} dataKey="count" strokeDasharray="2 5" stroke="#0EA5E9" dot={{r: 4}} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  };




  const ShareCodeTable = ({ analyticsData, shareCode }) => {
    const { processedAnalytics, totalCount, totalEngagementTime, scatterData, maxEngagementTime, maxViewCount, totalUniqueCount, processedTags } = useMemo(() => {
      if (!Array.isArray(analyticsData)) {
        console.error('Analytics data is not an array:', analyticsData);
        return {
          processedAnalytics: [],
          totalCount: 0,
          totalEngagementTime: 0,
          scatterData: [],
          maxEngagementTime: 0,
          maxViewCount: 0,
          totalUniqueCount: 0,
          processedTags: []
        };
      }

      const filteredData = analyticsData.filter(item => item.shareCode === shareCode);

      if (filteredData.length === 0) {
        return {
          processedAnalytics: [],
          totalCount: 0,
          totalEngagementTime: 0,
          scatterData: [],
          maxEngagementTime: 0,
          maxViewCount: 0,
          totalUniqueCount: 0,
          processedTags: []
        };
      }

      const sceneMap = {};
      const tagMap = {};
      let totalCount = 0;
      let totalEngagementTime = 0;
      const uniqueUsers = new Set();

      filteredData.forEach(item => {
        if (!item.sceneName) {
          console.warn('Item missing sceneName:', item);
          return;
        }

        if (!sceneMap[item.sceneName]) {
          sceneMap[item.sceneName] = {
            count: 0,
            totalEngagementTime: 0
          };
        }

        sceneMap[item.sceneName].count++;
        sceneMap[item.sceneName].totalEngagementTime += item.engagementTime || 0;

        totalCount++;
        totalEngagementTime += item.engagementTime || 0;

        if (item.userAnon) {
          uniqueUsers.add(item.userAnon);
        }

        if (Array.isArray(item.engagementTags)) {
          item.engagementTags.forEach(tag => {
            if (!tagMap[tag]) {
              tagMap[tag] = {
                count: 0,
                totalEngagementTime: 0
              };
            }
            tagMap[tag].count++;
            tagMap[tag].totalEngagementTime += item.engagementTime || 0;
          });
        }
      });

      const processedAnalytics = Object.entries(sceneMap)
        .map(([sceneName, data]) => ({
          sceneName,
          count: data.count,
          totalEngagementTime: data.totalEngagementTime
        }))
        .sort((a, b) => b.totalEngagementTime - a.totalEngagementTime);

      const processedTags = Object.entries(tagMap)
        .map(([tagName, data]) => ({
          tagName,
          count: data.count,
          totalEngagementTime: data.totalEngagementTime
        }))
        .sort((a, b) => b.totalEngagementTime - a.totalEngagementTime);

      const scatterData = processedAnalytics.map(item => ({
        sceneName: item.sceneName,
        engagementTime: item.totalEngagementTime,
        viewCount: item.count
      }));

      const maxEngagementTime = Math.max(...scatterData.map(d => d.engagementTime));
      const maxViewCount = Math.max(...scatterData.map(d => d.viewCount));

      return {
        processedAnalytics,
        totalCount,
        totalEngagementTime,
        scatterData,
        maxEngagementTime,
        maxViewCount,
        totalUniqueCount: uniqueUsers.size,
        processedTags
      };
    }, [analyticsData, shareCode, timeRange]);


    // const generateColorRange = (startColor, middleColor, endColor, steps) => {
    //   const hexToRgb = (hex) => ({
    //     r: parseInt(hex.slice(1, 3), 16),
    //     g: parseInt(hex.slice(3, 5), 16),
    //     b: parseInt(hex.slice(5, 7), 16)
    //   });

    //   const rgbToHex = (r, g, b) =>
    //     `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

    //   const start = hexToRgb(startColor);
    //   const middle = hexToRgb(middleColor);
    //   const end = hexToRgb(endColor);

    //   const halfSteps = Math.floor(steps / 2);

    //   const firstHalf = Array(halfSteps).fill().map((_, i) => {
    //     const factor = i / (halfSteps - 1);
    //     return rgbToHex(
    //       Math.round(start.r + (middle.r - start.r) * factor),
    //       Math.round(start.g + (middle.g - start.g) * factor),
    //       Math.round(start.b + (middle.b - start.b) * factor)
    //     );
    //   });

    //   const secondHalf = Array(steps - halfSteps).fill().map((_, i) => {
    //     const factor = i / (steps - halfSteps - 1);
    //     return rgbToHex(
    //       Math.round(middle.r + (end.r - middle.r) * factor),
    //       Math.round(middle.g + (end.g - middle.g) * factor),
    //       Math.round(middle.b + (end.b - middle.b) * factor)
    //     );
    //   });

    //   return [...firstHalf, ...secondHalf];
    // };

    // const COLORS = [
    //   // "#FBBF24",  // bg-amber-400
    //   // "#FACC15",  // bg-yellow-400
    //   "#A3E635",  // bg-lime-400
    //   "#4ADE80",  // bg-green-400
    //   "#34D399",  // bg-emerald-400
    //   "#2DD4BF",  // bg-teal-400
    //   "#22D3EE",  // bg-cyan-400
    //   "#38BDF8",  // bg-sky-400
    //   "#60A5FA",  // bg-blue-400
    //   "#818CF8",  // bg-indigo-400
    //   "#A78BFA",  // bg-violet-400
    //   "#C084FC",  // bg-purple-400
    //   "#E879F9",  // bg-fuchsia-400
    //   "#F472B6",  // bg-pink-400
    //   "#FB7185",  // bg-rose-400
    // ]; //generateColorRange('#93CFFB', '#CBFDCD', '#14B8A6', 10);

    const COLORS = ["#48dbfb", "#00d2d3", "#54a0ff", "#22a6b3", "#01a3a4", "#686de0", "#7269ef", "#5f27cd", "#4834d4", "#30336b"]

    const ScenePieCharts = () => {
      const countData = processedAnalytics.map(({ sceneName, count }) => ({
        name: sceneName,
        value: count
      }));

      const timeData = processedAnalytics.map(({ sceneName, totalEngagementTime }) => ({
        name: sceneName,
        value: totalEngagementTime
      }));

      const CustomizedLegend = (props) => {
        const { payload } = props;
        return (
          <ul className="flex flex-wrap justify-start mt-20 ml-16">
            {payload.map((entry, index) => (
              <li key={`item-${index}`} className="w-1/2 mb-2 flex items-center">
                <svg width="10" height="10" className="mr-2">
                  <rect width="10" height="10" fill={entry.color} />
                </svg>
                <span className="text-sm">{entry.value}</span>
              </li>
            ))}
          </ul>
        );
      };

      return (
        <div className="
         mt-8">
          <div>
            <h3 className="text-base text-zinc-200 pl-2 mb-4 ml-8">Scene View Count</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <Pie

                  data={countData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  paddingAngle={4}
                  innerRadius={100}
                  outerRadius={120}
                  fill="#82ca9d"
                  label={(entry) => entry.name}
                  labelLine={false}
                  strokeWidth={0}
                >
                  {countData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                {/* <Legend content={<CustomizedLegend />} /> */}
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div>
            <h3 className="text-base text-zinc-200 pl-2 mb-4 ml-8 mt-12">Scene View Time</h3>
            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Pie
                  data={timeData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  paddingAngle={4}
                  innerRadius={100}
                  outerRadius={120}
                  fill="#8884d8"
                  label={(entry) => entry.name}
                  labelLine={false}
                  strokeWidth={0}
                >
                  {timeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={<CustomizedLegend />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    };



    return (
      <>
        <div className="px-4 rounded-md">

          <div className="grid grid-cols-1 gap-px bg-black/10  grid-cols-3  ">

            <div className="px-4 py-6 sm:px-6 lg:px-8  rounded">
              <p className="text-sm font-medium leading-6 text-zinc-400">Unique <br />Viewers</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-3xl font-semibold tracking-tight text-zinc-50">{totalUniqueCount}</span>
              </p>
            </div>

            <div className="px-4 py-6 sm:px-6 lg:px-8  rounded">
              <p className="text-sm font-medium leading-6 text-zinc-400">Scenes  <br />Viewed</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-3xl font-semibold tracking-tight text-zinc-50">{totalCount}</span>
              </p>
            </div>

            <div className=" px-4 py-6 sm:px-6 lg:px-8  rounded">
              <p className="text-sm font-medium leading-6 text-zinc-400">Total Time <br />Watched</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-3xl font-semibold tracking-tight text-zinc-50">{formatTime(totalEngagementTime)}</span>
              </p>
            </div>

          </div>

        </div>




        <div className="pr-2 mt-8">

          <div className="pr-2 mt-8 mb-12">
            {/* <div className="text-xl pl-2 mb-4 text-zinc-200 ml-8">Last 48 Hours <span className="text-sm ml-4 text-zinc-500">(Scenes Viewed)</span></div> */}
            <h3 className="text-base text-zinc-200 pl-2 mb-4 ml-8">Scenes Viewed</h3>

            <ScenesViewedChart
              analyticsData={analyticsData}
              shareCode={shareCode}
              timeRange={timeRange}
            />
            {/* <div className="text-zinc-400 text-sm mt-2">{shareCode}....</div>   */}

          </div>

          <ResponsiveContainer width="100%" height={300}>
            <ScatterChart
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 50,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                strokeOpacity={0.1}
              />
              <XAxis
                type="number"
                dataKey="engagementTime"
                name="engagement time"
                unit="s"
                tickFormatter={formatTime}
                axisLine={false}
                tickLine={false}
                label={{ value: 'Duration', position: 'insideBottomRight', offset: -10 }}
              />
              <YAxis
                type="number"
                dataKey="viewCount"
                name="view count"
                axisLine={false}
                tickLine={false}
                label={{ value: 'Count', angle: -90, position: 'insideLeft' }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Scatter
                name="Scenes"
                data={scatterData}
                fill="#0EA5E9"
                shape={
                  (props) => (
                    <CustomShape
                      {...props}
                      maxEngagementTime={maxEngagementTime}
                      maxViewCount={maxViewCount}
                    />
                  )
                }
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>

        <div className="w-full">
          <ScenePieCharts />
        </div>

        <div className="rounded-md p-6 grid grid-cols-2 gap-14">
          <div>
            <h3 className="text-xl  pl-2 mb-4">Scenes</h3>
            <table className="w-full -mt-12 ">
              <thead>
                <tr>
                  <th className="p-2 text-left"> </th>
                  <th className="p-2 text-right">Count</th>
                  <th className="p-2 text-right">Time</th>
                </tr>
              </thead>
              <tbody>
                {processedAnalytics.map(({ sceneName, count, totalEngagementTime }) => (
                  <tr key={sceneName} className="text-zinc-400">
                    <td className="px-2 text-left">{sceneName}</td>
                    <td className="px-2 text-right">{count}</td>
                    <td className="px-2 text-right">{formatTime(totalEngagementTime)}</td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
          <div>
            <h3 className="text-xl   mb-4">Topics</h3>
            <table className="w-full -mt-12 ">
              <thead>
                <tr>
                  <th className="p-2 text-left"> </th>
                  <th className="p-2 text-right">Count</th>
                  <th className="p-2 text-right">Time</th>
                </tr>
              </thead>
              <tbody>
                {processedTags.map(({ tagName, count, totalEngagementTime }) => (
                  <tr key={tagName} className="text-zinc-400 py-4">
                    <td className="px-2 text-left">{tagName}</td>
                    <td className="px-2 text-right">{count}</td>
                    <td className="px-2 text-right">{formatTime(totalEngagementTime)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </>
    );
  };

  const override = {
    width: "50%",
    margin: "auto",
    marginTop: "20%",
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-2xl mb-4 sm:tracking-tight">
            <img src="/util/app/b3-genai-v2.svg" className="inline mr-2 h-6" /> Insights
          </h2>
        </div>
        <div className="mr-10">
        <ShareCodeFilter 
            shareCodes={getUniqueShareCodes}
            selectedCodes={selectedShareCodes}
            onChange={setSelectedShareCodes}
          />
          <TimeRangeSelector onSelectTimeRange={setTimeRange} currentTimeRange={timeRange} />
        </div>
      </div>



      {/* NEW CHART GOES HERE
      
      - LINECHART USING recharts
      - Show all data from analyticsArr, based on "shareCode" and filtered based on timeRange
      - use COLOR array for the lines (for each "shareCode") and legand colors
      
      [
    {
        "userAnon": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36|en-US|5120x1440|240",
        "sceneIndex": 6,
        "storyId": "GOTPFPnPZ3H42ZTuXDOM",
        "engagementTime": 0,
        "sceneName": "Thank You",
        "userAgent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36",
        "userAccount": "",
        "engagementTags": [],
        "createdAt": {
            "seconds": 1726014687,
            "nanoseconds": 484000000
        },
        "storyName": "Sample for Simon",
        "shareCode": "simon_demo",
        "sceneId": "lv3Cvks7ars5JnXGQ9RT",
        "windowHeight": 1193,
        "scenePriorIndex": 5,
        "scenePriorId": "xGWWlWaovPiRprVR53Aj",
        "windowWidth": 2350,
        "id": "htxOq21AKVsVyMwRHrgv"
    },
      ]
      
      */}


      <div className="overflow-y-auto admin-scroll mb-12 mt-4 pr-8">
        <div className="mx-auto ">

          <div className="w-full">
            <BarLoader
              color={color}
              loading={!storiesLoaded}
              cssOverride={override}
              size={450}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>


          {analyticsLoaded ? (
            <div className="w-full mb-8">
              <h3 className="text-xl text-zinc-200 pl-2 mb-4">Share Analytics Overview</h3>
              <ShareAnalyticsChart 
                analyticsData={analytics} 
                timeRange={timeRange} 
                selectedShareCodes={selectedShareCodes}
              />
            </div>
          ) : null}




          <CompInsightsDashboard  shares={shares} analytics={analytics} timeRange={timeRange} stories={stories} />


          <div className={"grid grid-cols-3 2xl:grid-cols-3   xl:grid-cols-2  gap-4 text-zinc-100"}>


            {sharesLoaded && shares.length === 0 ?

              <div className="bg-black/20 rounded-md p-6 flex col-span-x">

                <img src={'/media/icon/b3-admin-analytics-2.svg'} className="w-12 rounded-md inline mr-4" />

                <div className="">
                  <div className="text-lg text-gray-50  ">No insights yet available</div>
                  <div className="text-sm mt-1 text-gray-400">Create a new share to start collecting insights (the a tracking URL will be provided)</div>
                </div>
              </div>
              :
              null
            }






            {shares.map((share, index) => (
              <div key={index} className="bg-black/20 rounded-md ">
                <div className="flex justify-between p-8">
                  <div className="flex gap-4">
                    {/* <img src={share.imageStoryLogo} className="w-24 rounded-md " /> */}

                    <div className="bg-contain bg-center w-24 bg-no-repeat mr-2"
                      style={{ backgroundImage: `url(${share.imageStoryLogo})` }}
                    ></div>

                    <div>
                      <div className="text-lg text-gray-50  ">{share.name}</div>
                      {/* <div className="text-sm mt-1 text-gray-400">{share.description}</div>
                      <div className="text-sm mt-1 text-gray-400">
                        {share.createdAt?.toDate()?.toDateString()}
                      </div> */}
                      {/* <div className="text-sm mt-1 text-gray-400">Last Viewed</div> */}
                    </div>
                  </div>

                </div>


                {analyticsLoaded && storiesLoaded ? (
                  <ShareCodeTable
                    analyticsData={analytics}
                    shareCode={share.shareFriendlyURL || ''}
                    stories={stories}
                    timeRange={timeRange}
                  />
                ) : null}

              </div>
            ))}
          </div>



        </div>
      </div>
    </div>
  )
}

export default Insights