import React, { useMemo, useRef, useEffect } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import { extend, useFrame } from '@react-three/fiber';
import gsap from 'gsap';

// Custom shader material for line drawing animation
class LineMaterial extends THREE.ShaderMaterial {
    constructor() {
        super({
            uniforms: {
                color: { value: new THREE.Color('white') },
                dashSize: { value: 0.1 },
                totalSize: { value: 1 },
            },
            vertexShader: `
          attribute float lineDistance;
          varying float vLineDistance;
          void main() {
            vLineDistance = lineDistance;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
            fragmentShader: `
          uniform vec3 color;
          uniform float dashSize;
          uniform float totalSize;
          varying float vLineDistance;
          void main() {
            float progress = dashSize / totalSize;
            if (vLineDistance > progress) discard;
            gl_FragColor = vec4(color, 1.0);
          }
        `,
        });
    }
}

extend({ LineMaterial });

const ChartLine = ({ vizNode = {}, options = {} }) => {
    const {
        data = [
            { x: 0, y: 40, xLabel: 'Jan', yLabel: '40K' },
            { x: 12, y: 26, xLabel: 'Feb' },
            { x: 25, y: 11, xLabel: 'Mar' },
            { x: 34, y: 41, xLabel: 'Apr' },
            { x: 47, y: 33, xLabel: 'May' },
        ],
    } = vizNode;

    const {
        color = '#ff0000',
        thickness = 21,
        xScale = 0.1,
        yScale = 0.1,
        bezier = true,
        bezierTension = 0.5,
        showJoints = true,
        jointType = 'sphere',
        jointSize = 0.05,
        jointColor = '#cccccc',
        showLabels = true,
        labelColor = '#333333',
        labelSize = 0.5,
        jointAnimationDuration = 0.7,
        jointAnimationEase = 'elastic.out(1, 0.3)',
        lineAnimationDuration = 2,
    } = options;

    const lineRef = useRef();
    const jointsRef = useRef([]);
    const materialRef = useRef();

    const points = useMemo(() => {
        return data.map((point) => new THREE.Vector3(point.x * xScale, point.y * yScale, 0));
    }, [data, xScale, yScale]);

    const lineGeometry = useMemo(() => {
        const geometry = new THREE.BufferGeometry().setFromPoints(
            bezier ? new THREE.CatmullRomCurve3(points, false, 'catmullrom', bezierTension).getPoints(100) : points
        );

        const lineDistances = new Float32Array(geometry.attributes.position.count);
        let lineDistance = 0;
        for (let i = 1; i < geometry.attributes.position.count; i++) {
            const p1 = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i - 1);
            const p2 = new THREE.Vector3().fromBufferAttribute(geometry.attributes.position, i);
            lineDistance += p1.distanceTo(p2);
            lineDistances[i] = lineDistance;
        }
        geometry.setAttribute('lineDistance', new THREE.BufferAttribute(lineDistances, 1));

        return geometry;
    }, [points, bezier, bezierTension]);

    useEffect(() => {
        if (materialRef.current) {
            gsap.to(materialRef.current.uniforms.dashSize, {
                value: lineGeometry.attributes.lineDistance.array[lineGeometry.attributes.lineDistance.count - 1],
                duration: lineAnimationDuration,
                ease: 'power1.inOut',
            });

            points.forEach((_, index) => {
                gsap.from(jointsRef.current[index].scale, {
                    x: 0,
                    y: 0,
                    z: 0,
                    duration: jointAnimationDuration,
                    ease: jointAnimationEase,
                    delay: (index / (points.length - 1)) * lineAnimationDuration,
                });
            });
        }
    }, [points, lineGeometry, jointAnimationDuration, jointAnimationEase, lineAnimationDuration]);

    return (
        <group>
            {/* <line ref={lineRef} geometry={lineGeometry}>
        <lineMaterial
          ref={materialRef}
          color={color}
          dashSize={0}
          totalSize={lineGeometry.attributes.lineDistance.array[lineGeometry.attributes.lineDistance.count - 1]}
          linewidth={thickness}
          transparent
        />
      </line> */}
            <line ref={lineRef} geometry={lineGeometry}>
                <lineMaterial
                    ref={materialRef}
                    color={color}
                    dashSize={0}
                    totalSize={lineGeometry.attributes.lineDistance.array[lineGeometry.attributes.lineDistance.count - 1]}
                    linewidth={thickness}
                    transparent
                />
            </line>
            {showJoints && points.map((point, index) => (
                <mesh
                    key={index}
                    position={point}
                    ref={(el) => (jointsRef.current[index] = el)}
                >
                    {jointType === 'sphere' ? (
                        <sphereGeometry args={[jointSize, 32, 32]} />
                    ) : (
                        <boxGeometry args={[jointSize, jointSize, jointSize]} />
                    )}
                    <meshStandardMaterial
                        color={jointColor}
                    />
                </mesh>
            ))}
            {showLabels && data.map((point, index) => (
                <React.Fragment key={index}>
                    <Text
                        position={[point.x * xScale, -labelSize * yScale, 0]}
                        color={labelColor}
                        fontSize={labelSize}
                        anchorX="center"
                        anchorY="top"
                    >
                        {point.xLabel || point.x.toString()}
                    </Text>
                    {index === 0 && (
                        <Text
                            position={[-labelSize * xScale, point.y * yScale, 0]}
                            color={labelColor}
                            fontSize={labelSize}
                            anchorX="right"
                            anchorY="middle"
                        >
                            {point.yLabel || point.y.toString()}
                        </Text>
                    )}
                </React.Fragment>
            ))}
        </group>
    );
};

export default ChartLine;