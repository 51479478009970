import React, { useMemo } from 'react';
import { format, isToday, isYesterday, isSameDay, subDays } from 'date-fns';

const CompInsightsDashboard = ({ shares, analytics, stories }) => {
    const processedData = useMemo(() => {
        if (!shares || !analytics || !stories) return [];

        console.log('shares', shares);
        console.log('stories', stories);

        const shareStats = shares.reduce((acc, share) => {
            if (share && share.name) {
                const matchingStory = stories.find(stories => stories.id === share.storyId);
                acc[share.name] = {
                    name: share.name,
                    storyID: share.storyId,
                    imageStoryLogo: share.imageStoryLogo,
                    previewThumb: matchingStory ? matchingStory.previewThumb : null,
                    scenesViewed: 0,
                    timeWatched: 0,
                    uniqueViewers: new Set()
                };
            }
            return acc;
        }, {});

        analytics.forEach(item => {
            if (item && item.shareCode) {
                const shareName = shares.find(s => s.shareFriendlyURL === item.shareCode)?.name;
                if (shareName && shareStats[shareName]) {
                    shareStats[shareName].scenesViewed += 1;
                    shareStats[shareName].timeWatched += item.engagementTime || 0;
                    if (item.userAnon) shareStats[shareName].uniqueViewers.add(item.userAnon);
                }
            }
        });

        return Object.values(shareStats).map(stat => ({
            ...stat,
            uniqueViewers: stat.uniqueViewers.size
        }));
    }, [shares, analytics, stories]);

    const topShares = useMemo(() => {
        return processedData
            .sort((a, b) =>
                b.uniqueViewers - a.uniqueViewers ||
                b.timeWatched - a.timeWatched ||
                b.scenesViewed - a.scenesViewed
            )
            .slice(0, 5);
    }, [processedData]);

    const recentViewers = useMemo(() => {
        if (!analytics || !shares) return [];

        return analytics
            .filter(item => item && item.createdAt && item.userAnon)
            .sort((a, b) => (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0))
            .filter((item, index, self) =>
                index === self.findIndex(t => t.userAnon === item.userAnon)
            )
            .slice(0, 50)
            .map(item => {
                const shareName = shares.find(s => s.shareFriendlyURL === item.shareCode)?.name || 'Unknown';
                const userStats = analytics.filter(a => a.userAnon === item.userAnon);
                return {
                    shareName,
                    userAnon: item.userAnon,
                    uniqueViews: userStats.length,
                    totalTimeViewed: userStats.reduce((sum, a) => sum + (a.engagementTime || 0), 0),
                    totalScenesViewed: new Set(userStats.map(a => a.sceneId).filter(Boolean)).size,
                    createdAt: new Date(item.createdAt.seconds * 1000)
                };
            });
    }, [analytics, shares]);

    const shareEngagement = useMemo(() => {
        const engagement = {};
        recentViewers.forEach(viewer => {
            engagement[viewer.shareName] = (engagement[viewer.shareName] || 0) + viewer.uniqueViews;
        });
        return engagement;
    }, [recentViewers]);

    const maxEngagement = Math.max(...Object.values(shareEngagement), 1);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${hours}h ${minutes}m ${remainingSeconds}s`;
    };

    const groupedViewers = useMemo(() => {
        const groups = {};
        recentViewers.forEach(viewer => {
            const date = viewer.createdAt;
            const dateString = format(date, 'yyyy-MM-dd');
            if (!groups[dateString]) {
                groups[dateString] = [];
            }
            groups[dateString].push(viewer);
        });
        return groups;
    }, [recentViewers]);

    const formatDateHeader = (date) => {
        if (isToday(date)) {
            return 'Today';
        } else if (isYesterday(date)) {
            return 'Yesterday';
        } else if (isSameDay(date, subDays(new Date(), 2))) {
            return '2 days ago';
        } else {
            return format(date, 'EEEE, MMM d');
        }
    };

    return (
        <div className="flex h-fit text-zinc-300 p-8">
            <div className="w-1/3 pr-4">
                <h2 className="text-2xl mb-4">Top 5 Shares</h2>
                <div className="space-y-4">
                    {topShares.map((share, index) => (
                        <div key={index} className="bg-black/20 px-6 py-2 rounded-lg flex items-center">
                            <div className="flex-shrink-0 mr-4">
                                <div className="w-16 h-16 mb-2 mr-6 ml-4">
                                    {share.previewThumb && (
                                        <>
                                            <img
                                                src={share.previewThumb}
                                                alt={`${share.name} preview`}
                                                className="w-full h-full object-cover rounded"
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="w-16 h-16">
                                    {share.imageStoryLogo && (
                                        <img
                                            src={share.imageStoryLogo}
                                            alt={`${share.name} logo`}
                                            className="w-full h-full object-contain"
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                <h3 className="text-xl">{share.name}</h3>

                                <div className='grid grid-cols-3 gap-6 mt-4'>
                                    <div><span className='text-xl'>{share.uniqueViewers}</span><br/> <span className='text-sm text-zinc-400'>Unique Viewers </span></div>
                                    <div><span className='text-xl'>{formatTime(share.timeWatched)}</span><br/> <span className='text-sm text-zinc-400'>Total Watched </span></div>
                                    <div><span className='text-xl'>{share.scenesViewed}</span><br/> <span className='text-sm text-zinc-400'>Scenes Viewed </span></div>
                                </div>




                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-2/3 pl-4">
                <h2 className="text-2xl mb-4">Recent Activity</h2>
                <table className="w-full text-sm text-left text-zinc-300">
                    <thead className="text-zinc-300">
                        <tr>
                            <th scope="col" className="px-6 py-3">Share</th>
                            <th scope="col" className="px-6 py-3">Unique Views</th>
                            <th scope="col" className="px-6 py-3">Total Time</th>
                            <th scope="col" className="px-6 py-3">Total Scenes</th>
                            <th scope="col" className="px-6 py-3">Last View</th>
                        </tr>
                    </thead>
                </table>
                <div className="h-[calc(100vh-420px)] overflow-y-auto relative pt-4 pr-4">
                    {Object.entries(groupedViewers).map(([dateString, viewers]) => (
                        <div key={dateString} className="mb-6 relative">
                            <div className="absolute -top-3 left-4 bg-black/20 px-2 py-1 text-sm rounded">
                                {formatDateHeader(new Date(dateString))}
                            </div>
                            <div className="bg-black/20 rounded-md p-4">
                                <table className="w-full text-sm text-left text-zinc-300">
                                    <tbody>
                                        {viewers.map((viewer, index) => {
                                            const engagement = shareEngagement[viewer.shareName] || 0;
                                            const brightness = Math.floor((1 - engagement / maxEngagement) * 900);
                                            return (
                                                <tr key={index} className={`bg-sky-${brightness} bg-opacity-50`}>
                                                    <td className="px-6 py-4 font-medium">{viewer.shareName}</td>
                                                    <td className="px-6 py-4">{viewer.uniqueViews}</td>
                                                    <td className="px-6 py-4">{formatTime(viewer.totalTimeViewed)}</td>
                                                    <td className="px-6 py-4">{viewer.totalScenesViewed}</td>
                                                    <td className="px-6 py-4">
                                                        {format(viewer.createdAt, 'h:mm a')}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CompInsightsDashboard;