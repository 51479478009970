import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'

const ExpModelPhong = (props) => {
    //const groupRef = useRef()
    const { nodes } = useGLTF('/components/' + props.model + '.glb')

    const material = new THREE.MeshPhongMaterial({
        color: new THREE.Color(props.color || "#ff0000"),
        shininess: props.shininess || 30,
        emissive: new THREE.Color(props.emissive || "#000000"),
        specular: new THREE.Color(props.specular || "#111111"),
        //metalness: props.metalness || 0,
        //roughness: props.roughness || 0.5
    })

    return (
        <group 
        //ref={groupRef}
         dispose={null}>
            <mesh 
                geometry={nodes[props.model + ''].geometry} 
                position={props.position} 
                scale={props.scale || 1}
                material={material}
            />
        </group>
    )
}

export default ExpModelPhong