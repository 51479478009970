import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react';
import { extend, useLoader, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Html } from '@react-three/drei';

const degToRad = (degrees) => degrees * (Math.PI / 180);




// class EnergyShaderMaterial extends THREE.ShaderMaterial {
//     constructor() {
//         super({
//             uniforms: {
//                 time: { value: 0 },
//                 color: { value: new THREE.Color(0x00ff00) },
//                 speed: { value: 1.0 },
//                 intensity: { value: 1.0 },
//             },
//             vertexShader: `
//         varying vec2 vUv;
//         void main() {
//           vUv = uv;
//           gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
//         }
//       `,
//             fragmentShader: `
//         uniform float time;
//         uniform vec3 color;
//         uniform float speed;
//         uniform float intensity;
//         varying vec2 vUv;
        
//         void main() {
//           vec2 uv = vUv;
//           float t = time * speed;
          
//           // Create a flowing pattern
//           float pattern = sin(uv.x * 10.0 + t) * 0.5 + 0.5;
//           pattern *= sin(uv.y * 10.0 - t * 0.5) * 0.5 + 0.5;
          
//           // Add some vertical bands
//           pattern *= sin(uv.y * 20.0 + t * 2.0) * 0.5 + 0.5;
          
//           // Adjust intensity
//           pattern *= intensity;
          
//           gl_FragColor = vec4(color * pattern, 1.0);
//         }
//       `,
//         });
//     }
// }
// extend({ EnergyMaterial: EnergyShaderMaterial });
// function EnergyMesh({ geometry, color = '#00ff00', speed = 1, intensity = 1 }) {
//     const materialRef = useRef();

//     useFrame((state) => {
//         if (materialRef.current) {
//             materialRef.current.uniforms.time.value = state.clock.getElapsedTime();
//         }
//     });

//     return (
//         <mesh geometry={geometry}>
//             <energyMaterial
//                 ref={materialRef}
//                 color={color}
//                 speed={speed}
//                 intensity={intensity}
//             />
//         </mesh>
//     );
// }


const MeshShape3D = ({
    url = "/shapes/b3-shape-triangle.svg",
    matcapUrl = "/util/matcap/8F4277_361530_BF538E_52274C.png",
    rot = { x: 0, y: 0, z: 0 },
    pos = { x: -100, y: 0, z: 0 },
    position,
    rotation,
    extrusion,
    scale = 1,
    vizItem,
    positionCalc,
    setOrbitActive,
}) => {
    //console.log("MeshShape3D url: ", url);

    const groupRef = useRef();
    const meshRef = useRef();
    const { paths } = useLoader(SVGLoader, url);
    const matcapTexture = useLoader(THREE.TextureLoader, matcapUrl);
    const xArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-z.glb");
    const yArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-y.glb");

    const [isDragging, setIsDragging] = useState(false);
    const [isDraggingAxis, setIsDraggingAxis] = useState(null);
    const [hoveredAxis, setHoveredAxis] = useState(null);
    const [zPosition, setZPosition] = useState(0);
    const dragStart = useRef(new THREE.Vector3());
    const dragStartPosition = useRef(new THREE.Vector3());

    const { size, viewport, camera, gl } = useThree();

    const extrudeSettings = useMemo(() => ({
        steps: 2,
        depth: extrusion,
        bevelEnabled: false,
    }), [extrusion]);

    const shapes = useMemo(() =>
        paths.map(path => path.toShapes()[0]).filter(Boolean),
        [paths]
    );

    const geometry = useMemo(() => {
        const shapeGeometry = new THREE.ShapeGeometry(shapes);
        return new THREE.ExtrudeGeometry(shapes, extrudeSettings);
    }, [shapes, extrudeSettings]);

    useEffect(() => {
        if (meshRef.current) {
            const box = new THREE.Box3().setFromObject(meshRef.current);
            const center = box.getCenter(new THREE.Vector3());
            meshRef.current.position.sub(center);
            groupRef.current.position.add(center);
        }
    }, [geometry]);

    useFrame(() => {
        if (groupRef.current) {
            groupRef.current.rotation.x = degToRad(rotation[0]);
            groupRef.current.rotation.y = degToRad(rotation[1]);
            groupRef.current.rotation.z = degToRad(rotation[2]);
        }
    });
    const getMousePosition = useCallback((event) => {
        const vec = new THREE.Vector3();
        const pos = new THREE.Vector3();

        vec.set(
            (event.clientX / size.width) * 2 - 1,
            -(event.clientY / size.height) * 2 + 1,
            0.5
        );

        vec.unproject(camera);
        vec.sub(camera.position).normalize();
        const distance = -camera.position.z / vec.z;
        pos.copy(camera.position).add(vec.multiplyScalar(distance));

        return pos;
    }, [camera, size]);

    const onPointerDown = useCallback((event, axis) => {
        event.stopPropagation();
        setIsDragging(true);
        setIsDraggingAxis(axis);
        setOrbitActive(0);
        gl.domElement.style.cursor = 'grabbing';
        const mousePos = getMousePosition(event);
        dragStart.current.set(mousePos.x, mousePos.y, mousePos.z);
        dragStartPosition.current.copy(groupRef.current.position);
        console.log(`Drag started on ${axis} axis. Start position:`, dragStartPosition.current);
    }, [gl, getMousePosition, setOrbitActive]);

    const onPointerMove = useCallback((event) => {
        if (!isDragging) return;
        const currentPosition = getMousePosition(event);
        const dragDelta = new THREE.Vector3(
            currentPosition.x - dragStart.current.x,
            currentPosition.y - dragStart.current.y,
            currentPosition.z - dragStart.current.z
        );

        const newPosition = new THREE.Vector3().copy(groupRef.current.position);

        switch (isDraggingAxis) {
            case 'x':
                newPosition.x = dragStartPosition.current.x + dragDelta.x;
                break;
            case 'y':
                newPosition.y = dragStartPosition.current.y + dragDelta.y;
                break;
        }

        groupRef.current.position.copy(newPosition);
        console.log(`Dragging ${isDraggingAxis} axis. New position:`, newPosition);
    }, [isDragging, getMousePosition, isDraggingAxis]);

    const onPointerUp = useCallback((event) => {
        if (isDragging) {
            console.log(`Drag ended on ${isDraggingAxis} axis. Final position:`, groupRef.current.position);
        }

        setIsDragging(false);
        setIsDraggingAxis(null);
        setOrbitActive(1);
        gl.domElement.style.cursor = 'auto';
    }, [gl, setOrbitActive, isDragging, isDraggingAxis]);

    useEffect(() => {
        const domElement = gl.domElement;
        domElement.addEventListener('pointermove', onPointerMove);
        domElement.addEventListener('pointerup', onPointerUp);
        return () => {
            domElement.removeEventListener('pointermove', onPointerMove);
            domElement.removeEventListener('pointerup', onPointerUp);
        };
    }, [gl, onPointerMove, onPointerUp]);

    const handleZSliderChange = (event) => {
        const newZPosition = parseFloat(event.target.value);
        setZPosition(newZPosition);
        if (groupRef.current) {
            groupRef.current.position.z = newZPosition;
        }
        console.log(`Z-axis position changed to: ${newZPosition}`);
    };

    const handleZSliderStart = () => {
        setOrbitActive(0);
    };

    const handleZSliderEnd = () => {
        setOrbitActive(1);
    };

    return (
        <>
            <group
                ref={groupRef}
                position={[positionCalc[0], positionCalc[1], positionCalc[2]]}
            >
                {shapes.map((shape, i) => (
                    <mesh key={i} scale={scale} ref={meshRef} >
                        <primitive object={geometry} />
                        <meshMatcapMaterial matcap={matcapTexture} side={THREE.DoubleSide} />

                        {/* <EnergyMesh
                            geometry={geometry}
                            color="#00ffff"
                            speed={1.5}
                            intensity={1.2}
                        /> */}

                    </mesh>
                ))}
                <group
                    position={[1, -5, 0]}
                    onPointerDown={(e) => onPointerDown(e, 'x')}
                    onPointerOver={() => {
                        gl.domElement.style.cursor = 'grab';
                        setHoveredAxis('x');
                    }}
                    onPointerOut={() => {
                        gl.domElement.style.cursor = 'auto';
                        setHoveredAxis(null);
                    }}
                >
                    <mesh>
                        <boxGeometry args={[2, 1, 1]} />
                        <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'x' ? .2 : 0} />
                    </mesh>
                    <primitive object={yArrowModel.scene.clone()} scale={1} />
                </group>
                <group
                    onPointerDown={(e) => onPointerDown(e, 'y')}
                    onPointerOver={() => {
                        gl.domElement.style.cursor = 'grab';
                        setHoveredAxis('y');
                    }}
                    onPointerOut={() => {
                        gl.domElement.style.cursor = 'auto';
                        setHoveredAxis(null);
                    }}
                    position={[1, -1, 0]}
                    rotation={[0, 0, Math.PI / 2]}
                >
                    <mesh>
                        <boxGeometry args={[0, 2, 1]} />
                        <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'y' ? .2 : 0} />
                    </mesh>
                    <primitive object={xArrowModel.scene.clone()} scale={1} />
                </group>
            </group>
            <group position={[positionCalc[0] + 2, positionCalc[1], positionCalc[2]]}>
                <Html
                    transform
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'white',
                        background: 'rgba(0,0,0,0.5)',
                        padding: '10px',
                        borderRadius: '5px'
                    }}
                >
                    <div>Z-Axis: {zPosition.toFixed(2)}</div>
                    <input
                        type="range"
                        min="-50"
                        max="50"
                        step="0.1"
                        value={zPosition}
                        onChange={handleZSliderChange}
                        onMouseDown={handleZSliderStart}
                        onMouseUp={handleZSliderEnd}
                        onTouchStart={handleZSliderStart}
                        onTouchEnd={handleZSliderEnd}
                        style={{ width: '100px' }}
                    />
                </Html>
            </group>
        </>
    );
};

export default MeshShape3D;

// import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react';
// import { useLoader, useFrame, useThree } from '@react-three/fiber';
// import * as THREE from 'three';
// import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

// const degToRad = (degrees) => degrees * (Math.PI / 180);

// const MeshShape3D = ({
//     url = "/shapes/b3-shape-triangle.svg",
//     matcapUrl = "/util/matcap/8F4277_361530_BF538E_52274C.png",
//     rot = { x: 0, y: 0, z: 0 },
//     pos = { x: -100, y: 0, z: 0 },
//     position,
//     rotation,
//     extrusion,
//     scale = 1,
//     vizItem,
//     positionCalc,
//     setOrbitActive,
// }) => {
//     console.log("MeshShape3D url: ", url);

//     const groupRef = useRef();
//     const { paths } = useLoader(SVGLoader, url);
//     const matcapTexture = useLoader(THREE.TextureLoader, matcapUrl);
//     const xArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-z.glb");
//     const yArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-y.glb");
//     const zArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-x.glb");

//     const [isDragging, setIsDragging] = useState(false);
//     const [isDraggingAxis, setIsDraggingAxis] = useState(null);
//     const [hoveredAxis, setHoveredAxis] = useState(null);
//     const dragStart = useRef(new THREE.Vector3());
//     const dragStartPosition = useRef(new THREE.Vector3());

//     const { size, viewport, camera, gl } = useThree();

//     const extrudeSettings = useMemo(() => ({
//         steps: 2,
//         depth: extrusion,
//         bevelEnabled: false,
//     }), [extrusion]);

//     useFrame(() => {
//         if (groupRef.current) {
//             groupRef.current.rotation.x = degToRad(rotation[0]);
//             groupRef.current.rotation.y = degToRad(rotation[1]);
//             groupRef.current.rotation.z = degToRad(rotation[2]);
//         }
//     });

//     const shapes = useMemo(() =>
//         paths.map(path => path.toShapes()[0]).filter(Boolean),
//         [paths]
//     );

//     const getMousePosition = useCallback((event) => {
//         const vec = new THREE.Vector3();
//         const pos = new THREE.Vector3();

//         vec.set(
//             (event.clientX / size.width) * 2 - 1,
//             -(event.clientY / size.height) * 2 + 1,
//             0.5
//         );

//         vec.unproject(camera);
//         vec.sub(camera.position).normalize();
//         const distance = -camera.position.z / vec.z;
//         pos.copy(camera.position).add(vec.multiplyScalar(distance));

//         return pos;
//     }, [camera, size]);

//     const onPointerDown = useCallback((event, axis) => {
//         event.stopPropagation();
//         setIsDragging(true);
//         setIsDraggingAxis(axis);
//         setOrbitActive(0);
//         gl.domElement.style.cursor = 'grabbing';
//         const mousePos = getMousePosition(event);
//         dragStart.current.set(mousePos.x, mousePos.y, mousePos.z);
//         dragStartPosition.current.copy(groupRef.current.position);
//         console.log(`Drag started on ${axis} axis. Start position:`, dragStartPosition.current);
//     }, [gl, getMousePosition, setOrbitActive]);

//     const onPointerMove = useCallback((event) => {
//         if (!isDragging) return;
//         const currentPosition = getMousePosition(event);
//         const dragDelta = new THREE.Vector3(
//             currentPosition.x - dragStart.current.x,
//             currentPosition.y - dragStart.current.y,
//             currentPosition.z - dragStart.current.z
//         );

//         const newPosition = new THREE.Vector3().copy(groupRef.current.position);

//         switch (isDraggingAxis) {
//             case 'x':
//                 newPosition.x = dragStartPosition.current.x + dragDelta.x;
//                 break;
//             case 'y':
//                 newPosition.y = dragStartPosition.current.y + dragDelta.y;
//                 break;
//             case 'z':
//                 newPosition.z = dragStartPosition.current.z + dragDelta.z;
//                 break;
//         }

//         groupRef.current.position.copy(newPosition);
//         console.log(`Dragging ${isDraggingAxis} axis. New position:`, newPosition);
//     }, [isDragging, getMousePosition, isDraggingAxis]);

//     const onPointerUp = useCallback((event) => {
//         if (isDragging) {
//             console.log(`Drag ended on ${isDraggingAxis} axis. Final position:`, groupRef.current.position);
//         }

//         setIsDragging(false);
//         setIsDraggingAxis(null);
//         setOrbitActive(1);
//         gl.domElement.style.cursor = 'auto';
//     }, [gl, setOrbitActive, isDragging, isDraggingAxis]);

//     useEffect(() => {
//         const domElement = gl.domElement;
//         domElement.addEventListener('pointermove', onPointerMove);
//         domElement.addEventListener('pointerup', onPointerUp);
//         return () => {
//             domElement.removeEventListener('pointermove', onPointerMove);
//             domElement.removeEventListener('pointerup', onPointerUp);
//         };
//     }, [gl, onPointerMove, onPointerUp]);

//     return (
//         <group 
//             ref={groupRef} 
//             position={[positionCalc[0], positionCalc[1], positionCalc[2]]} 
//         >
//             {shapes.map((shape, i) => (
//                 <mesh key={i} scale={scale}>
//                     <extrudeGeometry args={[shape, extrudeSettings]} />
//                     <meshMatcapMaterial matcap={matcapTexture} side={THREE.DoubleSide} />
//                 </mesh>
//             ))}
//             <group
//                 position={[1, -5, 0]}
//                 onPointerDown={(e) => onPointerDown(e, 'x')}
//                 onPointerOver={() => {
//                     gl.domElement.style.cursor = 'grab';
//                     setHoveredAxis('x');
//                 }}
//                 onPointerOut={() => {
//                     gl.domElement.style.cursor = 'auto';
//                     setHoveredAxis(null);
//                 }}
//             >
//                 <mesh>
//                     <boxGeometry args={[2, 1, 1]} />
//                     <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'x' ? .2 : 0} />
//                 </mesh>
//                 <primitive object={yArrowModel.scene.clone()} scale={1} />
//             </group>
//             <group
//                 onPointerDown={(e) => onPointerDown(e, 'y')}
//                 onPointerOver={() => {
//                     gl.domElement.style.cursor = 'grab';
//                     setHoveredAxis('y');
//                 }}
//                 onPointerOut={() => {
//                     gl.domElement.style.cursor = 'auto';
//                     setHoveredAxis(null);
//                 }}
//                 position={[1,-1,0]}
//                 rotation={[0,0,Math.PI/2]}
//             >
//                 <mesh>
//                     <boxGeometry args={[0, 2, 1]} />
//                     <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'y' ? .2 : 0} />
//                 </mesh>
//                 <primitive object={xArrowModel.scene.clone()} scale={1} />
//             </group>
//             <group
//                 onPointerDown={(e) => onPointerDown(e, 'z')}
//                 onPointerOver={() => {
//                     gl.domElement.style.cursor = 'grab';
//                     setHoveredAxis('z');
//                 }}
//                 onPointerOut={() => {
//                     gl.domElement.style.cursor = 'auto';
//                     setHoveredAxis(null);
//                 }}
//                 position={[-1,-20,0]}
//                 rotation={[0,Math.PI/2,0]}
//             >
//                 <mesh>
//                     <boxGeometry args={[0, 2, 1]} />
//                     <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'z' ? .2 : 0} />
//                 </mesh>
//                 <primitive object={zArrowModel.scene.clone()} scale={2} />
//             </group>
//         </group>
//     );
// };

// export default MeshShape3D;


// import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react';
// import { useLoader, useFrame, useThree } from '@react-three/fiber';
// import * as THREE from 'three';
// import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

// const degToRad = (degrees) => degrees * (Math.PI / 180);

// const MeshShape3D = ({
//     url = "/shapes/b3-shape-triangle.svg",
//     matcapUrl = "/util/matcap/8F4277_361530_BF538E_52274C.png",
//     rot = { x: 0, y: 0, z: 0 },
//     pos = { x: -100, y: 0, z: 0 },
//     position,
//     rotation,
//     extrusion,
//     scale = 1,
//     vizItem,
//     positionCalc,
//     setOrbitActive,
// }) => {
//     //console.log("MeshShape3D url: ", url);

//     const groupRef = useRef();
//     const { paths } = useLoader(SVGLoader, url);
//     const matcapTexture = useLoader(THREE.TextureLoader, matcapUrl);
//     const xArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-z.glb");
//     const yArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-y.glb");
//     const zArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-x.glb");

//     const [isDragging, setIsDragging] = useState(false);
//     const [isDraggingAxis, setIsDraggingAxis] = useState(null);
//     const [hoveredAxis, setHoveredAxis] = useState(null);
//     const dragStart = useRef(new THREE.Vector3());
//     const dragStartPosition = useRef(new THREE.Vector3());

//     const { size, viewport, camera, gl } = useThree();

//     const extrudeSettings = useMemo(() => ({
//         steps: 2,
//         depth: extrusion,
//         bevelEnabled: false,
//     }), [extrusion]);

//     useFrame(() => {
//         if (groupRef.current) {
//             groupRef.current.rotation.x = degToRad(rotation[0]);
//             groupRef.current.rotation.y = degToRad(rotation[1]);
//             groupRef.current.rotation.z = degToRad(rotation[2]);
//         }
//     });

//     const shapes = useMemo(() =>
//         paths.map(path => path.toShapes()[0]).filter(Boolean),
//         [paths]
//     );

//     const getMousePosition = useCallback((event) => {
//         const vec = new THREE.Vector3();
//         const pos = new THREE.Vector3();

//         vec.set(
//             (event.clientX / size.width) * 2 - 1,
//             -(event.clientY / size.height) * 2 + 1,
//             0.5
//         );

//         vec.unproject(camera);
//         vec.sub(camera.position).normalize();
//         const distance = -camera.position.z / vec.z;
//         pos.copy(camera.position).add(vec.multiplyScalar(distance));

//         return pos;
//     }, [camera, size]);

//     const onPointerDown = useCallback((event, axis) => {
//         event.stopPropagation();
//         setIsDragging(true);
//         setIsDraggingAxis(axis);
//         setOrbitActive(0);
//         gl.domElement.style.cursor = 'grabbing';
//         const mousePos = getMousePosition(event);
//         dragStart.current.set(mousePos.x, mousePos.y, mousePos.z);
//         dragStartPosition.current.copy(groupRef.current.position);
//     }, [gl, getMousePosition, setOrbitActive]);

//     const onPointerMove = useCallback((event) => {
//         if (!isDragging) return;
//         const currentPosition = getMousePosition(event);
//         const dragDelta = new THREE.Vector3(
//             currentPosition.x - dragStart.current.x,
//             currentPosition.y - dragStart.current.y,
//             currentPosition.z - dragStart.current.z
//         );

//         const newPosition = new THREE.Vector3().copy(dragStartPosition.current);

//         switch (isDraggingAxis) {
//             case 'x':
//                 newPosition.x += dragDelta.x;
//                 break;
//             case 'y':
//                 newPosition.y += dragDelta.y;
//                 break;
//             case 'z':
//                 newPosition.z += dragDelta.z;
//                 break;
//         }

//         groupRef.current.position.copy(newPosition);

//     }, [isDragging, getMousePosition, isDraggingAxis]);

//     const onPointerUp = useCallback((event) => {
//         if (isDragging) {
//             console.log('Final position:', groupRef.current.position);
//             // You can add any post-drag logic here, similar to the ExpModel
//         }

//         setIsDragging(false);
//         setIsDraggingAxis(null);
//         setOrbitActive(1);
//         gl.domElement.style.cursor = 'auto';
//     }, [gl, setOrbitActive, isDragging]);

//     useEffect(() => {
//         const domElement = gl.domElement;
//         domElement.addEventListener('pointermove', onPointerMove);
//         domElement.addEventListener('pointerup', onPointerUp);
//         return () => {
//             domElement.removeEventListener('pointermove', onPointerMove);
//             domElement.removeEventListener('pointerup', onPointerUp);
//         };
//     }, [gl, onPointerMove, onPointerUp]);

//     return (
//         <group 
//             ref={groupRef} 
//             position={[positionCalc[0], positionCalc[1], positionCalc[2]]} 
//         >
//             {shapes.map((shape, i) => (
//                 <mesh key={i} scale={scale}>
//                     <extrudeGeometry args={[shape, extrudeSettings]} />
//                     <meshMatcapMaterial matcap={matcapTexture} side={THREE.DoubleSide} />
//                 </mesh>
//             ))}
//             <group
//                 position={[1, -5, 0]}
//                 onPointerDown={(e) => onPointerDown(e, 'x')}
//                 onPointerOver={() => {
//                     gl.domElement.style.cursor = 'grab';
//                     setHoveredAxis('x');
//                 }}
//                 onPointerOut={() => {
//                     gl.domElement.style.cursor = 'auto';
//                     setHoveredAxis(null);
//                 }}
//             >
//                 <mesh>
//                     <boxGeometry args={[2, 1, 1]} />
//                     <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'x' ? .2 : 0} />
//                 </mesh>
//                 <primitive object={yArrowModel.scene.clone()} scale={1} />
//             </group>
//             <group
//                 onPointerDown={(e) => onPointerDown(e, 'y')}
//                 onPointerOver={() => {
//                     gl.domElement.style.cursor = 'grab';
//                     setHoveredAxis('y');
//                 }}
//                 onPointerOut={() => {
//                     gl.domElement.style.cursor = 'auto';
//                     setHoveredAxis(null);
//                 }}
//                 position={[1,-1,0]}
//                 rotation={[0,0,Math.PI/2]}
//             >
//                 <mesh>
//                     <boxGeometry args={[0, 2, 1]} />
//                     <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'y' ? .2 : 0} />
//                 </mesh>
//                 <primitive object={xArrowModel.scene.clone()} scale={1} />
//             </group>
//             <group
//                 onPointerDown={(e) => onPointerDown(e, 'z')}
//                 onPointerOver={() => {
//                     gl.domElement.style.cursor = 'grab';
//                     setHoveredAxis('z');
//                 }}
//                 onPointerOut={() => {
//                     gl.domElement.style.cursor = 'auto';
//                     setHoveredAxis(null);
//                 }}
//                 position={[-1,-10,0]}
//                 //rotation={[0,Math.PI/2,0]}
//             >
//                 <mesh>
//                     <boxGeometry args={[4, 5, 2]} />
//                     <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'z' ? .2 : 0} />
//                 </mesh>
//                 <primitive object={zArrowModel.scene.clone()} scale={1.5} />
//             </group>
//         </group>
//     );
// };

// export default MeshShape3D;