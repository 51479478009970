import React, { useState, useRef, useContext, Fragment, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Menu, Combobox, Dialog, Popover } from '@headlessui/react'
import { db } from '../firebase'
import { getFunctions, httpsCallable } from "firebase/functions";
import BarLoader from 'react-spinners/BarLoader';
import { saveAs } from 'file-saver'

// import { AppRegistry, StyleSheet, Text, View } from 'react-native';
// import InputSpinner from "react-native-input-spinner";

import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore'

import { v4 as uuidv4 } from 'uuid'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Slider from 'react-input-slider'

import NumericInput from 'react-numeric-input';
import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'
import GridSelect from './CompGridSelect';
import { logCustomEvent } from './UtilAnalytics';

const MediaUploader = ({ props, selectSceneComponent, configureActiveComponent, updateActiveComponentData,
  activeComponentData, sessionData, storyId, activeIndex, personaItem, uploadMode = 2 }) => {

  const [files, setFiles] = useState([]);
  const [loadingItems, setLoadingItems] = useState([])
  const [sceneData, setSceneData] = useContext(SceneContext)

  // Various media animation styles
  const animationStyles = [
    { id: 1, name: 'none', displayName: 'None' },
    { id: 2, name: 'bounceIn', displayName: 'Bounce in' },
    { id: 3, name: 'fadeIn', displayName: 'Fade in' },
    { id: 4, name: 'fadeInDown', displayName: 'Fade in from top' },
    { id: 5, name: 'fadeInLeft', displayName: 'Fade in from left' },
    { id: 6, name: 'fadeInRight', displayName: 'Fade in from right' },
    { id: 7, name: 'fadeInUp', displayName: 'Fade in from bottom' },
    { id: 8, name: 'zoomIn', displayName: 'Zoom in' },
  ]

  const bezelStyles = [
    { id: 1, name: 'none', displayName: 'None' },
    { id: 1, name: 'default', displayName: 'Default' },
    { id: 2, name: 'iphone-white', displayName: 'iPhone White' },
    { id: 3, name: 'iphone-silver', displayName: 'iPhone Silver' },
    { id: 4, name: 'iphone-black', displayName: 'iPhone Black' },
    { id: 5, name: 'generic-white', displayName: 'Generic White' },
    { id: 6, name: 'generic-silver', displayName: 'Generic Silver' },
    { id: 7, name: 'generic-black', displayName: 'Generic Black' },
  ]

  const thumbnailStyles = [
    { id: 1, name: 'thumbnails', displayName: 'Thumbnails' },
    { id: 2, name: 'dots', displayName: 'Dots' },
    { id: 3, name: 'arrows', displayName: 'Arrows' },
  ]



  const getMediaType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm'];
    const audioExtensions = ['mp3', 'wav', 'ogg', 'aac', 'm4a'];
  
    if (imageExtensions.includes(extension)) return 'image';
    if (videoExtensions.includes(extension)) return 'video';
    if (audioExtensions.includes(extension)) return 'audio';
    return 'other';
  };
 
  const layoutStyles = [
    { id: 1, name: 'personaTop', displayName: 'Persona on Top' },
    { id: 2, name: 'personaLeft', displayName: 'Persona on Left' },
    { id: 7, name: 'personaTop', displayName: 'Persona within Card' },
    { id: 3, name: 'personaCircle', displayName: 'Persona in Circle' },
    { id: 4, name: 'personaCircleName', displayName: 'Circle with Name' },
    { id: 5, name: 'personaCircleNameRole', displayName: 'Circle with Name & Role' },
    { id: 6, name: 'personaCircleNameRoleDetail', displayName: 'Circle with Name, Role & Detail' },
    
  ]

  let [color, setColor] = useState("#0ca5e9")
  const override = {
    width: "50%",
    margin: "auto",
    marginTop: "20%",
  }

  const handleFileDrop = (e) => {
    e.preventDefault();
    const uploadedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    uploadedFiles.forEach(handleFileUpload);
  };

  const handleFileDelete = async (mediaData) => {

    //const storageRef = ref(storage, `files/${mediaData.filename}`)
    let revisedComponentList = []

    // Retrieve visualization components
    sceneData[sessionData.activeIndex].viznodes.forEach(item => {

      // Check to make sure a device that will display
      if (item.id == 2) {

        if (item.media) {
          let mediaItems = []
          item.media.forEach(mediaItem => {
            if (mediaData.guid != mediaItem.guid) {
              //revisedComponentList.push(mediaItem)
              mediaItems.push(mediaItem)
            }
          });


          // TODO - update to remove media item
          let nodeItem = {
            // guid: item.guid,
            // icon: item.icon,
            // id: item.id,
            // name: item.name,
            // type: item.type,
            ...item,
            media: mediaItems,
            //            guid: item.guid,
          }
          revisedComponentList.push(nodeItem)
        }
      }
      else {
        revisedComponentList.push(item)
      }
    });

    let activeSceneId = sceneData[sessionData.activeIndex].id

    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
    await updateDoc(sceneRef, {
      viznodes: revisedComponentList
    })

    // deleteObject(storageRef).then(() => {
    //   console.log('File deleted successfully!');
    //   console.log('-- NEW LIST POST DELETE --')
    //   console.log(revisedComponentList)
    //   console.log('// NEW LIST POST DELETE --')

    // }).catch((error) => {
    //   console.error('Error deleting file:', error);
    // });
  };

  function dataURItoBlob(dataURI, callback) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab]);

    handleFileUpload(bb)

    return bb;
  }

  function downloadImage(base64data) {
    let pdf = {
      file: base64data,
      file_name: new Date().toLocaleDateString()
    }

    // const base64 = base64data
    // fetch(base64)
    //   .then(res => res.blob())
    //   .then(blob => {
    //     const fd = new FormData();
    //     const file = new File([blob], "filename.jpeg");
    //     handleFileUpload(file)
    //   })

    const pdfLink = `${pdf.file}`;
    const anchorElement = document.createElement('a');
    const fileName = `Brevit3_Generated_${pdf.file_name}.jpg`;
    anchorElement.href = pdfLink;
    anchorElement.download = fileName;
    anchorElement.click();
  }



  const doUploadImage = async (dataUrl) => {
    // Get Cloud function named Upload
    const functions = getFunctions();

    var upload = httpsCallable(functions, 'upload'); //firebase.functions().httpsCallable('upload');
    const data = {
      dataUrl: dataUrl
    }
    // Upload Image to Cloudinary
    return upload(JSON.stringify(data))
      .then(response => {
        const data = JSON.parse(response.data)
        // Return the Cloudinary image link
        // return data.secure_url ? data.secure_url : ""
        return data ? data : ""
      })
      .catch((error) => {
        // Return any error message
        return error
      })
  }

  const options = {
    maxSizeMB: 500
  }

  // Hold loading visuals for any added items  
  let loadingItemsArray = []

  const handleFileUploadXXXX = async (file) => {

    loadingItemsArray.push('item')
    setLoadingItems(loadingItemsArray)


    try {
      const output = file //await imageCompression(file, options)
      let reader = new FileReader(output)
      reader.readAsDataURL(output)
      reader.onloadend = async function () {
        let res = await doUploadImage(reader.result)
        //console.log('#############     ################     ---- FILE UPLOAD')
        //console.log(res)
        loadingItemsArray.shift()

        let cloneVizActive = []
        cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

        cloneVizActive.forEach(item => {
          if ((item.id == 1 & uploadMode == 1) & (personaItem.guid == item.guid)) {
            if (item.media) {
              item.media = [
                {
                  ...item.media[0],
                  'image': res.secure_url,
                  'filename': file.name,
                  guid: uuidv4(),
                }]
              //item.personaPosX = 0
            }
            else {
              //revisedComponentList.push(item)
            }
          }
          if ((item.id == 4 & uploadMode == 1) & (personaItem.guid == item.guid)) {
            //console.log('--> Solo Image Mode...')
            if (item.media) {
              item.media = [
                {
                  ...item.media[0],
                  'image': res.secure_url,
                  'filename': file.name,
                  guid: uuidv4(),
                }]
              //item.personaPosX = 0
            }
          }
          else if (item.id == 2 & uploadMode == 2) {
            if (item.media) {
              item.media.push(
                {
                  'image': res.secure_url,
                  'filename': file.name,
                  guid: uuidv4(),
                }
              )
            }
            else {
            }
          }
          else {
          }
        })

        let activeSceneId = sceneData[sessionData.activeIndex].id
        const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

        updateDoc(sceneRef, {
          viznodes: cloneVizActive
        })
      }
    } catch (error) {
      alert(error)
      //console.log(error)
    }

  }




  function selectImage(base64data) {
    const base64 = base64data
    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        const fd = new FormData();
        const file = new File([blob], "filename.jpeg");
        handleFileUpload(file)
      })
  }

  const handleFileUpload = async (file) => {

    loadingItemsArray.push('item')
    setLoadingItems(loadingItemsArray)

    const data = new FormData()
    data.append("file", file)
    data.append(
      "upload_preset",
      'qblr702c'
    )
    data.append("cloud_name", 'djdw2s79u')
    data.append("folder", "Cloudinary-React")

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/djdw2s79u/auto/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const res = await response.json();

      // Remove loading bar...
      loadingItemsArray.shift()

      let cloneVizActive = []
      cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

      cloneVizActive.forEach(item => {

        if (item.guid == personaItem.guid) {
          if ((item.id == 1 & uploadMode == 1) & (personaItem.guid == item.guid)) {

            if (item.media) {
              item.media = [
                {
                  ...item.media[0],
                  'image': res.secure_url,
                  'filename': file.name,
                  guid: uuidv4(),
                }]
            }
            else {
              //revisedComponentList.push(item)
            }

          }
          if ((item.id == 4 & uploadMode == 1) & (personaItem.guid == item.guid)) {
            //console.log('--> Solo Image Mode...')
            if (item.media) {
              item.media = [
                {
                  ...item.media[0],
                  'image': res.secure_url,
                  'filename': file.name,
                  guid: uuidv4(),
                }]
              //item.personaPosX = 0
            }
          }
          else if (item.id == 2 & uploadMode == 2) {
            if (item.media) {
              item.media.push(
                {
                  'image': res.secure_url,
                  'filename': file.name,
                  guid: uuidv4(),
                }
              )
            }
            else {
            }
          }
          else {
          }
        }
      })

      let activeSceneId = sceneData[sessionData.activeIndex].id
      const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

      updateDoc(sceneRef, {
        viznodes: cloneVizActive
      })

      const mediaType = getMediaType(file.name)
      logCustomEvent('upload_media', { type: mediaType})

    } catch (error) {
      alert(error)
      //console.log(error)
    }
  }



  const CompImageGenBrowser = (props) => {

    const [open, setOpen] = useState(false)
    const [generatedImage, setGeneratedImage] = useState();
    const [imageDescription, setImageDescription] = useState('')
    const [aiLoading, setAILoading] = useState(false)
    const [aiError, setAIerror] = useState()

    const [personaGender, setPersonaGender] = useState('female')
    const [personaAge, setPersonaAge] = useState('adult')
    const [personaMood, setPersonaMood] = useState('neutral')
    const [personaLocation, setPersonaLocation] = useState('')
    const [personaJob, setPersonaJob] = useState('')
    const [personaAction, setPersonaAction] = useState('')
    const [personaArtStyle, setPersonaArtStyle] = useState(0)

    // Place
    const [placeArtStyle, setPlaceArtStyle] = useState(0)
    const [placePerspective, setPlacePerspective] = useState(0)

    const [activeGenTab, setActiveGenTab] = useState('person')

    const vizStyle = [
      ' on a pure white background, 35mm 4k', // 1
      ' similar to a high-quality image', // 2
      ' shot with a flat studio background, unreal engine', // 3
      ' in 3D art style', // 4
      ' in digital art in detailed etching style ', // 5      
      ' in pencil sketch/pencil drawing style', // 6
      ' in art for children style', // 7
      ' in ball-point pen art style', // ???
    ]

    const vizStylePlace = [
      ' similar to a high-quality image',
      ' in corporate line art style',
      ' in ink and watercolor illustration style',
      ' vector image style',
      ' colorful vector image style',
      ' ,85mm 4k',
      ' in ball-point pen art style',
      ' as simple, straight line, isometric vector illustration on white background',
    ]

    const emotionStyles = [
      { name: 'happy' },
      //{ name: 'surprised' },
      { name: 'neutral' },
      { name: 'frustrated' },
      { name: 'angry' },
      { name: 'sad' },
    ]

    // NEW - other style...
    const vizStyleView = [
      'close shot',
      'wide shot',
      'aerial',
      'interior',
    ]

    // TODO
    // Help per section...
    const helpSection = [
      '... areas with a lot of concept art online that do not accurately represent...'
    ]


    const generateImage = () => {
      //console.log('Got request for : ' + imageDescription)

      let genQuery = personaMood + ' ' + personaGender + ' ' + personaAge + ' ' + personaJob + ' ' + personaAction + ' at ' + personaLocation + ' ' + vizStyle[personaArtStyle]

      if (activeGenTab == 'custom') {
        genQuery = imageDescription;
      }

      if (activeGenTab == 'place') {
        genQuery = vizStyleView[placePerspective] + ' ' + imageDescription + ' ' + vizStylePlace[placeArtStyle]
        //console.log('Query : ' + genQuery)
      }

      setGeneratedImage('')

      const generateTextFromPrompt = () => {

        //try {
        setAILoading(true)
        setAIerror(null)

        //try {
        const response = fetch(
          `https://us-central1-brevit3-57b00.cloudfunctions.net/generateImageResponse?imageDescription=${encodeURIComponent(genQuery)}`)
          .then((response) => response.json())
          .then((data) => {
            setAILoading(false)
            setAIerror(null)
            setGeneratedImage(`data:image/jpeg;base64,${data.photo}`)
          }
          ).catch(function (error) {                        // catch
            console.log('Request failed', error);
            setAIerror(1)
            setAILoading(false)
          });
      }
      generateTextFromPrompt()

    }

    return (
      <>
        <div className="text-gray-200 admin-component component-card rounded text-sm px-2 ml-1 py-3 h-16 mb-2 text-center" onClick={() => { setOpen(true) }}>
          {props.linkText}
          <img src="/util/app/b3-genai-v2.svg" className='h-5 w-5 mx-auto' />
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0   bg-gray-700 bg-opacity-50  transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex   items-end justify-center p-8   text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden  rounded-lg bg-zinc-900    text-left shadow-xl transition-all sm:my-8 sm:w-2/3   ">
                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                      <button
                        type="button"
                        className="rounded-md   focus:outline-none  "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                      </button>
                    </div>
                    <div className="sm:items-start">
                      <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div className="p-4">
                          <div className='text-xl mt-4  text-gray-50  font-base mb-6'>
                            Story Media Studio <span className="text-amber-500 text-sm ml-2">beta</span>
                          </div>



                          <div className="section mb-4">
                            <div className="flex text-center text-base pr-1">
                              <button
                                className={`py-2 w-1/3   border-b-2  text-center  focus:outline-none tab-button  ${activeGenTab == 'person' ? ' text-white border-gray-200 ' : ' hover:border-zinc-700 border-zinc-800 text-gray-400'} `}
                                onClick={() => {
                                  setActiveGenTab('person')
                                }}>
                                Person
                              </button>
                              <button
                                className={`py-2  w-1/3 border-b-2   text-center    focus:outline-none   ${activeGenTab == 'place' ? ' text-white border-gray-50 ' : ' hover:border-zinc-700 border-zinc-800 text-gray-400'} `}
                                onClick={() => {
                                  setActiveGenTab('place')
                                }}>
                                Place
                              </button>
                              <button
                                className={`py-2  w-1/3 border-b-2  text-center   focus:outline-none  ${activeGenTab == 'custom' ? ' text-white border-gray-50 ' : ' hover:border-zinc-700 border-zinc-800 text-gray-400'} `}
                                onClick={() => {
                                  setActiveGenTab('custom')
                                }}>
                                Custom
                              </button>
                            </div>
                          </div>



                          <div id="tab1Gen" className={`tab-content text-gray-200 ${activeGenTab != 'person' ? ' hidden' : ''} `}>
                            <div className='grid grid-cols-2 gap-4'>
                              <div className='gap-4 pr-4 '>
                                <div className='flex-auto  mt-4 text-gray-400 text-sm'>
                                  <div className='text-gray-200 mb-2 text-base ml-2'>Profession</div>
                                  <input
                                    type="text"
                                    className="block flex rounded-md border-0 px-4 py-3   bg-transparent text-gray-100 shadow-sm ring-1 ring-inset w-full
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                    placeholder="what role or job are they in?"
                                    value={personaJob}
                                    onChange={(e) => setPersonaJob(e.target.value)}
                                  />
                                </div>
                                <div className='flex-auto  mt-5 text-gray-400 text-sm'>
                                  <div className='text-gray-200 mb-2 text-base ml-2'>Activity </div>
                                  <input
                                    type="text"
                                    className="block flex rounded-md border-0 px-4 py-3   bg-transparent text-gray-100 shadow-sm ring-1 ring-inset w-full
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                    placeholder="what activity are they doing?"
                                    value={personaAction}
                                    onChange={(e) => setPersonaAction(e.target.value)}
                                  />
                                </div>
                                <div className='flex-auto mt-5 text-gray-400 text-sm'>
                                  <div className='text-gray-200 mb-2 text-base ml-2'>Place </div>
                                  <input
                                    type="text"
                                    className="block flex rounded-md border-0 px-4 py-3 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset w-full
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                    placeholder="where are they?"
                                    value={personaLocation}
                                    onChange={(e) => setPersonaLocation(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className='w-full  text-gray-400 text-sm pr-4 gap-4'>
                                <div className="flex-auto">
                                  <div className='text-gray-300 mt-2  mb-2 ml-2 text-base'>Gender</div>
                                  <div className='flex gap-2 bg-black/20 rounded p-2'>
                                    <div
                                      className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaGender == 'female' ? "border-opacity-100" : "border-opacity-0"}`}
                                      onClick={() => {
                                        setPersonaGender('female')
                                      }}
                                    >Female</div>
                                    <div
                                      className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaGender == 'male' ? "border-opacity-100" : "border-opacity-0"}`}
                                      onClick={() => {
                                        setPersonaGender('male')
                                      }}
                                    >Male</div>
                                  </div>
                                  <div className='text-gray-300 mt-4 mb-2 ml-2 text-base'>Age </div>
                                  <div className='grid grid-cols-5 gap-2 bg-black/20 rounded p-2'>
                                    <div
                                      className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaAge == 'teen' ? "border-opacity-100" : "border-opacity-0"}`}
                                      onClick={() => {
                                        setPersonaAge('teen')
                                      }}
                                    >Teen</div>
                                    <div
                                      className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaAge == 'young adult' ? "border-opacity-100" : "border-opacity-0"}`}
                                      onClick={() => {
                                        setPersonaAge('young adult')
                                      }}
                                    >Yng Adult</div>
                                    <div
                                      className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaAge == 'adult' ? "border-opacity-100" : "border-opacity-0"}`}
                                      onClick={() => {
                                        setPersonaAge('adult')
                                      }}
                                    >Adult</div>
                                    <div
                                      className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaAge == 'middle age' ? "border-opacity-100" : "border-opacity-0"}`}
                                      onClick={() => {
                                        setPersonaAge('middle age')
                                      }}
                                    >Mid Age</div>
                                    <div
                                      className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaAge == 'senior citizen' ? "border-opacity-100" : "border-opacity-0"}`}
                                      onClick={() => {
                                        setPersonaAge('senior citizen')
                                      }}
                                    >Senior</div>
                                  </div>
                                </div>
                                <div className="flex-auto ">
                                  <div className='text-gray-300 mt-4  mb-2 ml-2 text-base'>Expression</div>
                                  <div className='grid grid-cols-5 gap-2 bg-black/20 rounded p-2'>
                                    {emotionStyles.map((style) => (
                                      <div
                                        key={encodeURIComponent(style.name)}
                                        className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaMood == style.name ? "border-opacity-100" : "border-opacity-0"}`}
                                        onClick={() => { setPersonaMood(style.name) }}
                                      >
                                        {style.name}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='flex w-full mt-4 text-gray-400 text-sm pr-4 gap-4'>
                              <div className="flex-auto">
                                <div className='text-gray-300 mb-2 text-base ml-2'>Style</div>
                                <div className='flex gap-2 bg-black/20 rounded p-2'>
                                  <div
                                    className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaArtStyle == 0 ? "border-opacity-100" : "border-opacity-0"}`}
                                    onClick={() => {
                                      setPersonaArtStyle(0)
                                    }}
                                  >
                                    <img src="/util/gen/style-1.png" className='w-full rounded' />
                                  </div>
                                  <div
                                    className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaArtStyle == 1 ? "border-opacity-100" : "border-opacity-0"}`}
                                    onClick={() => {
                                      setPersonaArtStyle(1)
                                    }}
                                  >
                                    <img src="/util/gen/style-2.png" className='w-full rounded' />
                                  </div>
                                  <div
                                    className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaArtStyle == 2 ? "border-opacity-100" : "border-opacity-0"}`}
                                    onClick={() => {
                                      setPersonaArtStyle(2)
                                    }}
                                  >
                                    <img src="/util/gen/style-3.png" className='w-full rounded' />
                                  </div>
                                  <div
                                    className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaArtStyle == 3 ? "border-opacity-100" : "border-opacity-0"}`}
                                    onClick={() => {
                                      setPersonaArtStyle(3)
                                    }}
                                  >
                                    <img src="/util/gen/style-4.png" className='w-full rounded' />
                                  </div>
                                  <div
                                    className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaArtStyle == 4 ? "border-opacity-100" : "border-opacity-0"}`}
                                    onClick={() => {
                                      setPersonaArtStyle(4)
                                    }}
                                  >
                                    <img src="/util/gen/style-5.png" className='w-full rounded' />
                                  </div>
                                  <div
                                    className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaArtStyle == 5 ? "border-opacity-100" : "border-opacity-0"}`}
                                    onClick={() => {
                                      setPersonaArtStyle(5)
                                    }}
                                  >
                                    <img src="/util/gen/style-6.png" className='w-full rounded' />
                                  </div>
                                  <div
                                    className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${personaArtStyle == 6 ? "border-opacity-100" : "border-opacity-0"}`}
                                    onClick={() => {
                                      setPersonaArtStyle(6)
                                    }}
                                  >
                                    <img src="/util/gen/style-7.png" className='w-full rounded' />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='flex w-full mt-4 pr-6'>
                              <div className='flex-auto'>
                                <input
                                  type="text"
                                  className="block flex rounded-md border-0 px-4 py-2 my-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset w-full
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                  placeholder="Short description (optional, the above options are generally enough for personas)"
                                  value={imageDescription}
                                  onChange={(e) => setImageDescription(e.target.value)}
                                />
                              </div>
                              <div className='flex-none w-40 ml-4'>
                                <button onClick={() =>
                                  generateImage()
                                }
                                  className='flex-none w-40 justify-center rounded-sm bg-sky-500 px-3 py-1.5
                    text-base  text-white bg-sky-500 hover:bg-sky-400 mt-4'
                                >Generate Image</button>
                              </div>
                            </div>
                          </div>
                          <div id="tab2Gen" className={`tab-content text-gray-200 ${activeGenTab != 'place' ? ' hidden' : ''} `}>
                            <div className='flex w-full mt-4 text-gray-400 text-sm pr-4 gap-4'>
                              <div className="flex-auto">
                                <div className='text-gray-300 mt-4 mb-2 ml-2 text-base'>View </div>
                                <div className='grid grid-cols-5 gap-2 bg-black/20 rounded p-2'>

                                  {vizStyleView.map((view, index) => (
                                    <div
                                      key={index + 'view'}
                                      className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${placePerspective == index ? "border-opacity-100" : "border-opacity-0"}`}
                                      onClick={() => {
                                        setPlacePerspective(index)
                                      }}
                                    >{view}</div>
                                  ))}

                                </div>
                              </div>
                            </div>

                            <div className="flex-auto pr-4">
                              <div className='text-gray-300 mb-2 mt-4 text-base ml-2'>Style   </div>
                              <div className='flex gap-2 bg-black/20 rounded p-2'>

                                {vizStylePlace.map((place, index) => (
                                  <div
                                    className={`border-solid border-2  border-sky-500 bg-white/10 hover:bg-white/20 rounded p-2 text-center cursor-pointer ${placeArtStyle == index ? "border-opacity-100" : "border-opacity-0"}`}
                                    key={index + 'place'}
                                    onClick={() => {
                                      setPlaceArtStyle(index)
                                    }}
                                  >
                                    <img src={"/util/gen/place" + (index + 1) + ".png"} className='w-full rounded' />
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className='flex w-full mt-4 pr-5'>
                              <div className='flex-auto'>
                                <input
                                  type="text"
                                  className="block flex rounded-md border-0 px-4 py-2 my-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset w-full
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                  placeholder="Short description (e.g. warehouse, cell tower, office building, etc)"
                                  value={imageDescription}
                                  onChange={(e) =>
                                    setImageDescription(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    //console.log(e.key)
                                    if (e.key === "Enter") {
                                      generateImage()
                                    }
                                  }}
                                />
                              </div>
                              <div className='flex-none w-40 ml-4'>
                                <button onClick={() => generateImage()}
                                  className='flex-none w-40 justify-center rounded-sm bg-sky-500 px-3 py-1.5
                    text-base  text-white bg-sky-500 hover:bg-sky-400 mt-4'
                                >Generate Image</button>
                              </div>
                            </div>


                          </div>
                          <div id="tab3Gen" className={`tab-content text-gray-200 ${activeGenTab != 'thing' ? ' hidden' : ''} `}>
                            <div className='text-gray-100'>
                              Gen Tab 3
                            </div>
                          </div>


                          <div id="tab4Gen" className={`tab-content  text-gray-200 ${activeGenTab != 'custom' ? ' hidden' : ''} `}>
                            <div className='flex w-full mt-4 pr-6'>
                              <div className='flex-auto'>
                                <input
                                  type="text"
                                  className="block flex rounded-md border-0 px-4 py-2 my-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset w-full
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                  placeholder="Detailed description - the more specific you can be, the better the result will be"
                                  value={imageDescription}
                                  onChange={(e) =>
                                    setImageDescription(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    //console.log(e.key)
                                    if (e.key === "Enter") {
                                      generateImage()
                                    }
                                  }}
                                />
                              </div>
                              <div className='flex-none w-40 ml-4'>
                                <button onClick={() => generateImage()}
                                  className='flex-none w-40 justify-center rounded-sm bg-sky-500 px-3 py-1.5
                    text-base  text-white bg-sky-500 hover:bg-sky-400 mt-4'
                                >Generate Image</button>
                              </div>
                            </div>


                          </div>


                          {/* <div className='w-full text-gray-300 p-2'>
                            {personaMood} {personaAge} {personaJob} {personaAction} at {personaLocation} in {vizStyle[personaArtStyle]}
                          </div> */}


                          <div className=" h-[60vh] overflow-y-auto pr-4 section-scroll">

                            {aiError ?
                              <div className='w-full inline-block pt-52'>
                                <div className="text-yellow-300 text-center">OpenAI Error - please try again shortly, the system may be at capacity or experiencing delays</div>
                              </div>
                              : null
                            }

                            {aiLoading ?
                              <div className='w-full inline-block pt-52'>
                                <div className="animated-gradient"></div>
                              </div>
                              : null
                            }

                            {generatedImage ?
                              <div className='w-full flex'>
                                <div className='w-3/4 pr-4'>
                                  <img src={generatedImage} className='w-full' />
                                </div>
                                <div className='w-1/4 text-gray-200'>
                                  <div
                                    className={`   bg-white/10 hover:bg-white/20 rounded p-2 text-left text-sm cursor-pointer `}
                                    onClick={() => {
                                      generateImage()
                                    }}
                                  >
                                    <img src="/util/app/b3-redo.svg" className='inline mr-2 h-6' /> redo
                                  </div>
                                  <div
                                    className={`mt-2   bg-white/10 hover:bg-white/20 rounded p-2 text-left text-sm cursor-pointer `}
                                    onClick={() => {
                                      downloadImage(generatedImage)
                                    }}
                                  >
                                    <img src="/util/app/b3-gen-save.svg" className='inline mr-2 h-6' /> save locally
                                  </div>
                                  <div
                                    className={`mt-2  bg-white/10 hover:bg-white/20 rounded p-2 text-left text-sm cursor-pointer `}
                                    onClick={() => {
                                      //setPersonaAge('middle age')
                                      selectImage(generatedImage)
                                    }}
                                  >
                                    <img src="/util/app/b3-use-image.svg" className='inline mr-2 h-6' /> use image
                                  </div>
                                </div>
                              </div>
                              : null}


                          </div>
                          {/* <div className='text-base mt-8 mb-8  text-gray-50'>
                            {generatedImage ?
                              <div
                                className='justify-center rounded-sm bg-sky-500 px-3 py-1.5
                              text-base text-white bg-sky-500 hover:bg-sky-400 mt-4 text-center'
                                onClick={() => { downloadImage(generatedImage) }}>Download Image</div>
                              : null
                            }
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }


  const handleImageCardUpdateProps = (mediaItem, mediaNode, personaSize, personaPositionXY, personaDelay, personaAnimation, cardFaceCamera, cardScale) => {
    let cloneVizActive = []
    cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizActive.forEach(vizItem => {
      if (vizItem.media) {

        if (vizItem.guid == mediaNode.item.guid) {
          vizItem['pos'] = personaPositionXY
          vizItem['size'] = personaSize
          vizItem['animationDuration'] = personaDelay
          vizItem['animation'] = personaAnimation
          vizItem['faceCamera'] = cardFaceCamera

          if (cardFaceCamera != null) {
            vizItem['faceCamera'] = cardFaceCamera
          }

          if (cardScale != null) {
            vizItem['scale'] = cardScale
          }
        }
      }

      let activeSceneId = sceneData[sessionData.activeIndex].id
      const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

      updateDoc(sceneRef, {
        viznodes: cloneVizActive
      })
    })
  }

  const handleImageCardUpdate = (singleMediaItem, mediaNode, personaName, personaRole, personaDetail, personaPosX, personaPositionXY, personaSize = { width: 0, height: 0 }, personaDelay, personaAnimation, personaLayout, cardFaceCamera, cardScale) => {

    let cloneVizActive = []
    cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizActive.forEach(vizItem => {
      if (vizItem.media) {
        if (personaPosX == null) {
          personaPosX = 0
        }
        if (personaName == null) {
          personaName = ''
        }
        if (personaRole == null) {
          personaRole = ''
        }
        if (personaDetail == null) {
          personaDetail = ''
        }

        if (vizItem.guid == mediaNode.item.guid) {
          vizItem.media[0]['personaName'] = personaName
          vizItem.media[0]['personaRole'] = personaRole
          vizItem.media[0]['personaDetail'] = personaDetail
          vizItem['personaPosX'] = personaPosX
          vizItem['pos'] = personaPositionXY

          if (personaLayout) {
            vizItem['layout'] = personaLayout
          }
          if (personaAnimation) {
            vizItem['animationDuration'] = personaDelay
            vizItem['animation'] = personaAnimation
          }
          if (cardFaceCamera != null) {
            vizItem['faceCamera'] = cardFaceCamera
          }
          if (cardScale != null) {
            vizItem['scale'] = cardScale
          }
        }
      }

      let activeSceneId = sceneData[sessionData.activeIndex].id
      const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

      updateDoc(sceneRef, {
        viznodes: cloneVizActive
      })
    })
  }

  const handleDeviceCardUpdate = (mediaNode, item, personaSize, personaPositionXY, personaDelay, personaAnimation, cardFaceCamera, cardScale, cardAutoPlay, galleryNavigation) => {
    let cloneVizActive = []
    cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]
    cloneVizActive.forEach(vizItem => {
      if (vizItem.media) {
        if (item.item.guid == vizItem.guid) {
          vizItem['pos'] = personaPositionXY
          vizItem['size'] = personaSize
          vizItem['animationDuration'] = personaDelay
          vizItem['animation'] = personaAnimation
          vizItem['faceCamera'] = cardFaceCamera

          if (cardFaceCamera != null) {
            vizItem['faceCamera'] = cardFaceCamera
          }

          if (cardScale != null) {
            vizItem['scale'] = cardScale
          }

          if (cardAutoPlay != null) {
            vizItem['videoAutoPlay'] = cardAutoPlay
          }

          if (galleryNavigation != null) {
            vizItem['galleryNavigation'] = galleryNavigation
          }

        }
      }

      let activeSceneId = sceneData[sessionData.activeIndex].id
      const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

      updateDoc(sceneRef, {
        viznodes: cloneVizActive
      })
    })
  }

  const RenderMediaList = () => {

    var moveInArray = function (arr, from, to) {
      if (Object.prototype.toString.call(arr) !== '[object Array]') {
        throw new Error('Please provide a valid array');
      }
      var item = arr.splice(from, 1);
      if (!item.length) {
        throw new Error('There is no item in the array at index ' + from);
      }
      arr.splice(to, 0, item[0]);
    };

    function handleOnDragEnd(result) {
      if (!result.destination) return;

      let itemsArrayReorder = []

      let activeSceneId = sceneData[sessionData.activeIndex].id
      const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

      let nodeIndex = 0;
      sceneData[sessionData.activeIndex].viznodes.forEach(element => {
        if (activeComponentData.guid == element.guid) {
          itemsArrayReorder = sceneData[sessionData.activeIndex].viznodes[nodeIndex].media
          moveInArray(itemsArrayReorder, result.source.index, result.destination.index)

          // Update the data...
          sceneData[sessionData.activeIndex].viznodes[nodeIndex].media = itemsArrayReorder

          updateDoc(sceneRef, {
            viznodes: sceneData[sessionData.activeIndex].viznodes
          })

        }
        nodeIndex++
      });

    }

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    const FormPersona = (item, mediaItem, activeIndex, viznodes) => {

      const [personaName, setPersonaName] = useState(item.mediaItem.personaName)
      const [personaRole, setPersonaRole] = useState(item.mediaItem.personaRole)
      const [personaPosX, setPersonaPosX] = useState(item.item.personaPosX)
      const [personaDetail, setPersonaDetail] = useState(item.mediaItem.personaDetail)
      const [personaAnimation, setPersonaAnimation] = useState(item.item.animation || 'none')
      const [personaDelay, setPersonaDelay] = useState(item.item.animationDuration || 1)
      const [personaLayout, setPersonaLayout] = useState(item.item.layout || { id: 1, name: 'personaTop', displayName: 'Persona on Top' })
      const [personaPositionXY, setPersonaPositionXY] = useState({ x: item.item.pos.x, y: item.item.pos.y })
      const [cardScale, setCardScale] = useState(item.item.scale || 1)
      const [cardFaceCamera, setCardFaceCamera] = useState(item.item.faceCamera || false)

      const faceCameraHandler = () => { setCardFaceCamera(!cardFaceCamera) }

      const [sceneData, setSceneData] = useContext(SceneContext)
      const [sessionData, setSessionData] = useContext(SessionContext)

      const setNewPos = (newPos) => {

        setPersonaPositionXY({ x: newPos.x, y: newPos.y })

        let cloneVizActive = []
        cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]
        cloneVizActive.forEach(vizItem => {
          if (vizItem.guid == item.item.guid) {
            vizItem['pos'] = { x: newPos.x, y: newPos.y }
          }
        })

        let cloneSceneDataActive = []
        cloneSceneDataActive = [...sceneData]
        cloneSceneDataActive[sessionData.activeIndex].viznodes = cloneVizActive
        setSceneData(cloneSceneDataActive)

        const activeSceneId = sceneData[sessionData.activeIndex].id

        const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
        //await 
        updateDoc(sceneRef, {
          viznodes: cloneVizActive
        })
      }

      const handleBlur = (event) => {
        // Do something when the input field loses focus
        //handleImageCardUpdate(item.mediaItem, item, personaName, personaRole, personaDetail, personaPosX, personaPositionXY, null, personaDelay, personaAnimation, personaLayout, cardFaceCamera, cardScale)
      }


      return (
        <>
          <form onSubmit={(e) => {
            e.preventDefault()
            handleImageCardUpdate(item.mediaItem, item, personaName, personaRole, personaDetail, personaPosX, personaPositionXY, cardFaceCamera, cardScale)
          }}>
            <div className="sm:col-span-2 mt-4">

            

              <div className="mt-2.5">
                <input
                  autoComplete="off"
                  value={personaName}
                  onChange={(e) => setPersonaName(e.target.value)}
                  type="text"
                  name="persona-name"
                  id="persona-name"
                  placeholder='Name'
                  onBlur={handleBlur} 
                  tabIndex="1" 
                  className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
                    text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
                    focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                />
              </div>
              <div className="mt-2.5">
                <input
                  autoComplete="off"
                  value={personaRole}
                  onChange={(e) => setPersonaRole(e.target.value)}
                  type="text"
                  placeholder='Role'
                  onBlur={handleBlur} 
                  tabIndex="2"
                  name="persona-role"
                  id="phone-number"
                  className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
                    text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
                    focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                // className="remove-arrow bg-black/50 px-3 w-full  text-left block rounded-md border-0  py-2.5 bg-transparent text-gray-100  ring-1 ring-inset
                // ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-sm sm:leading-6v focus:outline-none"
                />
              </div>
              <div className="mt-2.5">
                <textarea
                  autoComplete="off"
                  value={personaDetail}
                  onChange={(e) => setPersonaDetail(e.target.value)}
                  type="text"
                  onBlur={handleBlur} 
                  tabIndex="3" 
                  placeholder='Details of activity'
                  name="persona-detail"
                  id="persona-detail"
                  className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
                    text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
                    focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                />
              </div>


              <div className="mt-2.5 text-gray-300 flex">

                <div className='flex w-1/5 ml-1 text-sm mt-2 mr-4'>
                  Layout
                </div>


                {/* personaLayout, setPersonaLayout */}

                <div className='flex-auto'>
                  <Listbox value={personaLayout} onChange={setPersonaLayout}>
                    {({ open }) => (
                      <>
                        <div className="relative  ">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6
                            hover:bg-white/10
                            ">
                            <span className="block truncate">{personaLayout.displayName || 'none'} &nbsp;</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {layoutStyles.map((layout) => (
                                <Listbox.Option
                                  key={layout.id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={layout}
                                >
                                  {({ cardAnimation, active }) => (
                                    <>
                                      <span className={classNames(personaLayout ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {layout.displayName}
                                      </span>
                                      {/* {personaLayout ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-sky-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null} */}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>


              <div className="mt-2.5 grid grid-cols-2 gap-0">

                <div className="relative flex items-start">
                  <div className="ml-1 text-sm leading-6 mr-2">
                    {/* <label htmlFor="candidates" className="font-medium text-gray-100">
            Turn to face camera
        </label> */}
                    <p id="candidates-description" className="text-gray-300 text-sm mt-2">
                      Always Face
                    </p>
                  </div>
                  <div className="flex h-6 items-center mt-2">
                    <input
                      id="faceCamera"
                      aria-describedby="faceCamera"
                      name="faceCamera"
                      type="checkbox"
                      checked={cardFaceCamera}
                      onChange={faceCameraHandler}
                      className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                    />
                  </div>
                </div>


                <div className='grid grid-cols-5 gap-0'>
                  <div id="" className="text-gray-300 col-span-2 text-sm mt-2 text-right mr-2">
                    Scale
                  </div>

                  <div className='grid grid-cols-10 gap-0 col-span-3 ml-2'>
                    <div className='col-span-3  p-2  text-center  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                      onClick={(e) =>
                        //setCardScale(cardScale - .25)
                        cardScale > 0 ? setCardScale(cardScale - .25) : 0
                      }
                    >-</div>
                    <div className='col-span-4'>
                      <div className='className="block w-full rounded-md border-0  px-1.5 py-2 
text-white   sm:text-sm sm:leading-6 text-center'>
                        {cardScale}
                      </div>
                    </div>
                    <div className='col-span-3 p-2  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                      onClick={(e) => setCardScale(cardScale + .25)}
                    >+</div>
                  </div>
                </div>
              </div>


              <div className='flex w-full'>

                <div className='flex w-1/5 mr-4 text-gray-300 pt-5 ml-1 text-sm pr-3'>
                  Animation
                </div>
                <div className='flex-auto'>
                  <div className='grid grid-cols-5 gap-3 mt-3  '>
                    <div className='col-span-3'>
                      <Listbox value={personaAnimation} onChange={setPersonaAnimation}>
                        {({ open }) => (
                          <>
                            <div className="relative  -ml-4">
                              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6
                            hover:bg-white/10
                            ">
                                <span className="block truncate">{personaAnimation.displayName || 'none'} &nbsp;</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>
                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {animationStyles.map((person) => (
                                    <Listbox.Option
                                      key={person.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                          'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )
                                      }
                                      value={person}
                                    >
                                      {({ cardAnimation, active }) => (
                                        <>
                                          <span className={classNames(personaAnimation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                            {person.displayName}
                                          </span>
                                          {cardAnimation ? (
                                            <span
                                              className={classNames(
                                                active ? 'text-white' : 'text-sky-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className='col-span-2'>
                      <div className='grid grid-cols-10 gap-0'>
                        <div className='col-span-3  p-2  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                          onClick={(e) =>
                            //setPersonaDelay(personaDelay - 1)
                            personaDelay > 0 ? setPersonaDelay(personaDelay - 1) : 0
                          }
                        >-</div>
                        <div className='col-span-4'>
                          <div className='className="block w-full rounded-md border-0  px-1.5 py-2 
text-white   sm:text-sm sm:leading-6 text-center'>
                            {!personaAnimation.displayName ? 0 : personaDelay}
                          </div>
                        </div>
                        <div className='col-span-3 p-2 font-bold cursor-pointer text-center bg-white/5 hover:bg-white/10 rounded'
                          onClick={(e) => setPersonaDelay(personaDelay + 1)}
                        >+</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>






              <div className='w-100p float-left slider-parent mt-3'>



                <GridSelect
                  //viznodes
                  activeNode={item.item.guid}
                  viznodes={
                    sceneData[sessionData.activeIndex].viznodes
                    //sceneDataforGrid
                  }
                  setCardPositionXY={setNewPos}
                />


                {/* 
                <Slider
                  axis="xy"
                  xmin={-30}
                  xmax={30}
                  ymin={-10}
                  ymax={30}
                  xreverse={true}
                  yreverse={true}
                  x={personaPositionXY.x}
                  y={personaPositionXY.y}
                  onChange={setPersonaPositionXY}

                  styles={{
                    active: {
                      backgroundColor: '#333'
                    },
                    track: {
                      backgroundColor: '#1b1e21'
                    }
                  }}
                /> */}
              </div>

              {/* (singleMediaItem, mediaNode, personaName, personaRole, personaDetail, personaPosX, personaPositionXY, personaSize = { width: 0, height: 0 }, personaDelay, personaAnimation) */}

              <div className="mt-4  ">
                <button onClick={() => handleImageCardUpdate(item.mediaItem, item, personaName, personaRole, personaDetail, personaPosX, personaPositionXY, null, personaDelay, personaAnimation, personaLayout, cardFaceCamera, cardScale)}
                  className='w-full justify-center rounded-sm bg-sky-500 px-3 py-1
                    text-base  text-white bg-sky-500 hover:bg-sky-400 mt-4'
                >Update</button>
              </div>
            </div>
          </form>
        </>
      )
    }


    // Gallery Component
    const FormDevice = (item) => {

      const [sceneData, setSceneData] = useContext(SceneContext)
      const [sessionData, setSessionData] = useContext(SessionContext)

      const [personaSize, setPersonaSize] = useState({ width: item.item.size.width, height: item.item.size.height })
      const [personaPositionXY, setPersonaPositionXY] = useState({ x: item.item.pos.x, y: item.item.pos.y })

      const [personaAnimation, setPersonaAnimation] = useState(item.item.animation || 'none')
      const [personaDelay, setPersonaDelay] = useState(item.item.animationDuration || 1)

      const [galleryNavigation, setGalleryNavigation] = useState(item.item.galleryNavigation || 'Thumbnails')

      const [cardScale, setCardScale] = useState(item.item.scale || 1)
      const [cardFaceCamera, setCardFaceCamera] = useState(item.item.faceCamera || false)

      // cardAutoPlay

      const [cardAutoPlay, setCardAutoPlay] = useState(item.item.videoAutoPlay || false)

      const faceCameraHandler = () => {
        setCardFaceCamera(!cardFaceCamera)
      }

      const handleSliderXChange = (value) => {
        setPersonaPosX(value)
      }

      const [devicePosX, setDevicePosX] = useState()



      // console.log('MediaUploader...')
      // console.log(sceneData[sessionData.activeIndex].viznodes)


      // const [sceneData, setSceneData] = useContext(SceneContext)
      // const [sessionData, setSessionData] = useContext(SessionContext)

      const setNewPos = (newPos) => {
        // console.log('getNewPos')
        // console.log(newPos.x)
        // console.log(newPos.y)

        setPersonaPositionXY({ x: newPos.x, y: newPos.y })

        let cloneVizActive = []
        cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]
        cloneVizActive.forEach(vizItem => {

          if (vizItem.guid == item.item.guid) {
            vizItem['pos'] = { x: newPos.x, y: newPos.y }
          }
        })

        let cloneSceneDataActive = []
        cloneSceneDataActive = [...sceneData]
        cloneSceneDataActive[sessionData.activeIndex].viznodes = cloneVizActive
        setSceneData(cloneSceneDataActive)

        const activeSceneId = sceneData[sessionData.activeIndex].id

        const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
        //await 
        updateDoc(sceneRef, {
          viznodes: cloneVizActive
        })
      }
 


      return (

        <div className="sm:col-span-2 mt-4 text-sm">
          <div className='w-100p float-left slider-parent-size mt-3'>
            <div className='grid grid-cols-4 gap-4 w-full mb-6'>
              <div className='text-gray-300 col-span-2 ml-2 mt-2'>Autoplay</div>
              <div className='col-span-2'>
                <div className="flex rounded-md w-full p-1 bg-white/5 gap-1 ">
                  <div
                    className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                          ${cardAutoPlay == true ? 'bg-sky-600 text-gray-200  ' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                    onClick={(e) => { setCardAutoPlay(true) }}>
                    Enabled
                  </div>
                  <div
                    className={`flex-auto  text-center  items-center rounded-md  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                       ${cardAutoPlay == false ? 'bg-sky-600 text-gray-200  ' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                    onClick={(e) => {
                      setCardAutoPlay(false)
                    }}>
                    Manual
                  </div>
                </div>
              </div>
            </div>



            <div className='grid grid-cols-4 gap-4 w-full mb-6'>
              <div className='text-gray-300 col-span-2 ml-2 mt-2'>Navigation</div>
              <div className='col-span-2'>

                <Listbox value={galleryNavigation.name} onChange={setGalleryNavigation}>
                  {({ open }) => (
                    <>
                      <div className="relative  ">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6
            hover:bg-white/10
            ">
                          <span className="block truncate">{galleryNavigation.name || 'Thumbnails'}  &nbsp;</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {thumbnailStyles.map((person) => (
                              <Listbox.Option
                                key={person.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )
                                }
                                value={person}
                              >
                                {person.name}
                                {/* {({ cardAnimation, active }) => (
                                  <>
                                    <span className={classNames(galleryNavigation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {person.name}
                                    </span>
                                    {cardAnimation ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-sky-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )} */}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>

              </div>
            </div>

            <div className='grid grid-cols-5 gap-4 w-full'>
              <div className='text-gray-300 col-span-1 ml-2'>Width </div>
              <div className='col-span-3'>
                <Slider
                  axis="x"
                  xmin={100}
                  xmax={1600}
                  xstep={10}
                  xreverse={false}
                  x={personaSize.width}
                  onChange={({ x }) => setPersonaSize(state => ({ ...state, width: x }))}
                  styles={{
                    active: {
                      backgroundColor: '#333'
                    },
                    track: {
                      backgroundColor: '#1b1e21'
                    }
                  }}
                />
              </div>
              <div className='text-gray-300 col-span-1'>{personaSize.width}</div>
            </div>

          </div>



          <div className='flex w-full'>

            <div className='flex  w-1/5 text-gray-200 pt-5 pl-2 text-sm pr-3'>
              Anim
            </div>
            <div className='flex-auto'>
              <div className='grid grid-cols-5 gap-3 mt-3  '>
                <div className='col-span-3'>
                  <Listbox value={personaAnimation} onChange={setPersonaAnimation}>
                    {({ open }) => (
                      <>
                        <div className="relative  ">
                          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6
            hover:bg-white/10
            ">
                            <span className="block truncate">{personaAnimation.displayName || 'none'}  &nbsp;</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {animationStyles.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={person}
                                >
                                  {({ cardAnimation, active }) => (
                                    <>
                                      <span className={classNames(personaAnimation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {person.displayName}
                                      </span>
                                      {cardAnimation ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-sky-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
                <div className='col-span-2'>
                  <div className='grid grid-cols-10 gap-0'>
                    <div className='col-span-3  p-2  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                      onClick={(e) => personaDelay > 0 ? setPersonaDelay(personaDelay - 1) : 0}
                    >-</div>
                    <div className='col-span-4'>
                      <div className='className="block w-full rounded-md border-0  px-1.5 py-2 
text-white   sm:text-sm sm:leading-6 text-center'>
                        {!personaAnimation.displayName ? 0 : personaDelay}
                      </div>
                    </div>
                    <div className='col-span-3 p-2 font-bold cursor-pointer text-center bg-white/5 hover:bg-white/10 rounded'
                      onClick={(e) => setPersonaDelay(personaDelay + 1)}
                    >+</div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="mt-2.5 grid grid-cols-2 gap-0">

            <div className="relative flex items-start">
              <div className="ml-1 text-sm leading-6 mr-2">
                {/* <label htmlFor="candidates" className="font-medium text-gray-100">
Turn to face camera
</label> */}
                <p id="candidates-description" className="text-gray-300 text-sm mt-2 ml-1">
                  Rotate to Camera
                </p>
              </div>
              <div className="flex h-6 items-center mt-2">
                <input
                  id="faceCamera"
                  aria-describedby="faceCamera"
                  name="faceCamera"
                  type="checkbox"
                  checked={cardFaceCamera || false}
                  onChange={faceCameraHandler}
                  className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                />
              </div>
            </div>


            <div className='grid grid-cols-5 gap-0'>
              <div id="" className="text-gray-300 col-span-2 text-sm mt-2 text-right mr-2">
                Scale
              </div>

              <div className='grid grid-cols-10 gap-0 col-span-3 ml-2'>
                <div className='col-span-3  p-2  text-center  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                  onClick={(e) =>
                    //setCardScale(cardScale - .25)
                    cardScale > 0 ? setCardScale(cardScale - .25) : 0
                  }
                >-</div>
                <div className='col-span-4'>
                  <div className='className="block w-full rounded-md border-0  px-1.5 py-2 
text-white   sm:text-sm sm:leading-6 text-center'>
                    {cardScale}
                  </div>
                </div>
                <div className='col-span-3 p-2  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                  onClick={(e) => setCardScale(cardScale + .25)}
                >+</div>
              </div>
            </div>
          </div>

          <div className='w-100p float-left slider-parent mt-3'>
            {/* <h2 className='position-overlay'>Position {personaPositionXY.x}</h2> */}



            <GridSelect
              //viznodes
              activeNode={item.item.guid}
              viznodes={
                sceneData[sessionData.activeIndex].viznodes
                //sceneDataforGrid[sessionData.activeIndex].viznodes
              }
              setCardPositionXY={setNewPos}
            />




            {/* <Slider
              axis="xy"
              xmin={-30}
              xmax={30}
              ymin={-10}
              ymax={30}
              xreverse={true}
              yreverse={true}
              x={personaPositionXY.x}
              y={personaPositionXY.y}
              onChange={setPersonaPositionXY}
              styles={{
                active: {
                  backgroundColor: '#333'
                },
                track: {
                  backgroundColor: '#1b1e21'
                }
              }}
            /> */}
          </div>
          <div className="sm:col-span-2 mt-6">
            <button onClick={() => handleDeviceCardUpdate(item.mediaItem, item, personaSize, personaPositionXY, personaDelay, personaAnimation, cardFaceCamera, cardScale, cardAutoPlay, galleryNavigation)}
              className='w-full justify-center rounded-sm bg-sky-500 px-3 py-1
              text-base  text-white bg-sky-500 hover:bg-sky-400 mt-4'
            >Update</button>
          </div>
        </div>
      )
    }


    const FormImage = (item, mediaItem, activeIndex) => {

      const [sceneData, setSceneData] = useContext(SceneContext)
      const [sessionData, setSessionData] = useContext(SessionContext)

      const [personaName, setPersonaName] = useState(item.mediaItem.personaName)
      const [personaRole, setPersonaRole] = useState(item.mediaItem.personaRole)
      const [personaPosX, setPersonaPosX] = useState(item.item.personaPosX)
      const [personaDetail, setPersonaDetail] = useState(item.mediaItem.personaDetail)

      const [personaPositionXY, setPersonaPositionXY] = useState({ x: item.item.pos.x, y: item.item.pos.y })
      const [personaSize, setPersonaSize] = useState({ width: item.item.size.width, height: item.item.size.height })
      const [personaDelay, setPersonaDelay] = useState(item.item.animationDuration)
      const [personaAnimation, setPersonaAnimation] = useState(item.item.animation)

      const [cardScale, setCardScale] = useState(item.item.scale || 1)
      const [cardFaceCamera, setCardFaceCamera] = useState(item.item.faceCamera || false)

      const faceCameraHandler = () => {
        setCardFaceCamera(!cardFaceCamera)
      }






      const mediaitem = item.mediaItem



      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

      //const [selectedAnimation, setSelectedAnimation] = useState(animationStyles[0])


      // const debouncedLog = useRef(debounce((value) => {
      //   console.log(value);
      // }, 300));

      // useEffect(() => {
      //   //debouncedLog.current(personaPositionXY);
      //   console.log(personaPositionXY)
      //   // return () => {
      //   //   debouncedLog.current.cancel();
      //   // };
      // }, [personaPositionXY]);



      const setNewPos = (newPos) => {
        // console.log('getNewPos')
        // console.log(newPos.x)
        // console.log(newPos.y)

        setPersonaPositionXY({ x: newPos.x, y: newPos.y })

        let cloneVizActive = []
        cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]
        cloneVizActive.forEach(vizItem => {

          if (vizItem.guid == item.item.guid) {
            vizItem['pos'] = { x: newPos.x, y: newPos.y }
          }
        })

        let cloneSceneDataActive = []
        cloneSceneDataActive = [...sceneData]
        cloneSceneDataActive[sessionData.activeIndex].viznodes = cloneVizActive
        setSceneData(cloneSceneDataActive)

        const activeSceneId = sceneData[sessionData.activeIndex].id

        const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
        //await 
        updateDoc(sceneRef, {
          viznodes: cloneVizActive
        })
      }

      return (
        <>


          <div className='w-100p float-left slider-parent-size mt-3'>
            {/* <div className="mr-2 mb-4">
                <div className="flex mb-4 text-sm">
                  <div className="flex-none w-20 h-4 text-white mr-2 mt-4">
                    BORDER RAD THICK COLOR...
                  </div>
                </div>
              </div>

              <div className="flex mb-4 text-sm">
                <div className="flex-none w-20 h-4 text-white mr-2">
                  Border Width
                </div>
                <div className="flex-auto">
                  <Slider
                    axis="x"
                    xmin={0}
                    xmax={20}
                    xreverse={false}
                    x={personaDelay}
                    onChange={({ x }) => setPersonaDelay(x)}
                    styles={{
                      active: {
                        backgroundColor: '#333'
                      },
                      track: {
                        backgroundColor: '#1b1e21',
                        width: 180
                      }
                    }}
                  />
                </div>
                <div className="flex-1 w-14 text-white ml-2">
                  {personaDelay}
                </div>
              </div>

              <div className="flex mb-4 text-sm">
                <div className="flex-none w-20 h-4 text-white mr-2">
                  Border Radius
                </div>
                <div className="flex-auto">
                  <Slider
                    axis="x"
                    xmin={0}
                    xmax={20}
                    xreverse={false}
                    x={personaDelay}
                    onChange={({ x }) => setPersonaDelay(x)}
                    styles={{
                      active: {
                        backgroundColor: '#333'
                      },
                      track: {
                        backgroundColor: '#1b1e21',
                        width: 180
                      }
                    }}
                  />
                </div>
                <div className="flex-1 w-14 text-white ml-2">
                  {personaDelay}
                </div>
              </div> */}

            <div className="flex  text-sm">
              <div className="flex-none w-20 h-4 mt-1   mr-2 ml-2 text-gray-300">
                Size
              </div>
              <div className="flex mt-3">
                <Slider
                  axis="x"
                  xmin={100}
                  xmax={1600}
                  xstep={10}
                  xreverse={false}
                  x={personaSize.width}
                  onChange={({ x }) => setPersonaSize(state => ({ ...state, width: x }))}
                  styles={{
                    active: {
                      backgroundColor: '#333'
                    },
                    track: {
                      backgroundColor: '#1b1e21',
                      width: 180
                    }
                  }}
                />
              </div>
              <div className="flex-1 w-56 text-white ml-2">
                {/* {personaSize.width} */}
                <input
                  className="
                  remove-arrow 
                  bg-black/50 px-1 text-center   w-full rounded-md border-0   py-2.5 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset
        ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-sm sm:leading-6v focus:outline-none"
                  type="number"
                  min="0" //max="10"
                  step="1"
                  value={personaSize.width}
                  onChange={(e) =>
                    setPersonaSize(state => ({ ...state, width: parseInt(e.target.value) }))
                    //setPersonaSize(state => ({width: e.target.value }))
                    //  setCardScale(e.target.value)
                  }
                />
              </div>
            </div>


            <div className="flex  text-sm  mt-1">
              <div className="flex-none w-20 h-4 mt-2   mr-2 ml-2 text-gray-300">
                Zoom
              </div>


              <div className='flex w-full'>
                <div className='  h-9 w-9 mt-0.5 p-0.5 text-center text-lg  cursor-pointer  bg-zinc-300/5 hover:bg-white/10 rounded-full'
                  onClick={(e) =>
                    //setCardScale(cardScale - .25)
                    cardScale - .25 > 0 ? setCardScale(parseFloat(Math.round(cardScale * 100) / 100) - .25) : 0
                  }
                >-</div>
                <div className='flex mx-1'>
                  <div className=' block w-full rounded-md border-0  px-1.5  
text-white   sm:text-sm sm:leading-6'>
                    {/* {cardScale} */}

                    <input
                      className="remove-arrow bg-black/50 px-3 w-14  text-center block rounded-md border-0  py-2.5 bg-transparent text-gray-100  ring-1 ring-inset
        ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-sm sm:leading-6v focus:outline-none"
                      type="number"
                      min="0" //max="10"
                      //step=".25"
                      pattern='^[0-9]*$'
                      value={cardScale || 0}
                      // onFocus={handleFocus}
                      // onBlur={handleBlur}
                      //        onBlur={(e) => setCardScale(e.target.value)}
                      onChange={(e) => setCardScale(Math.round(e.target.value * 100) / 100)}
                    />
                    {/* <NumericInput min={0} max={100} value={cardScale}
      mobile
      className="remove-arrow bg-black/50 px-3  text-center block w-full rounded-md border-0  py-2.5 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset
      ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-sm sm:leading-6v focus:outline-none"
      /> */}
                  </div>
                </div>
                <div className='  h-9 w-9 mt-0.5 p-0.5 text-center text-lg  cursor-pointer  bg-zinc-300/5 hover:bg-white/10 rounded-full'
                  onClick={(e) => setCardScale(parseFloat(Math.round(cardScale * 100) / 100) + .25)}
                >+</div>
              </div>
            </div>




            <div className="flex w-full h-8 mt-8   mr-2 ml-2 text-gray-300">
              <div className='flex mt-4 text-sm'>
                Animation
              </div>


              {/* <Listbox value={personaAnimation} onChange={setPersonaAnimation}>
                {({ open }) => (
                  <>
                    <div className="p-2 pl-3 flex-auto ml-2.5">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6
            hover:bg-white/10
            ">
                        <span className="block truncate">{personaAnimation.displayName || 'none'} &nbsp;</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full  overflow-auto rounded-md bg-zinc-800 py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {animationStyles.map((person) => (
                            <Listbox.Option
                              key={person.id + 'animation'}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={person}
                            >
                              {({ cardAnimation, active }) => (
                                <>
                                  <span className={classNames(personaAnimation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {person.displayName}
                                  </span>
                                  {cardAnimation ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-sky-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox> */}

              <Listbox value={personaAnimation} onChange={setPersonaAnimation}>
                {({ open }) => (
                  <>
                    <div className="relative  flex-auto ml-4 mr-4 mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6
                            hover:bg-white/10
                            ">
                        <span className="block truncate">{personaAnimation.displayName || 'none'} &nbsp;</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {animationStyles.map((person) => (
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={person}
                            >
                              {({ cardAnimation, active }) => (
                                <>
                                  <span className={classNames(personaAnimation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {person.displayName}
                                  </span>
                                  {cardAnimation ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-sky-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>




            </div>



            <div className="flex w-full      mr-2 ml-2 text-gray-300">
              <div className='flex w-1/3 mt-8 text-sm'>
                Delay
              </div>



              <div className='flex mt-6 w-full pr-2'>
                <div className='  h-9 w-9 mt-0.5 p-0.5 text-center text-lg  cursor-pointer  bg-zinc-300/5 hover:bg-white/10 rounded-full'
                  onClick={(e) =>
                    //setCardScale(cardScale - .25)
                    personaDelay > 0 ? setPersonaDelay(parseInt(personaDelay) - 1) : 0
                  }
                >-</div>
                <div className='flex mx-1'>
                  <div className=' block w-full rounded-md border-0  px-1.5  
text-white   sm:text-sm sm:leading-6'>
                    {/* {cardScale} */}
                    <input
                      className="remove-arrow bg-black/50 px-3  text-center block  w-14  rounded-md border-0  py-2.5 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset
        ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-sm sm:leading-6v focus:outline-none"

                      type="number" pattern='^[0-9]*$'

                      value={!personaAnimation.displayName ? 0 : personaDelay || 0}
                      // onFocus={handleFocus}
                      // onBlur={handleBlur}
                      onChange={(e) => setPersonaDelay(parseInt(e.target.value))}
                    />
                  </div>
                </div>
                <div className='  h-9 w-9 mt-0.5 p-0.5 text-center text-lg  cursor-pointer bg-zinc-300/5 hover:bg-white/10 rounded-full'
                  onClick={(e) => setPersonaDelay(parseInt(personaDelay) + 1)}
                >+</div>
              </div>
            </div>


          </div>





          <div className="flex w-full  float-left mt-4   mr-2 ml-2 text-gray-300">
            <div className='flex w-1/3 mt-8 text-sm'>
              Face
            </div>
            <div className='flex mt-6 -ml-1 w-full pr-2 gap-x-1 bg-zinc-300/5 p-2 mr-4 rounded-md'>
              <div
                className={`flex-auto  text-center  items-center rounded transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                       ${cardFaceCamera == false ? 'bg-sky-600 text-gray-200  ' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                onClick={(e) => {
                  setCardFaceCamera(false)
                }}>
                Forward
              </div>
              <div
                className={`flex-auto  text-center  items-center rounded  transition transition-all duration-250 px-3 py-2 text-sm   cursor-pointer
                          ${cardFaceCamera == true ? 'bg-sky-600 text-gray-200  ' : ' text-gray-300 hover:bg-sky-600/50 '}`}
                onClick={(e) => {
                  setCardFaceCamera(true)
                }}>
                Rotate to Viewer
              </div>
            </div>
          </div>


          {/* <div className='text-gray-400 h-4 float-right text-sm mt-2' style={{ height: '20px' }}>
            {personaPositionXY.x}, {personaPositionXY.y}
          </div> */}




          {/* <div className='flex mt-6 w-full pr-2'> */}
          <div className="flex w-full  float-left  mr-2 ml-2 text-gray-300 mb-2">
            {/* <div className='flex mt-2.5 w-full pr-2 gap-x-1'>
              <div className='flex w-1/4 mt-2 text-sm'>
                Position
              </div>
              <div
                className={`flex w-1/3 -ml-1`} >
                <input
                  className=" bg-black/50 px-3  text-center block   w-14   rounded-md border-0  py-2.5 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset
        ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-sm sm:leading-6v focus:outline-none"
                  type="number" pattern='^[0-9]*$'
                  value={personaPositionXY.x || 0}
                  onChange={(e) =>
                    setPersonaPositionXY({ x: e.target.value, y: personaPositionXY.y })
                  }
                />
                <img src="/util/app/b3-admin-arrow-horiz.svg " className="w-8 h-8 p-1 opacity-50" />
              </div>
              <div
                className={`flex w-1/3 ml-4`} >

                <input
                  className="remove-arrow bg-black/50 px-3  text-center block   w-14   rounded-md border-0  py-2.5 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset
        ring-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-sm sm:leading-6v focus:outline-none"
                  type="number" pattern='^[0-9]*$'
                  value={personaPositionXY.y || 0}
                  onChange={(e) =>
                    setPersonaPositionXY({ x: personaPositionXY.x, y: e.target.value })
                  }
                />
                <img src="/util/app/b3-admin-arrow-vert.svg " className="w-8 h-8 p-1 opacity-50" />
              </div>
            </div> */}
          </div>


          <div className='w-full flex float-left slider-parent mt-4 mb-4'>


            <GridSelect
              //viznodes'
              activeNode={item.item.guid}
              viznodes={
                sceneData[sessionData.activeIndex].viznodes
                //sceneDataforGrid
              }
              setCardPositionXY={setNewPos}
            //                  setCardPositionXY={setPersonaPositionXY}
            />

            {/* <Slider
              axis="xy"
              xmin={-30}
              xmax={30}
              ymin={-10}
              ymax={30}
              xreverse={true}
              yreverse={true}
              x={personaPositionXY.x}
              y={personaPositionXY.y}
              onChange={setPersonaPositionXY}
              // onChange={({ x }) => {
              //   setPersonaPositionXY
              //   //console.log('X')
              //   //console.log(x)
              // }
              // }
              styles={{
                active: {
                  backgroundColor: '#333'
                },
                track: {
                  backgroundColor: '#1b1e21'
                }
              }}
            /> */}
          </div>
          {/* <div className='text-sm text-gray-400 mb-4'>
              Position {personaPositionXY.x}, {personaPositionXY.y}
            </div> */}


          {/* <div className="mt-2.5">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center ml-1">
                  <input
                    id="faceCamera"
                    aria-describedby="faceCamera"
                    name="faceCamera"
                    type="checkbox"
                    checked={cardFaceCamera}
                    onChange={faceCameraHandler}
                    className="h-4 w-4 rounded border-gray-300 text-sky-400 focus:ring-sky-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <p id="candidates-description" className="text-gray-300">
                    Turn to face camera
                  </p>
                </div>
              </div>
            </div> */}

          <div className="mt-6 ">
            <button onClick={() => handleImageCardUpdateProps(mediaItem, item, personaSize, personaPositionXY, personaDelay, personaAnimation, cardFaceCamera, cardScale)}
              className='w-full justify-center rounded-md bg-sky-500 px-3 py-1
                    text-l font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >Update</button>

          </div>
        </>
      )
    }

    return (
      <div className='sidebar-gallery-wrapper'>



        {loadingItems.map((item, index) => (
          <div
            className='media-record animate__animated animate__fadeIn progress progress-striped active h12 mb-2'
            key={index + 'upload' + item.id}
          >
            <div role="progressbar progress-striped" className="progress-bar w-100p h-100p"></div>
          </div>
        ))}

        {sceneData[sessionData.activeIndex].viznodes.map((item, index) => (
          <div key={item.guid + 'gallery-wrap'}>
            {(item.id == 1 & uploadMode == 1) & (personaItem.guid == item.guid) ?
              item.media.map((mediaItem, index) => (
                <div className='person animate__xxx animate__fadeIxxxn'
                  key={mediaItem.guid + 'inner'}
                >
                  <img src={mediaItem.image} className='media-thumb person mb-6 rounded' />
                  <FormPersona
                    key={mediaItem.guid + 'form'}
                    mediaItem={mediaItem}
                    item={item}
                    activeIndex={sessionData.activeIndex}
                  //sessionData={sessionData}
                  //viznodes={sceneData[sessionData.activeIndex].viznodes || []}
                  //sceneData={sceneData} 
                  />
                </div>
              ))
              : (null)
            }

            {(item.id == 4 & uploadMode == 1) & (personaItem.guid == item.guid) ?
              item.media.map((mediaItem, index) => (
                <div key={'uploadlist' + mediaItem.id}>
                  <div
                    className='person animate__animated animate__fadxxeIn'
                    key={mediaItem.guid}
                  >
                    <img src={mediaItem.image} className='media-thumb person mb-6' />
                    <FormImage
                      key={mediaItem.guid + 'form'}
                      mediaItem={mediaItem}
                      item={item}
                      sceneData={sceneData} />
                  </div>
                </div>
              ))
              : (null)
            }



            {(item.id == 2 & uploadMode == 2) & (personaItem.guid == item.guid) ?
              //item.media.map((mediaItem, index) => (

              <div className='animate__animated animate__fadeIn'>
                {/* <p className='text-white'>{mediaItem.guid} <br/> {item.guid} <br/> {personaItem.guid} <br/> {item.id}</p> */}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                        {item.media.map((mediaItem, index) => {
                          return (
                            <Draggable key={mediaItem.guid} draggableId={mediaItem.guid} index={index}>
                              {(provided) => (
                                <li className="characters-thumb" ref={provided.innerRef} {...provided.draggableProps}
                                  {...provided.dragHandleProps}>

                                  {/* <div className='h100'>
                                    <div className='w-10p float-left drag-handle'>
                                      <img src="/util/app/b3-grab-2.svg" className="h18 opacity-80 mt-1 " />
                                    </div>

                                    <div className='w-50p float-left'>
                                      <img src={mediaItem.image.replace("mov", "jpg").replace("mp4", "jpg")} className='media-thumb mt-5' />
                                    </div>

                                    <div className='w-20p float-right mr-2'>
                                      <button onClick={() => handleFileDelete(mediaItem)} className='float-right'>
                                        <img src="/media/icon/noun-trash-5765342-FFFFFF.svg" className="h18 o5 mt40 mr-2" />
                                      </button>
                                    </div>
                                  </div> */}


                                  <div className='grid grid-cols-5 gap-1 rounded bg-zinc-900'>
                                    <div className='col-span-1'>
                                      <div className="flex-none w-4 mr-2">
                                        <img src="/util/app/b3-grab-2.svg" className="h18 o4 mt-10 ml-2" />
                                      </div>
                                    </div>
                                    <div className='col-span-3'>
                                      <img src={mediaItem.image.replace("mov", "jpg").replace("mp4", "jpg")} className='media-thumb' />
                                    </div>
                                    <div className='col-span-1'>
                                      <button onClick={() => handleFileDelete(mediaItem)} className=' float-right'>
                                        <img src="/media/icon/noun-trash-5765342-FFFFFF.svg" className="h18 o5 mr-6 mt40" />
                                      </button>
                                    </div>
                                  </div>




                                </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              //))
              : (null)
            }


            {(item.id == 2 & uploadMode == 2) & (personaItem.guid == item.guid) ?
              <FormDevice
                key={item.guid + 'deviceform'}
                item={item}
                sceneData={sceneData} />
              : (null)
            }


          </div>
        ))}



      </div>
    )

  }


  const UpdatePersonaImage = (personaUrl) => {

    let cloneVizActive = []
    cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizActive.forEach(item => {
      if ((item.id == 1) & (personaItem.guid == item.guid)) {
        if (item.media) {
          item.media = [
            {
              ...item.media[0],
              'image': personaUrl,
              'filename': personaUrl,
              guid: uuidv4(),
            }]
        }
      }
    })

    let activeSceneId = sceneData[sessionData.activeIndex].id
    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

    updateDoc(sceneRef, {
      viznodes: cloneVizActive
    })
  }

  const CompPersonaBrowser = (props) => {
    const [open, setOpen] = useState(false)
    const [modelCols, setModelCols] = useState(6)

    const personaList = [
      { url: '/media/persona/f-1.png' },
      { url: '/media/persona/f-2.png' },
      { url: '/media/persona/f-3.png' },
      { url: '/media/persona/f-4.png' },
      { url: '/media/persona/f-5.png' },
      { url: '/media/persona/f-6.png' },
      { url: '/media/persona/f-7.png' },
      { url: '/media/persona/f-8.png' },
      { url: '/media/persona/f-9.png' },
      { url: '/media/persona/f-10.png' },
      { url: '/media/persona/f-11.png' },
      { url: '/media/persona/f-12.png' },
      { url: '/media/persona/f-13.png' },
      { url: '/media/persona/f-14.png' },
      { url: '/media/persona/f-15.png' },
      { url: '/media/persona/f-16.png' },
      { url: '/media/persona/f-17.png' },
      { url: '/media/persona/f-18.png' },
      { url: '/media/persona/f-19.png' },
      { url: '/media/persona/f-20.png' },
      { url: '/media/persona/m-1.png' },
      { url: '/media/persona/m-2.png' },
      { url: '/media/persona/m-3.png' },
      { url: '/media/persona/m-4.png' },
      { url: '/media/persona/m-5.png' },
      { url: '/media/persona/m-6.png' },
      { url: '/media/persona/m-7.png' },
      { url: '/media/persona/m-8.png' },
      { url: '/media/persona/m-9.png' },
      { url: '/media/persona/m-10.png' },
      { url: '/media/persona/m-11.png' },
      { url: '/media/persona/m-12.png' },
      { url: '/media/persona/m-13.png' },
      { url: '/media/persona/m-14.png' },
      { url: '/media/persona/m-15.png' },
      { url: '/media/persona/m-16.png' },
      { url: '/media/persona/m-17.png' },
      { url: '/media/persona/m-18.png' },
      { url: '/media/persona/m-19.png' },
      { url: '/media/persona/m-20.png' },
      { url: '/media/persona/m-21.png' },
      { url: '/media/persona/m-22.png' },
      { url: '/media/persona/m-23.png' },
      { url: '/media/persona/m-24.png' },
      { url: '/media/persona/m-25.png' },
      // { url: '/media/persona/m-26.png' },
      { url: '/media/persona/m-27.png' },
      { url: '/media/persona/m-28.png' },
      { url: '/media/persona/m-29.png' },
    ]

    return (
      <>
        {/* //border border-gray-700  bg-white/5 hover:bg-white/10  */}
        <div className={`text-gray-300 px-2 ml-1 mt-2 pt-3 
        
        admin-component component-card 
        w-fullm mr-2
        h-16  rounded text-sm text-center`} onClick={() => { setOpen(true) }}>
          {props.linkText}
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full  items-end justify-center p-8   text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden  rounded-lg bg-zinc-800    text-left shadow-xl transition-all sm:my-8 sm:w-3/4   ">
                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                      <button
                        type="button"
                        className="rounded-md   focus:outline-none  "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div className="p-4">
                          <div className='text-xl mt-4 mb-8 text-gray-50  font-semibold'>
                            {props.headingText}
                          </div>
                          {/* <div className="flex gap-4 mb-8 mt-8">
                            <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Buildings</div>
                            <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Interiors</div>
                            <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Transportation &amp; Logistics</div>
                            <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Industrial</div>
                            <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Diagram &amp; Arrows</div>
                            <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Mini Backgrounds</div>
                          </div> */}
                          <div className=" h-[60vh] overflow-y-auto pr-4 section-scroll">
                            <div className={"grid grid-cols-" + modelCols + " gap-4 "}>
                              {personaList.map((persona, index) => (
                                <div
                                  key={index + 'persona'}
                                  className={`component-card model-select skyBackground1 h-20 overflow-hidden `}
                                  style={{ height: 'auto' }}
                                  onClick={() => {
                                    UpdatePersonaImage(persona.url)
                                    setOpen(false)
                                  }}
                                >
                                  <div className="bg-no-repeat bg-bottom h-56 -mb-4 w-56 mx-auto"
                                    style={{
                                      backgroundImage: `url(` + persona.url + `)`,
                                      backgroundSize: `contain`,
                                    }}
                                  ></div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className='text-base mt-8 mb-8  text-gray-50'>
                            Cannot find what you are looking for?  Let us know what you need and we will add it to the library
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    )
  }


  const MediaUploadDialogue = () => {
    return (
      <div className='flex'>
        <div
          style={{
            border: '1px dashed #2289a6', borderRadius: '.25rem',
            padding: '0rem', color: '#efefef', textAlign: 'center', width: '100%'
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleFileDrop}
          className='flex-1  mb-4 mt-2 hover:bg-gray-800 cursor-pointer  '
          onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*,video/*';
            input.multiple = true;
            input.addEventListener('change', (e) => {
              const uploadedFiles = Array.from(e.target.files);
              setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
              uploadedFiles.forEach(handleFileUpload);
            });
            input.click();
          }}
        >
          <div className='text-sm'>

            <div className='py-1 mt-2'>
              Select or Drop media here
            </div>

          </div>
        </div>


        {personaItem.id != 4 && personaItem.id != 2 ?
          <div className='flex-1 '>
            <CompPersonaBrowser headingText="Select Persona Image" linkText="Browse Personas" />
          </div>
          : null}


        <div className='flex-1  h-20 flex-none  mt-2'>
          <CompImageGenBrowser linkText="Generate" />
        </div>

      </div>
    )
  }


  // const MediaUploadDialogue = React.memo(({ personaItem, handleFileDrop, setFiles, handleFileUpload }) => {
  //   return (
  //     <div className='flex'>
  //       <div
  //         style={{
  //           border: '1px dashed #2289a6',
  //           borderRadius: '.25rem',
  //           padding: '0rem',
  //           color: '#efefef',
  //           textAlign: 'center',
  //           width: '100%'
  //         }}
  //         onDragOver={(e) => e.preventDefault()}
  //         onDrop={handleFileDrop}
  //         className='flex-1 mb-4 mt-2 hover:bg-gray-800 cursor-pointer'
  //         onClick={() => {
  //           const input = document.createElement('input');
  //           input.type = 'file';
  //           input.accept = 'image/*,video/*';
  //           input.multiple = true;
  //           input.addEventListener('change', (e) => {
  //             const uploadedFiles = Array.from(e.target.files);
  //             setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  //             uploadedFiles.forEach(handleFileUpload);
  //           });
  //           input.click();
  //         }}
  //       >
  //         <div className='text-sm'>
  //           <div className='py-1 mt-2'>
  //             Select or Drop media here
  //           </div>
  //         </div>
  //       </div>
  //       {personaItem.id !== 4 && personaItem.id !== 2 ? (
  //         <div className='flex-1'>
  //           <CompPersonaBrowser headingText="Select Persona Image" linkText="Browse Personas" />
  //         </div>
  //       ) : null}
  //       <div className='flex-1 h-20 flex-none mt-2'>
  //         <CompImageGenBrowser linkText="Generate" />
  //       </div>
  //     </div>
  //   );
  // });

  //console.log('sceneData', sceneData)



  //console.log(personaItem)

  return (
    <div className='  pr-1'>
      {/* <div
        style={{
          border: '1px dashed #61dafc', borderRadius: '.25rem',
          padding: '.5rem', color: '#efefef', textAlign: 'center', background: 'rgb(22 24 29)', width: '100%'
        }}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleFileDrop}
        className='mb-4'
      >
        <div className='mt-2 mb-2'>
          Drop media here
        </div>
      </div> */}

      <MediaUploadDialogue />

      
      

      {/* <GridSelect
                  //viznodes
                  activeNode={personaItem.guid}
                  viznodes={ sceneData[sessionData.activeIndex].viznodes}
                  //viznodes={gridVizNodes || []}
                  //setCardPositionXY={setPersonaPositionXY}
                /> */}

      {/* {renderMediaList()} */}
      <RenderMediaList key={sceneData.guid}></RenderMediaList>

    </div>
  );
};

export default MediaUploader;