import { OrbitControls } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import gsap from "gsap";
import { useEffect, useRef, useCallback, useState } from "react";
import { Vector3 } from "three";

const CameraControls = ({ position, target, moveDuration = 5, cameraOrbitOn, onCameraReady }) => {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const ref = useRef();
  const [lastPosition, setLastPosition] = useState(new Vector3());
  const [lastTarget, setLastTarget] = useState(new Vector3());

  const cameraAnimate = useCallback(() => {
    if (ref.current) {
      gsap.timeline().to(camera.position, {
        duration: moveDuration,
        repeat: 0,
        x: position.x,
        y: position.y,
        z: position.z,
        ease: "power3.inOut",
      });

      gsap.timeline().to(
        ref.current.target,
        {
          duration: moveDuration,
          repeat: 0,
          x: target.x,
          y: target.y,
          z: target.z,
          ease: "power3.inOut",
        },
      );
    }
  }, [camera, position, target, moveDuration]);

  useEffect(() => {
    cameraAnimate();
    onCameraReady(camera, ref);
    // console.log('Camera ref ready');
    // console.log(ref);
  }, [camera, cameraAnimate, onCameraReady]);

  useFrame(() => {
    if (ref.current) {
      const currentPosition = camera.position.clone();
      const currentTarget = ref.current.target.clone();

      if (!currentPosition.equals(lastPosition) || !currentTarget.equals(lastTarget)) {
        onCameraReady(camera, ref);
        setLastPosition(currentPosition);
        setLastTarget(currentTarget);
      }
    }
  });

  return (
    <OrbitControls
      ref={ref}
      args={[camera, domElement]}
      panSpeed={1}
      enablePan={cameraOrbitOn === 1}
      enableRotate={cameraOrbitOn === 1}
      enableZoom={cameraOrbitOn === 1}
      maxDistance={1000}
      minDistance={100}
      enableDamping={true}
      dampingFactor={0.4}
      maxPolarAngle={Math.PI / 1.9}
      minAzimuthAngle={-Math.PI / 4.8}
      maxAzimuthAngle={Math.PI / 4.8}
    />
  );
};

export { CameraControls };


// //Camera.tsx
// import { OrbitControls } from "@react-three/drei";
// import { useThree, useFrame } from "@react-three/fiber";
// import gsap from "gsap";
// import { useEffect, useRef, useCallback  } from "react";
// import { Vector3 } from "three";

// // interface Point {
// //   x: number;
// //   y: number;
// //   z: number;
// // }


// // DEMO TODO - revert the moveDuration, was 4.5
// const CameraControls = ({ position, target, moveDuration = 5, cameraOrbitOn, onCameraReady }) =>  {
//   const {
//     camera,
//     gl: { domElement },
//   } = useThree();

//   const ref = useRef();

//   const cameraAnimate = useCallback(() => {
//     if (ref.current) {
//       gsap.timeline().to(camera.position, {
//         duration: moveDuration,
//         repeat: 0,
//         x: position.x,
//         y: position.y,
//         z: position.z,
//         ease: "power3.inOut",
//       });

//       gsap.timeline().to(
//         ref.current.target,
//         {
//           duration: moveDuration,
//           repeat: 0,
//           x: target.x,
//           y: target.y,
//           z: target.z,
//           ease: "power3.inOut",
//         },
//       );
//     }
//   }, [camera, position, target, moveDuration]);

//   useEffect(() => {
//     //camera.up = new Vector3(0, 1, 0);
//     cameraAnimate();
//     onCameraReady(camera, ref);
//     //onCameraReady(ref);
//     console.log('Camera ref ready');
//     console.log(ref);
//   }, [camera, cameraAnimate, onCameraReady]);

//   return (
//     <OrbitControls
//       ref={ref}
//       args={[camera, domElement]}
//       panSpeed={1}
//       enablePan={cameraOrbitOn === 1}
//       enableRotate={cameraOrbitOn === 1}
//       enableZoom={cameraOrbitOn === 1}
//       maxDistance={1000}
//       minDistance={100}
//       enableDamping={true}
//       dampingFactor={0.4}
//       maxPolarAngle={Math.PI / 1.9}
//       minAzimuthAngle={-Math.PI / 4.8}
//       maxAzimuthAngle={Math.PI / 4.8}
//     />
//   );
// };

// export { CameraControls };
