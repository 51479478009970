import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import Stories from '../pages/Stories'
import { UserAuth } from '../context/AuthContext'
import { Link, Outlet } from 'react-router-dom'
import '/Style.css'

import { db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  orderBy,
  doc,
  where,
  addDoc,
  deleteDoc, serverTimestamp
} from 'firebase/firestore'

//import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import CompChat from './CompChat'

import Confetti from 'react-confetti'


let navigation = [
  {
    name: 'Home',
    href: '/app/home',
    icon: HomeIcon,
    svgicon: 'noun-home-2472584-FFFFFF',
    current: false
  },
  {
    name: 'Stories',
    href: '/app/stories',
    icon: UsersIcon,
    svgicon: 'noun-book-534697-FFFFFF',
    current: false
  },
  {
    name: 'Share',
    href: '/app/share',
    icon: CalendarIcon,
    svgicon: 'b3-admin-share',
    current: false
  },
  {
    name: 'Insights',
    href: '/app/insights',
    icon: CalendarIcon,
    svgicon: 'b3-admin-analytics-2',
    current: false
  },
  // {
  //   name: 'Messages',
  //   href: '/app/messages',
  //   icon: CalendarIcon,
  //   svgicon: 'noun-chat-1459497-FFFFFF',
  //   current: false
  // },
  {
    name: 'Creator Guide',
    href: '/app/training',
    icon: CalendarIcon,
    svgicon: 'noun-training-3217375-FFFFFF',
    current: false
  },
  // { name: 'Media & Content', href: '/ultra', icon: CalendarIcon, svgicon: 'noun-photos-3136007-FFFFFF', current: false },
  // { name: 'Ultrares', href: '/ultra', icon: CalendarIcon, svgicon: 'noun-film-4414528-FFFFFF', current: false },
  // { name: 'Integration', href: '/integration', icon: CalendarIcon, svgicon: 'noun-cloud-sharing-1096622-FFFFFF', current: false },
]

const teams = [
  { id: 1, name: 'My Stories', href: 'folder', initial: 'H', current: false, svgicon: 'noun-folder-5871978-FFFFFF', },

  { id: 3, name: 'Shared with Me', href: 'folder', initial: 'T', current: false, svgicon: 'noun-folder-5871978-FFFFFF', },
  { id: 2, name: 'Public', href: 'folder', initial: 'T', current: false, svgicon: 'noun-folder-5871978-FFFFFF', },
  { id: 4, name: 'Templates', href: 'folder', initial: 'T', current: false, svgicon: 'b3-templates', },
]

const teamsMarketing = [
  { id: 18, name: 'First Call - Service', href: 'folder', initial: 'W', current: false, svgicon: 'noun-folder-5871978-0CA5E9_open', },
  { id: 19, name: 'First Call - Marketing', href: 'folder', initial: 'W', current: false, svgicon: 'noun-folder-5871978-0CA5E9_open', },
  { id: 20, name: 'Case Studies', href: 'folder', initial: 'W', current: false, svgicon: 'noun-folder-5871978-0CA5E9_open', },
  { id: 21, name: 'Industry Trends', href: 'folder', initial: 'W', current: false, svgicon: 'noun-folder-5871978-0CA5E9_open', },
]

const teamsSales = [
  { id: 27, name: 'BDR Training', href: 'folder', initial: 'W', current: false, svgicon: 'noun-folder-5871978-0CA5E9_open', },
  { id: 28, name: 'BASHO Templates', href: 'folder', initial: 'W', current: false, svgicon: 'noun-folder-5871978-0CA5E9_open', },
  { id: 29, name: 'ABM Templates', href: 'folder', initial: 'W', current: false, svgicon: 'noun-folder-5871978-0CA5E9_open', },
  { id: 30, name: 'Service Templates', href: 'folder', initial: 'W', current: false, svgicon: 'noun-folder-5871978-0CA5E9_open', },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminNav() {

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { user, logOut } = UserAuth() //user?.email == 'john.brunswick@gmail.com' ?
  const [selectedNavigationIndex, setSelectedNavigationIndex] = useState(0)
  const [confettiShown, setConfettiShown] = useState(1)

  const navigate = useNavigate();
  //const { googleSignIn, user } = UserAuth();

  const handleSignOut = async () => {
    try {
      await logOut().then(() => navigate('/signin'));
      //user = null
    } catch (error) {
      console.log(error)
    }
  }
  // useEffect (() => {
  //   setTimeout(() => {
  //     setConfettiShown(1)
  //   }, 5000)
  // }, [])

  useEffect(() => {
    if (location.pathname.includes('/app/home')) {
      setSelectedNavigationIndex(0)
      document.title = 'Home'
    }
    if (location.pathname.includes('/app/stories')) {
      setSelectedNavigationIndex(1)
      document.title = 'Stories'
    }
    if (location.pathname.includes('/app/share')) {
      setSelectedNavigationIndex(2)
      document.title = 'Share'
    }
    if (location.pathname.includes('/app/insights')) {
      setSelectedNavigationIndex(3)
      document.title = 'Insights'
    }
    if (location.pathname.includes('/app/messages')) {
      setSelectedNavigationIndex(3)
      document.title = 'Messages'
    }
    if (location.pathname.includes('/app/training')) {
      setSelectedNavigationIndex(4)
      document.title = 'Training'
    }
    if (location.pathname.includes('/app/admin')) {
      setSelectedNavigationIndex(5)
      document.title = 'Admin'
    }
    if (location.pathname.includes('/app/account')) {
      setSelectedNavigationIndex(6)
      document.title = 'Account'
    }
  }, [selectedNavigationIndex])

  const NavagationSelection = (index) => {
    //return function (index) {
    //console.log(index);
    setSelectedNavigationIndex(index);
    //}
    // //return function () {
    //   navigation.forEach(element => {
    //     element.current = false
    //   });
    //   navigation[index].current = true
    // //}
  }


  // TODO - query the User collection to get the license status...
  // uid field maps between login and the user in collection




  return (
    <>
      <div className=''>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-zinc-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-hidden bg-zinc-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto mt-4"
                        src="/media/icon/Bevit3-Logo-Rainbow.png"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item, index) => (
                              <div key={index}>
                                {item.name == "share" && user?.email == 'john.brunswick@gmail.com' ?
                                  <li key={item.name}>
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        item.current
                                          ? 'bg-gray-800 text-white'
                                          : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                      )}
                                    >
                                      {/* <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" /> */}
                                      <img src={'/media/icon/' + item.svgicon + '.svg'} className="h-6 w-6 shrink-0" aria-hidden="true" />
                                      {item.name}
                                    </a>
                                  </li>
                                  : null}
                              </div>
                            ))}
                          </ul>
                        </li>

                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-8 overflow-y-hidden bg-zinc-900 px-8">
            <div className="flex h-16 shrink-0 items-center pt-12">
              <img
                className="h-10 w-auto pt-3"
                src="/media/icon/Bevit3-Logo-Rainbow.png"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col pt-6">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-2">
                    {navigation.map((item, index) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          onClick={() => NavagationSelection(index)}
                          className={classNames(
                            index == selectedNavigationIndex
                              ? 'bg-gray-600 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-700',
                            'group flex gap-x-3 rounded-md p-2 leading-6 font-regular'
                          )}
                        >
                          <img src={'/media/icon/' + item.svgicon + '.svg'}
                            //className="h-5 w-5 shrink-0 mr+-1 o5" 
                            className={classNames(
                              index == selectedNavigationIndex
                                ? 'opacity-100'
                                : 'o5',
                              'h-5 w-5 shrink-0 mr+-1 o5'
                            )}
                            aria-hidden="true" />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                    <li>
                      {user?.email == 'john.brunswick@gmail.com' ?
                        <Link
                          to={'/app/admin'}
                          onClick={() => NavagationSelection(5)}
                          className={classNames(
                            5 == selectedNavigationIndex
                              ? 'bg-gray-600 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-700',
                            'group flex gap-x-3 rounded-md p-2 leading-6 font-regular'
                          )}
                        >
                          <img src={'/util/app/b3-admin.svg'}
                            //className="h-5 w-5 shrink-0 mr+-1 o5" 
                            className={classNames(
                              5 == selectedNavigationIndex
                                ? 'opacity-100'
                                : 'o5',
                              'h-5 w-5 shrink-0 mr+-1 o5'
                            )}
                            aria-hidden="true" />
                          Admin
                        </Link>
                        : (null)}
                    </li>
                  </ul>
                </li>
                <li>
                  {/* <div className="text-lg font-base leading-6 pt-8 text-gray-100">Library</div> */}
                  {/* <div className=' overflow-y-auto admin-scroll overflow-x-hidden  '> */}
                  {/* <ul role="list" className="-mx-2 mt-4 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>

                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-m leading-6 font-regular'
                          )}
                        > 
                          <img src={'/media/icon/' + team.svgicon + '.svg'}
                            //className="h-5 w-5 shrink-0 mr+-1 o5" 
                            className={classNames(
                              1 == 1 //index == selectedNavigationIndex
                                ? 'opacity-100'
                                : 'o5',
                              'h-5 w-5 shrink-0 mr+-1 o5'
                            )}
                            aria-hidden="true" /> 
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul> */}
                  {/* </div> */}

                  {/* <div className="text-lg font-base leading-6 pt-6 text-gray-100">Sales</div>
                  <ul role="list" className="-mx-2 mt-4 space-y-1">
                    {teamsSales.map((team) => (
                      <li key={team.name}>

                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-m leading-6 font-regular'
                          )}
                        >
                          <img src={'/media/icon/' + team.svgicon + '.svg'}
                            className={classNames(
                              1 == 1 
                                ? 'opacity-100'
                                : 'o5',
                              'h-5 w-5 shrink-0 mr+-1 o5'
                            )}
                            aria-hidden="true" />
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul> */}

                </li>

                <li className=" mt-auto pb-8 -ml-2 -mr-2 ">
                  <Link
                    to={'/app/account'}
                    onClick={() => NavagationSelection(6)}
                    >
                    <div
                      //className=" "
                     className={classNames(
                      selectedNavigationIndex == 6
                        ? 'bg-gray-700 text-zinc-100'
                        : 'text-zinc-300 hover:text-white hover:bg-gray-700',
                      'mb-2 items-center gap-x-4 p-2 text-m font-regular leading-6  cursor-pointer rounded-md'
                    )}
                     >
                      <div className='flex'>
                        <img
                          className="h-12 w-12 rounded-lg bg-gray-800 inline"
                          src={user?.photoURL}
                          referrerPolicy="no-referrer"
                          alt=""
                        />
                        <div aria-hidden="true" className='inline ml-2 mt-1 '>
                          {user?.displayName}
                          <span className='text-xs text-zinc-300 block'>{user?.email || ''}</span>
                        </div>
                      </div>
                    </div>
                    <div className='flex p-2 pb-3 pl-0 text-zinc-500 hover:text-white hover:bg-gray-700 cursor-pointer rounded-md'>
                      <button onClick={handleSignOut} className='ml-2 mr-2 h-12 w-12 rounded-lg bg-grxxay-900'>
                        <img src="/media/icon/noun-logout-3750552-FFFFFF.svg" className="h-5 w-5 shrink-0 mr+-1 mx-auto" aria-hidden="true" />
                      </button>
                      <div className='mt-3'>Logout</div>
                    </div>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-zinc-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-regular leading-6 text-white"></div>
          <a href="#">
            <span className="sr-only">Your profile</span>
            <img
              className="h-8 w-8 rounded-lg bg-gray-800"
              src={user?.photoURL}
              referrerPolicy="no-referrer"
              alt=""
            />
          </a>
        </div>

        <main className="lg:pl-72 bg-gray-900">
          <div className="pl-14 admin-topic-bg py-10 pr-4 ">

            <div className="relative isolate"> 
              <div
                className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                aria-hidden="true"
              > 
              </div>


              <Outlet />


              <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                </div>
              </div>
            </div>

            {/* <Outlet /> */}
          </div>
        </main>

        <div className='animate__animated animate__bounceIn  animate__delay-3s'>

        </div>

        {/* <CompChat /> */}

        {confettiShown < 1 ?
          <Confetti
            recycle={false}
          // onConfettiComplete={alert('done')}
          />
          : null}

      </div>
    </>
  )
}