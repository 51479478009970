import React, { useRef, useState, useContext, Fragment, useEffect, Suspense, CSSProperties } from "react";
import { analytics, db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  orderBy,
  doc,
  where,
  addDoc,
  deleteDoc, serverTimestamp
} from 'firebase/firestore'
//import SceneCard from "./SceneCard";
import { UserAuth } from '../context/AuthContext';
import { Dialog, Transition, Listbox, Menu } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
// import { Listbox, Transition, Dialog } from '@headlessui/react'
//import { Popover } from '@headlessui/react'
import { Link, Outlet, Navigate, useNavigate } from 'react-router-dom'
// import StoryDetailEdit from "../components/StoryDetailEdit";

import { RadioGroup } from '@headlessui/react'
import BarLoader from "react-spinners/BarLoader";
import CompOpenAI from "../components/CompOpenAI";
import { CopyToClipboard } from 'react-copy-to-clipboard';

//import StoryDetailEditModal from "../components/StoryDetailEditModal";
import StoryDetailEdit from "../components/StoryDetailEdit";
import { logEvent } from "firebase/analytics";
//import { collabRemoveUser } from "../../b3-functions";


// import { Slide, ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
//import {logCustomEvent} from "./UtilAnalytics";
import { logCustomEvent } from "../components/UtilAnalytics";

const Stories = (props) => {

  const { user, logOut } = UserAuth();

  let [loading, setLoading] = useState(true)
  let [color, setColor] = useState("#0ca5e9")

  //const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false)
  const [openStoryteller, setOpenStoryteller] = useState(false)
  const [openAdvanced, setOpenAdvanced] = useState(false)
  // Advanced
  const cancelButtonRef = useRef(null)


  const [stories, setStories] = useState([])
  const [storyName, setStoryName] = useState('')
  const [storyDescription, setStoryDescription] = useState('')
  const [storiesLoaded, setStoriesLoaded] = useState(false)

  // Create todo
  const createStory = async () => {
    //console.log("IN createStory")
    //e.preventDefault(e);
    if (storyName === '') {
      alert('Please enter a valid story name');
      return;
    }

    if (storyDescription === '') {
      alert('Please enter a valid story description');
      return;
    }

    // Grab the user ID...
    let userOwnerKey = user?.uid

    const docRef = await addDoc(collection(db, 'stories'), {
      name: storyName,
      description: storyDescription,
      userPhotoURL: user?.photoURL,
      userEmail: user?.email,
      userDisplayName: user?.displayName,
      messageUserId: user?.uid,
      backgroundMatcap: 'C8C8C8_3F3F3F_787878_5C5C5C',
      backgroundModel: 'background_plain',
      backgroundSky: 'skyBackground1',
      backgroundHeight: -5,
      layoutType: 'linear',
      layoutCircleDistance: 70,
      navigation: 'nav1',
      cameraOrbitOn: 1,
      visibility: 'private',
      previewThumb: '/media/screen/B3_New_Project.png',
      createdAt: serverTimestamp(),
      lastModified: serverTimestamp(),
      analyticsViewTotal: 0,
      analyticsLastViewed: serverTimestamp(),
      imageStoryBackground: '/media/icon/B3-Image-Placeholder.png', // TODO - add default image
      imageStoryLogo: '/media/icon/B3-Image-Placeholder.png', // TODO - add default image
      uiStyle: {
        'nav-button-bg-off': '#b3b3b3',
        'nav-button-bg-on': '#222222',
        'nav-button-text-off': '#4d4d4d',
        'nav-button-text-on': '#009ce0',
        'nav-progress-meter': '#808080',
        'nav-progress-meter-bg': '#009ce0',
        'nav-region-bg': '#111111',
        'super-highlight-color': '#009ce0',
        'super-list-icon-color': '#000000',
        'super-list-text-color': '#000000',
        'super-main-bg-color': '#111111',
        'super-main-title-color': '#009ce0',
        'super-secondary-bg-color': '#000000',
        'super-subtitle-color': '#cccccc',
      },
      role: {
        //[userOwnerKey]: 'owner',
        owner: [userOwnerKey], //t8X5CAmys8W88z5rOQWEQaIWhHn2
        commenter: [userOwnerKey],
        writer: [userOwnerKey],
        reader: [userOwnerKey],
      },
      //roleOwner: [userOwnerKey],
    });

    // Reset form fields
    setStoryName('');
    setStoryDescription('');

    
    logCustomEvent('create_story', {})

    // Redirect --- docRef.id 
    // console.log('--- DOC ID ---')
    // console.log(docRef.id)

    // logEvent(analytics, 'create_story', {
    //   name: storyName,
    //   userEmail: user?.email,
    // })



    // LOG DATA


    navigate('/app/stories/' + docRef.id + '/edit/0')
    // /app/stories/zLkP0IHYyy90p6jL66qd/edit/0


  };




  const storyDataUpdate = async (storyData, storyId) => {

    let cloneStoryData = storyData
    //cloneStoryData = [...storyData]

    const sceneRef = doc(db, 'stories', storyId);
    await updateDoc(sceneRef, {
      //cloneStoryData,
      imageStoryBackground: '/media/icon/B3-Image-Placeholder.png',
      imageStoryLogo: '/media/icon/B3-Image-Placeholder.png',
      uiStyle: {
        'nav-button-bg-off': '#000000',
        'nav-button-bg-on': '#000000',
        'nav-button-text-off': '#000000',
        'nav-button-text-on': '#000000',
        'nav-progress-meter': '#000000',
        'nav-progress-meter-bg': '#000000',
        'nav-region-bg': '#000000',
        'super-highlight-color': '#000000',
        'super-list-icon-color': '#000000',
        'super-list-text-color': '#000000',
        'super-main-bg-color': '#000000',
        'super-main-title-color': '#000000',
        'super-secondary-bg-color': '#000000',
        'super-subtitle-color': '#000000',
      },
    })

    alert('Update competed')
  }


  const storyClone = async (storyData) => {

    let cloneStoryData = storyData

    storyData['parentId'] = storyData.id
    storyData['name'] = storyData.name + " (copy)"

    // toast("Success - " + storyData['name'] + " has been copied",{
    //   transition: Slide
    // })

    // toast(
    //   "Success - " + storyData['name'] + " has been copied", {
    //   icon: '👏',
    //   style: {
    //     borderRadius: '10px',
    //     background: '#18181b',
    //     color: '#fff',
    //   },
    // })

    toast.custom((t) => (
      <div
        className={`${t.visible ? 'fadeInUp' : 'fadeOut'
          } max-w-md w-full bg-zinc-900 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <img src="/media/icon/noun-clone-2075584-FFFFFF.svg" className="w-5 opacity-100 inline mr-4" />
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-gray-100">
                {storyData['name']} has been copied
              </p>
            </div>
          </div>
        </div>
        {/* <div className="flex border-l border-gray-200">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Close
          </button>
        </div> */}
      </div>
    ))




    // Remove prior document id
    delete storyData.id

    // Reset analytics
    storyData['analyticsViewTotal'] = 0

    const docRef = await addDoc(collection(db, "stories"), storyData)
    //console.log(docRef.id)

    const q = query(collection(db, 'stories', storyData['parentId'], 'scenes'), orderBy("order"))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let sceneArr = []
      querySnapshot.forEach((doc) => {
        sceneArr.push({ ...doc.data() })
        const docRefScenes = addDoc(collection(db, 'stories', docRef.id, 'scenes'), {
          ...doc.data()
        })
        sceneArr = []
      })

      unsubscribe()
      // console.log('--- STORY ID & SCENE ARR ---')
      // console.log(docRef.id)
      // console.log(sceneArr)
    })

  }

  const tutorialClone = async (storyId = 'YoK7ny1XaYoIWI3VPt40', storyName = 'Tutorial Experience') => {

    //const storyId = 'YoK7ny1XaYoIWI3VPt40'

    try {
      const unsub = onSnapshot(doc(db, 'stories', storyId), (doc) => {

        // console.log('--- STORY DATA ---')
        // console.log(doc.data())

        const q = query(collection(db, 'stories', storyId, 'scenes'), orderBy("order"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let sceneArr = []
          querySnapshot.forEach((doc) => {
            sceneArr.push({ ...doc.data(), id: doc.id })
          })

          let cloneStoryData = doc.data()
          let userOwnerKey = user?.uid

          cloneStoryData['parentId'] = storyId
          cloneStoryData['name'] = storyName + " (" + user?.displayName + ")"
          cloneStoryData['analyticsViewTotal'] = 0

          cloneStoryData['createdAt'] = serverTimestamp()
          cloneStoryData['lastModified'] = serverTimestamp()

          cloneStoryData['role'] = {
            owner: [userOwnerKey], //t8X5CAmys8W88z5rOQWEQaIWhHn2
            commenter: [userOwnerKey],
            writer: [userOwnerKey],
            reader: [userOwnerKey],
          }
          cloneStoryData['visibility'] = 'private'


          addDoc(collection(db, "stories"), cloneStoryData).then(function (docRef) {
            // console.log('--- STORY ID ---')
            // console.log(docRef.id)
            setTimeout(() => {
              // console.log('NEW STORY CREATED...!')
              // console.log(docRef.id)

              // console.log(sceneArr)

              sceneArr.forEach((sceneDoc) => {
                // console.log('adding scene')
                // console.log(sceneDoc)
                //sceneArr.push({ ...doc.data() })
                addDoc(collection(db, 'stories', docRef.id, 'scenes'),
                  sceneDoc
                ).then(function (docSceneRef) {
                  //                  console.log(docSceneRef)
                })

              })

              // // sceneArr
              // const q = query(collection(db, 'stories', cloneStoryData.id, 'scenes'), orderBy("order"))

              // const unsubscribe = onSnapshot(q, (querySnapshot) => {
              //   let sceneArr = []
              //   querySnapshot.forEach((doc) => {
              //     sceneArr.push({ ...doc.data() })
              //     const docRefScenes = addDoc(collection(db, 'stories', docRef.id, 'scenes'), {
              //       ...doc.data()
              //     })
              //     sceneArr = []
              //   })
              // })
            }, 1000)
          })





          //   console.log('--- STORY ID & SCENE ARR ---')
          //   console.log(docRef.id)
          //   console.log(sceneArr)
          // })

        },
          (error) => {
          })
        return () => {
          unsubscribe()
        }

      },
        (error) => {
          //navigate('/404')
        })

    } catch (error) {
      // console.error('###################### Error loading data... ######################');
      // console.error(error);
      // console.error('////////////////////// Error loading data... //////////////////////');
    }
    // Start by getting the story data to copy... 



  }



  useEffect(() => {
    setTimeout(() => {
      if (user?.uid) {

        const q = query(collection(db, 'stories'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let storiesArr = [];
          querySnapshot.forEach((doc) => {
            storiesArr.push({ ...doc.data(), id: doc.id });
          });
          setStories(storiesArr);
          setStoriesLoaded(true)
        });
        return () => unsubscribe();
      }
    }, 250);
  }, [user]);

  // Delete todo
  const deleteStory = async (id) => {
    const storyRef = doc(db, 'stories', id);
    await deleteDoc(storyRef)
  }

  const navigate = useNavigate()

  const override = {
    width: "50%",
    margin: "auto",
    marginTop: "20%",
  }
  const override2 = {
    width: "50%",
    margin: "auto",
  }
  const [openStoryEdit, setOpenStoryEdit] = useState(false)




  const CollabUserCrud = (props) => {
    const [userLoading, setUserLoading] = useState(false)
    const [userList, setUserList] = useState()

    const [storyId, setStoryId] = useState(props.storyId);
    const [userEmail, setUserEmail] = useState('');

    const storyName = props.storyName
    const storyDescription = props.storyDescription
    const senderName = props.senderName
    const senderPhoto = user?.photoURL

    const handleFormSubmit = async () => {
      setUserLoading(true)
      try {
        const response = await fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/updateUserRole', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ storyId, userEmail, storyName, storyDescription, senderName, senderPhoto }),
        });
        if (response.ok) {
          console.log('Role updated successfully');
          setUserLoading(false)
          //setOpen(true)
        } else {
          console.error('Failed to update role:', response.statusText);
          setUserLoading(false)
        }
      } catch (error) {
        console.error('Error updating role:', error.message);
        setUserLoading(false)
      }
    };


    // collabRemoveUser const { storyId, uid} = req.body;
    const handleUserRemove = async (storyId, uid) => {
      //setUserLoading(true)

      console.log('/// SENDING!!!')
      console.log({ storyId: storyId, uid: uid })


      try {
        const response = await fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/collabRemoveUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ storyId, uid }),
        });
        if (response.ok) {
          console.log('Role updated successfully');
          //setUserLoading(false)
          //setOpen(true)
        } else {
          console.error('Failed to update role:', response.statusText);
          //setUserLoading(false)
        }
      } catch (error) {
        console.error('Error updating role:', error.message);
        //setUserLoading(false)
      }
    };


    useEffect(() => {
      async function getCollabUsers() {
        // console.log('Sending...')
        // console.log(props.story.role.owner)

        try {
          const response = await fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/getCollabUsers', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ids: props.story.role.owner })
          })

          const userData = await response.json();
          //          console.log(userData.collabusers)
          setUserList(userData.collabusers)

          if (response.ok) {
            // console.log('Got users...')
            // console.log(response)
            // console.log(response.data)
          } else {
            //console.error('Failed to update role:', response.statusText);
            //setUserLoading(false)
          }
        } catch (error) {
          //console.error('Error updating role:', error.message);
          //setUserLoading(false)
        }
      }

      getCollabUsers()
    }, [])


    return (
      <div className="min-h-64">
        {userList ?
          <div className="w-full mt-6">
            {userList.filter(person => person.email != props.senderEmail).map((person) => (
              <div key={person.email} className="w-full flex bg-black/20 rounded mb-2 py-4 text-gray-400">
                <div className="flex px-3 text-sm">
                  <img referrerPolicy="no-referrer" src={person.photoURL} className="h-10 rounded-md" />
                </div>
                <div className="w-1/4 px-3 text-left text-sm mt-2">{person.displayName}</div>
                <div className="grow px-3 text-left text-sm mt-2">{person.email}</div>
                <div className="flex-auto w-24 pl-3    pr-4">
                  <div className="text-sky-400 hover:text-sky-300 p-2 px-3 bg-black/20 rounded-md w-fit float-right  cursor-pointer "
                    onClick={() => handleUserRemove(props.storyId, person.uid)}
                  >
                    remove access
                  </div>
                </div>
              </div>
            ))}
          </div>
          :
          <div className="w-full py-4">
            <BarLoader
              color={color}
              //loading={userLoading}
              cssOverride={override2}
              size={450}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }
        <div className="flex mt-8  pb-4">

          {userLoading ?
            <div className=" w-full">
              <div className="text-center text-gray-100 text-xl bounce mb-2">
                <img src="/util/app/b3-mail.svg" className="h-20 mx-auto animate__animated animate__fadeIn" />
              </div>
              <div className="text-center text-gray-300 mb-8">
                Sending Invite
              </div>
              <BarLoader
                color={color}
                loading={userLoading}
                cssOverride={override2}
                size={450}
                aria-label="Loading Spinner"
                data-testid="loader"
              />

            </div>
            :
            <div className="w-full">
              <div className="flex w-full mt-12">
                <div className="text-lg text-gray-100 ml-4">Invite</div>
              </div>
              <div className="flex w-full mt-4">
                <input
                  type="text"
                  className="flex-auto rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                  placeholder="Email Address"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
                <div

                  className="flex-none justify-center rounded bg-sky-500 px-6 ml-4  
                     text-base font-base text-white shadow-sm hover:bg-sky-400 focus-visible:outline h-12 pt-3
                      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 cursor-pointer "
                  // onClick={() => {
                  //   handleFormSubmit().then(() => setOpen(true))}
                  // }

                  onClick={() => handleFormSubmit()}

                // onClick={(ev) => {
                //   ev.stopPropagation();
                //   //handleFormSubmit();
                //   //popSample()
                // }}

                >
                  Add
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }




  const UserPermissions = (props) => {

    const [open, setOpen] = useState(false)

    useEffect(() => {
      if (props.story) {
        setOpen(true)
      }
      else {
        setOpen(false)
      }
    }, [props.story])

    const closePerms = () => {
      // Clear the current story
      setStoryActivePerm(null)
      setOpen(false)
    }

    return (
      <>
        {/* <span hover-tooltip="Collaboration" tooltip-position="bottom"
            onClick={() => { setOpen(true) }}
          >
            <img src="/util/app/b3-users.svg" className="w-5 h-4 mt-3 opacity-60 hover:opacity-100" />
          </span> */}

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closePerms}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 -mt-20 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-900 px-4 pt-2 text-left
               shadow-xl transition-all sm:my-2 sm:w-1/2 sm:w-none m-40 sm:p-12">
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-3xl leading-6 text-gray-100">
                        Collaboration Settings
                      </Dialog.Title>
                    </div>
                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                      <button
                        type="button"
                        className="rounded-md   focus:outline-none  "
                        onClick={() =>
                          //setOpen(false)
                          closePerms()
                        }
                      >
                        <span className="sr-only">Close</span>
                        <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                      </button>
                    </div>
                    {props.story ?
                      <div className="mx-auto max-w-7xl">
                        <div className="py-4">
                          <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="flex text-gray-300 w-full mb-12">
                                  <div className="flex mr-6">
                                    <img
                                      className="rounded-md h-18"
                                      referrerPolicy="no-referrer"
                                      src={props.story.userPhotoURL} />
                                  </div>
                                  <div className="flex-auto">
                                    <div className="text-lg text-gray-200">Story Owner</div>
                                    <div className="text-xl">{props.story.userDisplayName}</div>
                                    <div className="text-sm mt-2">{props.story.userEmail}</div>
                                  </div>
                                </div>

                                <div className="min-w-full   text-gray-200">
                                  The following users will have access to edit this story
                                </div>

                                <CollabUserCrud
                                  storyName={props.storyName}
                                  storyDescription={props.storyDescription}
                                  senderName={props.senderName}
                                  senderEmail={props.story.userEmail}
                                  senderPhoto={user?.photoURL}
                                  story={props.story}
                                  storyId={props.storyId}
                                />

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      : null}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root >
      </>
    )
    //}
  }



  const [storyActiveEdit, setStoryActiveEdit] = useState()
  const [storyActivePerm, setStoryActivePerm] = useState()


  return (
    <div className="h-screen flex flex-col">

      <div className="md:flex md:items-center md:justify-between ">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-2xl mb-4 sm:tracking-tight">
            Stories
          </h2>
        </div>
      </div>

      <Transition.Root show={openAdvanced} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenAdvanced}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto"
          >
            <div className="flex min-h-full items-end justify-center text-center sm:items-center px-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-zinc-900 px-4 pb-2 pt-2 text-left
               shadow-xl transition-all sm:my-2 w-1/3 m-40 sm:p-10">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-xl leading-6 text-gray-100">
                        Create New Story
                      </Dialog.Title>
                      <div className="">
                        {/* <form onSubmit={createStory} className=''> */}
                        <div className="mt-12  pb-4 text-gray-300">
                          Available with enterprise or team / project functionality
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-20 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3 pt-4">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center 
                          rounded bg-gray-500 px-3 py-2 bg-zinc-800
                          text-base font-base text-gray-100 shadow-sm 
                          hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpenAdvanced(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>






      <Transition.Root show={openStoryteller} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenStoryteller}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto"
          >
            <div className="flex min-h-full items-end justify-center text-center sm:items-center px-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >

                <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-zinc-900 px-4 pb-2 pt-2 text-left
               shadow-xl transition-all sm:my-2 sm:w-full sm:w-none m-10 sm:p-10">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-xl leading-6 text-gray-100">
                        Create New Story
                      </Dialog.Title>
                      <div className="">
                        {/* <form onSubmit={createStory} className=''> */}
                        <div className="mt-2  pb-4">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                focus-within:ring-inset focus-within:ring-sky-400 mt-12 focus:outline-none">
                            <input
                              type="text"
                              className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                              placeholder="Story Name"
                              value={storyName}
                              onChange={(e) => setStoryName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-span-full">
                          <div className="mt-2">
                            <textarea
                              placeholder="Description"
                              rows={4}
                              className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                  ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                              value={storyDescription}
                              onChange={(e) => setStoryDescription(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-span-full">
                          <div className="mt-8">
                            {/* <div className="text-xl leading-6 text-gray-100 mb-4 mt-8 inline-flex ">
                              AI Story Coach / Template / Blank (figure out UX)
                            </div> */}
                            <CompOpenAI></CompOpenAI>
                            <div className="mt-4 grid grid-cols-3 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="mt-20 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 pt-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded bg-sky-500 px-3 py-2
                     text-base font-base text-white shadow-sm hover:bg-sky-400 focus-visible:outline
                      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                      onClick={() => {
                        //createStory();
                        //setOpen(false);
                        setOpenStoryteller(false);
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center 
                          rounded bg-gray-500 px-3 py-2 bg-zinc-800
                          text-base font-base text-gray-100 shadow-sm 
                          hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpenStoryteller(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>



      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto"
          >
            <div className="flex min-h-full items-end justify-center text-center sm:items-center px-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >

                <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-zinc-900 px-4 pb-2 pt-2 text-left
               shadow-xl transition-all sm:my-2 sm:w-full sm:w-none m-40 sm:p-10">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-xl leading-6 text-gray-100">
                        Create New Story
                      </Dialog.Title>
                      <div className="">
                        {/* <form onSubmit={createStory} className=''> */}
                        <div className="mt-2  pb-4">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                focus-within:ring-inset focus-within:ring-sky-400 mt-12 focus:outline-none">
                            <input
                              type="text"
                              className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                              placeholder="Story Name"
                              value={storyName}
                              onChange={(e) => setStoryName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-span-full">
                          <div className="mt-2">
                            <textarea
                              placeholder="Description"
                              rows={4}
                              className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                  ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                              value={storyDescription}
                              onChange={(e) => setStoryDescription(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* <div className="col-span-full">
                          <div className="mt-8">
                            <div className="text-xl leading-6 text-gray-100 mb-4 mt-8 inline-flex ">
                              AI Story Coach / Template / Blank (figure out UX)
                            </div>
                            <CompOpenAI></CompOpenAI>
                            <div className="mt-4 grid grid-cols-3 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                  <div className="mt-20 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 pt-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded bg-sky-500 px-3 py-2
                     text-base font-base text-white shadow-sm hover:bg-sky-400 focus-visible:outline
                      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                      onClick={() => {
                        createStory();
                        setOpen(false);
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center 
                          rounded bg-gray-500 px-3 py-2 bg-zinc-800
                          text-base font-base text-gray-100 shadow-sm 
                          hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>


      {/* {stories.length < 1 ? null : ( */}
      {/* <p className='pb-8 text-gray-400'>{`Viewing ${stories.length} stories`}</p> */}
      {/* )} */}

      <div className="overflow-y-auto admin-scroll mb-12">
        <div className="mx-auto py-4 pr-14">


          <div className="w-full">
            <BarLoader
              color={color}
              loading={!storiesLoaded}
              cssOverride={override}
              size={450}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>


          <div className={"grid grid-cols-1 gap-y-4 "
            //sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 xl:grid-cols-2 2xl:grid-cols-3 xl:gap-x-8 xl:gap-y-12
          }
          >

            {storiesLoaded ?
              <>
                <div className=" min-h-24">

                  
                  {/* <div className="grid sm:grid-cols-3 grid-cols-2 bg-zinc-900 p-8 rounded-md gap-8 "> */}
                    <div className="pointer-events-auto flex  gap-8  ">
                      <button onClick={() => setOpen(true)}
                        type="button"
                        className=" border border-sky-500   hover:bg-gray-900/50  w-1/3 rounded items-center justify-center font-medium text-sky-500  
                 hover:border-sky-500 hover:border
                    duration-300 border border-gray-800 shadow  
                ">
                        <div className="flex  ">
                           
                          <div className="mx-auto py-8">
                            <img src="/media/icon/new.svg" className="h-18 w-8 margin-auto" />
                            <div className="mt-4 md:text-sm  text-sm">New Story</div>
                          </div>
                           
                        </div>
                      </button>
 
                      <button onClick={() => tutorialClone()}
                        type="button"
                        className=" border border-sky-500   hover:bg-gray-900/50   w-1/3  rounded items-center justify-center font-medium text-sky-500   
                 hover:border-sky-500 hover:border
                   duration-300 border border-gray-800 shadow  
                ">
                        <div className="flex ">
                          
                          <div className="mx-auto py-8">
                            <img src="/util/app/b3-tutorial.svg" className="h-20 w-10 margin-auto -mt-6" />
                            <div className="-mt-2 md:text-sm text-sm">Create Tutorial Story</div>
                          </div>
                          
                        </div>
                      </button>
 
                      <button onClick={() => 
                        tutorialClone('LlbGIJs8YOa6aOeJqGF3', '4 Scene Template')
                      }
                        type="button"
                        className=" border border-sky-500   hover:bg-gray-900/50   w-1/3   rounded items-center justify-center font-medium text-sky-500  
                 hover:border-sky-500 hover:border
                  duration-300 border border-gray-800 shadow
                ">
                        <div className="flex ">
                           
                          <div className="mx-auto py-8">
                            <img src="/util/app/b3-new-story-template.svg" className="h-18 w-8 margin-auto" />
                            <div className="mt-4 md:text-sm text-sm">Create 4 Scene Starter Template</div>
                          </div>
                           
                        </div>
                      </button>
                    </div>



                  </div>
                {/* </div> */}

                    {/* <div className="pointer-events-auto flex flex-col  h-fit">
                      <button 
                      onClick={() => {setOpenStoryteller(true)}}
                        type="button"
                        className=" border border-sky-500   hover:bg-gray-900 w-full flex  aspect-video rounded items-center justify-center font-medium text-sky-500  w-full aspect-video rounded
                 hover:border-sky-500 hover:border
                aspect-h-4 aspect-w-2 rounded bg-story-preview duration-300 border border-gray-800 shadow
                ">
                        <div className="flex items-stretch">
                          <div className="py-8">  </div>
                          <div className="">
                            <img src="/util/app/b3-genai.svg" className="h-20 w-10 margin-auto -mt-6" />
                            <div className="-mt-2 md:text-sm  text-sm">Create with Storyteller</div>
                          </div>
                          <div className="py-8"></div>
                        </div>
                      </button>
                    </div> */}

                    {/* <div className="pointer-events-auto flex flex-col col-span-2 text-gray-300  h-fit">
                      Some details here
                    </div> */}

                <div className="mt-8 mb-4">
                  <div className="md:flex md:items-center md:justify-between ">
                    <div className="min-w-0 flex-1">
                      <h2 className="text-xl font-normal leading-7 text-gray-200 sm:truncate sm:text-2xl sm:tracking-tight">
                        Recent
                      </h2>
                    </div>
                  </div>

                  {/* <div className="bg-zinc-900 rounded-md p-4 text-gray-300"
                    onClick={() => {setOpenStoryteller(true)}}
                  >
                    open AI test
                  </div> */}
                </div>

                <div className={"grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 xl:grid-cols-2 2xl:grid-cols-3 xl:gap-x-8 xl:gap-y-12"
                  //sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 xl:grid-cols-2 2xl:grid-cols-3 xl:gap-x-8 xl:gap-y-12
                }>


                  <StoryDetailEdit
                    story={storyActiveEdit}
                    linkOnly={0}
                    setStoryActiveEdit={setStoryActiveEdit}
                  />

                  <UserPermissions
                    storyId={storyActivePerm ? storyActivePerm.id : 0}
                    storyName={storyActivePerm ? storyActivePerm.name : ''}
                    storyDescription={storyActivePerm ? storyActivePerm.description : ''}
                    senderName={user.displayName}
                    story={storyActivePerm}
                  />

                  {stories.map((story, index) => (
                    <div key={story.id}
                      className={`relative pointer-events-auto rounded-md    xxoverflow-hidden
                      animate__animated animate__fadeIn
                      `}

                    >
                      <Link
                        to={`/app/stories/${story.id}/edit/0`}
                        key={story.id}
                        className="  rounded-tr rounded-tl">

                        <div className={`overflow-hidden h-80 ${story.backgroundSky}`}>
                          <div className={`bg-story-thumbnail   rounded-tr rounded-tl duration-300 relative h-80 overflow-hidden `}
                            style={{
                              backgroundImage: 'url(' + story.previewThumb.replace('/upload', '/upload/ar_16:9,c_fit,w_900/') + ')',
                              backgroundSize: 'cover',
                              // backgroundPosition: 'center',
                            }}
                          >

                          </div>
                          {/* <img src={story.previewThumb.replace('/upload', '/upload/ar_16:9,c_fit,w_900/')} className="w-full aspect-auto rounded-tr rounded-tl" /> */}
                          <div className="left-4 bottom-[17vh] absolute px-2 py-1 bg-zinc-800 rounded-md">
                            {story.visibility == 'public' ?
                              <div className="text-amber-500 text-sm">
                                public
                              </div>
                              :
                              <div className="text-gray-300 text-sm">
                                private
                              </div>
                            }
                          </div>
                        </div>
                      </Link>

                      <div className="rounded-b-md ovexrflow-hidden  ">
                        <div className="flex p-5 bg-black/10  h-48">
                          <div className="flex-none w-12 mt-2">
                            <img referrerPolicy="no-referrer" src={story.userPhotoURL} className="w-8 mt-1 rounded" />
                          </div>
                          <div className="flex-auto ml-1 pr-2 mt-2">
                            <h3 className="text-lg font-normal text-gray-100 ">
                              <Link to={`/app/stories/${story.id}/edit/0`}>
                                {story.name}
                              </Link>
                            </h3>
                            <p className="text-sm text-gray-400 pb-4 pt-1 ">
                              {
                                story.lastModified ?
                                  new Date(story.lastModified.seconds * 1000).toLocaleDateString("en-US") : ''
                              }
                            </p>
                            <p className="text-sm text-gray-400 pt-1 h-24  ">{story.description}</p>
                          </div>
                          <div className="flex-none w-10">


                            <Menu as="div" className="relative inline-block text-left  ">
                              <Menu.Button
                                className="button rounded hover:bg-white/5 w-10 h-10 p-1"
                              ><img src="/util/app/b3-admin-menu-more.svg" className="w-full" />
                              </Menu.Button>

                              <Menu.Items
                                unmount={false}
                                className="absolute right-0
                              animate__animated animate__fadeIn 
                               w-64    rounded-md bg-zinc-900 text-gray-300 z-10
                              shadow shadow-black/50 p-3
                              "
                                style={{ '--animate-duration': '.25s' }}
                              >
                                <Menu.Item onClick={() => {
                                  setStoryActiveEdit(story)
                                  setStoryActivePerm(null)
                                }}>
                                  <span className='text-gray-400 hover:text-white cursor-pointer hover:bg-gray-800 block rounded-md p-3 text-sm leading-6 font-semibold'
                                  >
                                    <img src="/media/icon/noun-edit-1918366-FFFFFF.svg" className="w-5 opacity-80 inline mr-4" />
                                    Edit Details &amp; Visibility
                                  </span>

                                </Menu.Item>
                                <Menu.Item onClick={() => storyClone(story)}>
                                  <span className={'text-gray-400 hover:text-white cursor-pointer hover:bg-gray-800 block rounded-md p-3 text-sm leading-6 font-semibold'}>
                                    <img src="/media/icon/noun-clone-2075584-FFFFFF.svg" className="w-5 opacity-80 inline mr-4" />
                                    Make a Copy
                                  </span>
                                </Menu.Item>
                                <Menu.Item>
                                  <span className={'text-gray-400 hover:text-white cursor-pointer hover:bg-gray-800 block rounded-md p-3 text-sm leading-6 font-semibold'}
                                    onClick={() => {
                                      setStoryActivePerm(story)
                                      setStoryActiveEdit(null)
                                    }}
                                  >
                                    <img src="/util/app/b3-users.svg" className="w-5 opacity-80 inline mr-4" />
                                    Collaborators
                                  </span>
                                </Menu.Item>
                                {/* <Menu.Item>
                                  <span className={'text-gray-400 hover:text-white cursor-pointer hover:bg-gray-800 block rounded-md p-3 text-sm leading-6 font-semibold'}>
                                    <img src="/media/icon/noun-folder-5871978-FFFFFF.svg" className="w-5 opacity-80 inline mr-4" />
                                    Assign to Folder(s)
                                  </span>
                                </Menu.Item> */}
                                {/* <div className="w-full p-4 text-gray-300">
                                  Folders that item is currently in (tags...)
                                </div> */}
                              </Menu.Items>
                            </Menu>





                            {/* <StoryDetailEdit
                              story={story}
                              linkOnly={0}
                            /> */}

                            {/* <span hover-tooltip="Clone Story" tooltip-position="bottom">
                              <img src="/media/icon/noun-clone-2075584-FFFFFF.svg" className="w-4 h-4 mt-3 opacity-60 hover:opacity-100"
                                onClick={() => storyClone(story)}
                              />
                            </span> */}




                          </div>
                        </div>

                      </div>

                    </div>

                  ))}

                </div>
              </>
              : null}


          </div>
        </div>
      </div>

      {/* <ToastContainer

        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        //draggable
        pauseOnHover
        theme="dark"
      /> */}

      <Toaster />

    </div>


  )

}

export default Stories