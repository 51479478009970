import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Slider from 'react-input-slider';
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer';
import CompMatcapGen from './CompMatcapGen';




const ConfigMeshShape3D = ({ contentItem, storyId }) => {

    //console.log(contentItem)


    const [svgUrl, setSvgUrl] = useState(contentItem.url || '');
    const [matcapUrl, setMatcapUrl] = useState(contentItem.matcapUrl || '');
    const [extrusion, setExtrusion] = useState(contentItem.extrusion || 20);
    const [rot, setRot] = useState(contentItem.rot || { x: 0, y: 0, z: 0 });
    const [pos, setPos] = useState(contentItem.pos || { x: 0, y: 0, z: 0 });
    const [scale, setScale] = useState(contentItem.scale || 1);

    const [storyData, setStoryData] = useContext(StoryContext);
    const [sceneData, setSceneData] = useContext(SceneContext);
    const [sessionData, setSessionData] = useContext(SessionContext);

    const fileInputRef = useRef(null);
    const captureRef = useRef(null);
    const [isGenerating, setIsGenerating] = useState(false);

    // const svgOptions = [
    //     { id: 1, name: 'Default SVG', url: '/shapes/01-up arrow.svg' },
    //     { id: 2, name: 'Another', url: '/shapes/b3-shape-chevrons-3.svg' }
    // ];

    const svgOptions = [
        { id: 1, name: '01-up arrow', url: '/shapes/01-up arrow.svg' },
        { id: 2, name: '236-send', url: '/shapes/236-send.svg' },
        { id: 3, name: '77-chevron', url: '/shapes/77-chevron.svg' },
        { id: 4, name: 'b3-arrow-sample', url: '/shapes/b3-arrow-sample.svg' },
        { id: 5, name: 'b3-shape-arrow-line-5', url: '/shapes/b3-shape-arrow-line-5.svg' },
        { id: 6, name: 'b3-shape-chevrons-3', url: '/shapes/b3-shape-chevrons-3.svg' },
        { id: 7, name: 'b3-shape-hex-a', url: '/shapes/b3-shape-hex-a.svg' },
        { id: 8, name: 'b3-shape-hex-dots-a', url: '/shapes/b3-shape-hex-dots-a.svg' },
        { id: 9, name: 'b3-shape-triangle', url: '/shapes/b3-shape-triangle.svg' },
        { id: 10, name: 'continue_straight', url: '/shapes/continue_straight.svg' },
        { id: 11, name: 'noun-1053750', url: '/shapes/noun-1053750.svg' },
        { id: 12, name: 'noun-1646700', url: '/shapes/noun-1646700.svg' },
        { id: 13, name: 'noun-1681054', url: '/shapes/noun-1681054.svg' },
        { id: 14, name: 'noun-1681059', url: '/shapes/noun-1681059.svg' },
        { id: 15, name: 'noun-2443420', url: '/shapes/noun-2443420.svg' },
        { id: 16, name: 'noun-2449184', url: '/shapes/noun-2449184.svg' },
        { id: 17, name: 'noun-26662', url: '/shapes/noun-26662.svg' },
        { id: 18, name: 'noun-3029497', url: '/shapes/noun-3029497.svg' },
        { id: 19, name: 'noun-3134100', url: '/shapes/noun-3134100.svg' },
        { id: 20, name: 'noun-3139214', url: '/shapes/noun-3139214.svg' },
        { id: 21, name: 'noun-3212693', url: '/shapes/noun-3212693.svg' },
        { id: 22, name: 'noun-3212705', url: '/shapes/noun-3212705.svg' },
        { id: 23, name: 'noun-3927008', url: '/shapes/noun-3927008.svg' },
        { id: 24, name: 'noun-4608637', url: '/shapes/noun-4608637.svg' },
        { id: 25, name: 'noun-4608642', url: '/shapes/noun-4608642.svg' },
        { id: 26, name: 'noun-4853990', url: '/shapes/noun-4853990.svg' },
        { id: 27, name: 'noun-5021619', url: '/shapes/noun-5021619.svg' },
        { id: 28, name: 'noun-5108875', url: '/shapes/noun-5108875.svg' },
        { id: 29, name: 'noun-5233179', url: '/shapes/noun-5233179.svg' },
        { id: 30, name: 'noun-5241132', url: '/shapes/noun-5241132.svg' },
        { id: 31, name: 'noun-5786594', url: '/shapes/noun-5786594.svg' },
        { id: 32, name: 'noun-595443', url: '/shapes/noun-595443.svg' },
        { id: 33, name: 'noun-5988232', url: '/shapes/noun-5988232.svg' },
        { id: 34, name: 'noun-599429', url: '/shapes/noun-599429.svg' },
        { id: 35, name: 'noun-6000447', url: '/shapes/noun-6000447.svg' },
        { id: 36, name: 'noun-6000451', url: '/shapes/noun-6000451.svg' },
        { id: 37, name: 'noun-6094272', url: '/shapes/noun-6094272.svg' },
        { id: 38, name: 'noun-6175665', url: '/shapes/noun-6175665.svg' },
        { id: 39, name: 'noun-6175673', url: '/shapes/noun-6175673.svg' },
        { id: 40, name: 'noun-6175696', url: '/shapes/noun-6175696.svg' },
        { id: 41, name: 'noun-6577563', url: '/shapes/noun-6577563.svg' },
        { id: 42, name: 'noun-662798', url: '/shapes/noun-662798.svg' },
        { id: 43, name: 'noun-6834664', url: '/shapes/noun-6834664.svg' },
        { id: 44, name: 'noun-6955006', url: '/shapes/noun-6955006.svg' },
        { id: 45, name: 'noun-6955030', url: '/shapes/noun-6955030.svg' },
        { id: 46, name: 'noun-6974403', url: '/shapes/noun-6974403.svg' },
        { id: 47, name: 'noun-7015354', url: '/shapes/noun-7015354.svg' },
        { id: 48, name: 'noun-7076089', url: '/shapes/noun-7076089.svg' },
        { id: 49, name: 'noun-7131012', url: '/shapes/noun-7131012.svg' },
        { id: 50, name: 'noun-7159687', url: '/shapes/noun-7159687.svg' },
        { id: 51, name: 'noun-7170739', url: '/shapes/noun-7170739.svg' },
        { id: 52, name: 'noun-7202189', url: '/shapes/noun-7202189.svg' },
        { id: 53, name: 'noun-7229695', url: '/shapes/noun-7229695.svg' },
        { id: 54, name: 'noun-7229702', url: '/shapes/noun-7229702.svg' },
        { id: 55, name: 'noun-7229710', url: '/shapes/noun-7229710.svg' },
        { id: 56, name: 'noun-7229713', url: '/shapes/noun-7229713.svg' },
        { id: 57, name: 'noun-7229716', url: '/shapes/noun-7229716.svg' },
        { id: 58, name: 'noun-7229718', url: '/shapes/noun-7229718.svg' },
        { id: 59, name: 'noun-7229719', url: '/shapes/noun-7229719.svg' },
        { id: 60, name: 'noun-7254292', url: '/shapes/noun-7254292.svg' },
        { id: 61, name: 'noun-7304952', url: '/shapes/noun-7304952.svg' },
        { id: 62, name: 'noun-964116', url: '/shapes/noun-964116.svg' }
    ];
    

    const updateContent = async () => {
        let cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes];

        cloneVizActive.forEach(item => {
            if (contentItem.guid === item.guid) {
                item.url = svgUrl;
                item.matcapUrl = matcapUrl;
                item.extrusion = extrusion;
                item.rot = rot;
                item.pos = pos;
                item.scale = scale;
            }
        });

        let activeSceneId = sceneData[sessionData.activeIndex].id;
        const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);

        await updateDoc(sceneRef, {
            viznodes: cloneVizActive
        });
    };

    const handleSvgUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/storyFileUpload', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    setSvgUrl(data.fileUrl);
                })
                .catch(error => {
                    console.error('Error uploading file:', error);
                });
        }
    };



    const handleRotChange = (axis, value) => {
        setRot(prev => ({ ...prev, [axis]: value }));
    };


    return (
        <div className="px-2 text-zinc-300">
            {/* <h2 className="text-xl font-bold mb-4">Configure MeshShape3D</h2> */}

            <div className="mb-4 mt-2">
                <Listbox value={svgUrl} onChange={setSvgUrl}>
                    <Listbox.Label className="block text-sm font-medium mb-1">Shape</Listbox.Label>
                    <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm">
                            <span className="block truncate">{svgOptions.find(option => option.url === svgUrl)?.name || 'Custom SVG'}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={React.Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {svgOptions.map((option) => (
                                    <Listbox.Option
                                        key={option.id}
                                        className={({ active }) =>
                                            `${active ? 'text-white bg-sky-600' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-3 pr-9`
                                        }
                                        value={option.url}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                                    <img src={option.url} className='inline h-8'/> 
                                                </span>
                                                {selected && (
                                                    <span
                                                        className={`${active ? 'text-white' : 'text-sky-600'}
                                absolute inset-y-0 right-0 flex items-center pr-4`}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
                {svgUrl === 'custom' && (
                    <div className="mt-2">
                        <input
                            type="file"
                            accept=".svg"
                            onChange={handleSvgUpload}
                            ref={fileInputRef}
                            className="hidden"
                        />
                        <button
                            onClick={() => fileInputRef.current.click()}
                            className="bg-sky-600 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Upload Custom SVG
                        </button>
                    </div>
                )}
            </div>

            <div className="mb-4">
                <CompMatcapGen matcapUrl={matcapUrl} setMatcapUrl={setMatcapUrl} type={'shape'} />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Extrusion</label>
                <Slider
                    axis="x"
                    x={extrusion}
                    onChange={({ x }) => setExtrusion(x)}
                    xmin={0}
                    xmax={100}
                    styles={{
                        track: { backgroundColor: 'white' },
                        active: { backgroundColor: '#4F46E5' },
                        thumb: { width: 20, height: 20, backgroundColor: '#4F46E5' },
                    }}
                />
                <span className="mt-1 inline-block">{extrusion}</span>
            </div>



            {['x', 'y', 'z'].map((axis) => (
                <div key={axis} className="mb-4">
                    <label className="block text-sm font-medium mb-1">Rotation {axis.toUpperCase()}</label>
                    <Slider
                        axis="x"
                        x={rot[axis]}
                        onChange={({ x }) => handleRotChange(axis, x)}
                        xmin={-180}
                        xmax={180}
                        xstep={5}
                        styles={{
                            track: { backgroundColor: 'white' },
                            active: { backgroundColor: '#4F46E5' },
                            thumb: { width: 20, height: 20, backgroundColor: '#4F46E5' },
                        }}
                    />
                    <span className="mt-1 inline-block">{rot[axis]}°</span>
                </div>
            ))}

            <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Scale</label>
                <Slider
                    axis="x"
                    x={scale}
                    onChange={({ x }) => setScale(x)}
                    xmin={0.001}
                    xmax={.25}
                    xstep={0.001}
                    styles={{
                        track: { backgroundColor: 'white' },
                        active: { backgroundColor: '#4F46E5' },
                        thumb: { width: 20, height: 20, backgroundColor: '#4F46E5' },
                    }}
                />
                <span className="mt-1 inline-block">{scale.toFixed(2) || 0}</span>
            </div>

            <button
                onClick={updateContent}
                className="w-full bg-sky-500 hover:bg-sky-400 text-white text-sm  py-2 px-4 rounded-sm"
            >
                Update
            </button>
        </div>
    );
};

export default ConfigMeshShape3D;