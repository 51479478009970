import React, { useRef, useMemo } from 'react'
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { Cylinder, Cone, Box, Torus } from '@react-three/drei'
import * as THREE from 'three'

const ArrowTypes = {
  SIMPLE: 1,
  DOUBLE_HEADED: 2,
  CURVED: 3,
  BLOCKY: 4,
  TAPERED: 5,
  CHEVRON: 6,
  CIRCULAR: 7,
  SKETCHY: 8,
  DASHED: 9
}

export default function ExpMeshArrow({
  arrowType = ArrowTypes.SIMPLE,
  dimensions = [0.1, 1, 0.2], // [shaft radius, length, head radius]
  matcapUrl = 'https://example.com/path/to/your/matcap.png',
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  scale = [1, 1, 1],
  color = '#ffffff',
  curvature = 0.5, // For curved arrows
  blockiness = 0.2, // For blocky arrows
  taper = 0.8, // For tapered arrows
  chevronAngle = Math.PI / 6, // For chevron arrows
  circularRadius = 0.5, // For circular arrows
  sketchiness = 0.1, // For sketchy arrows
  dashLength = 0.1, // For dashed arrows
  dashGap = 0.05 // For dashed arrows
}) {
  const meshRef = useRef()

  // Load matcap texture
  const matcapTexture = useLoader(TextureLoader, matcapUrl)

  // Calculate dimensions
  const [shaftRadius, length, headRadius] = dimensions
  const headLength = length * 0.2
  const shaftLength = length - headLength

  // Generate arrow based on type
  const renderArrow = () => {
    switch (arrowType) {
      case ArrowTypes.SIMPLE:
      case ArrowTypes.DOUBLE_HEADED:
      case ArrowTypes.CURVED:
      case ArrowTypes.BLOCKY:
      case ArrowTypes.TAPERED:
        // ... (keep the existing implementations for these types)

      case ArrowTypes.CHEVRON:
        const chevronWidth = headRadius * 2
        const chevronHeight = headLength
        return (
          <group rotation={[0, 0, -Math.PI / 2]}>
            <Cylinder args={[shaftRadius, shaftRadius, shaftLength, 32]} position={[0, -shaftLength / 2 - chevronHeight / 2, 0]}>
              <meshMatcapMaterial matcap={matcapTexture} color={color} />
            </Cylinder>
            <group position={[0, chevronHeight / 2, 0]}>
              <Box args={[chevronWidth, chevronHeight, shaftRadius]} rotation={[0, 0, chevronAngle]}>
                <meshMatcapMaterial matcap={matcapTexture} color={color} />
              </Box>
              <Box args={[chevronWidth, chevronHeight, shaftRadius]} rotation={[0, 0, -chevronAngle]}>
                <meshMatcapMaterial matcap={matcapTexture} color={color} />
              </Box>
            </group>
          </group>
        )

      case ArrowTypes.CIRCULAR:
        return (
          <group>
            <Torus args={[circularRadius, shaftRadius, 16, 100, Math.PI * 1.5]} rotation={[0, 0, Math.PI / 4]}>
              <meshMatcapMaterial matcap={matcapTexture} color={color} />
            </Torus>
            <Cone args={[headRadius, headLength, 32]} position={[circularRadius, 0, 0]} rotation={[0, 0, -Math.PI / 2]}>
              <meshMatcapMaterial matcap={matcapTexture} color={color} />
            </Cone>
          </group>
        )

      case ArrowTypes.SKETCHY:
        const sketchyPoints = []
        const segments = 50
        for (let i = 0; i <= segments; i++) {
          const t = i / segments
          const x = t * length
          const y = (Math.random() - 0.5) * sketchiness
          sketchyPoints.push(new THREE.Vector3(x, y, 0))
        }
        const sketchyCurve = new THREE.CatmullRomCurve3(sketchyPoints)
        return (
          <group>
            <mesh>
              <tubeGeometry args={[sketchyCurve, 64, shaftRadius, 8, false]} />
              <meshMatcapMaterial matcap={matcapTexture} color={color} />
            </mesh>
            <Cone args={[headRadius, headLength, 32]} position={[length, 0, 0]} rotation={[0, 0, -Math.PI / 2]}>
              <meshMatcapMaterial matcap={matcapTexture} color={color} />
            </Cone>
          </group>
        )

      case ArrowTypes.DASHED:
        const dashCount = Math.floor(shaftLength / (dashLength + dashGap))
        return (
          <group>
            {[...Array(dashCount)].map((_, index) => (
              <Cylinder
                key={index}
                args={[shaftRadius, shaftRadius, dashLength, 32]}
                position={[0, -shaftLength / 2 + index * (dashLength + dashGap) + dashLength / 2, 0]}
              >
                <meshMatcapMaterial matcap={matcapTexture} color={color} />
              </Cylinder>
            ))}
            <Cone args={[headRadius, headLength, 32]} position={[0, shaftLength / 2, 0]} rotation={[Math.PI, 0, 0]}>
              <meshMatcapMaterial matcap={matcapTexture} color={color} />
            </Cone>
          </group>
        )

      default:
        return null
    }
  }

  return (
    <group ref={meshRef} position={position} rotation={rotation} scale={scale}>
      {renderArrow()}
    </group>
  )
}