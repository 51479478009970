import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, OrbitControls, Stage } from '@react-three/drei';
import { useDropzone } from 'react-dropzone';
import { addDoc, collection } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logCustomEvent } from './UtilAnalytics';
import { getFunctions, httpsCallable } from 'firebase/functions';

const Model = ({ url }) => {
    const { scene } = useGLTF(url);
    const modelRef = useRef();

    useFrame((state, delta) => {
        if (modelRef.current) {
            modelRef.current.rotation.y += delta * 0.3;
        }
    });

    return <primitive ref={modelRef} object={scene} />;
};

const functions = getFunctions();

const CompModelAI = (props) => {
    const [file, setFile] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [modelUrl, setModelUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const canvasRef = useRef(null);
    const [modelLoaded, setModelLoaded] = useState(false);
    const [genQuery, setGenQuery] = useState('');

    const captureCanvas = useCallback(() => {
        return new Promise((resolve) => {
            if (canvasRef.current) {
                // Ensure the canvas is rendered before capturing
                requestAnimationFrame(() => {
                    canvasRef.current.toBlob((blob) => {
                        resolve(blob);
                    }, 'image/png');
                });
            } else {
                resolve(null);
            }
        });
    }, []);


    // const uploadFile = async (file) => {
    //     if (!file) return;

    //     setLoading(true);
    //     setError(null);

    //     try {
    //         const callModelAI = httpsCallable(functions, 'callModelAI');
    //         const response = await callModelAI({ image: file });
    //         setModelUrl(response.data.modelUrl);

    //         // Wait for the model to load before capturing the canvas
    //         setModelLoaded(true);
    //     } catch (err) {
    //         console.error('Error saving model:', err);
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     if (modelLoaded && modelUrl) {
    //         const captureAndUploadThumbnail = async () => {
    //             await new Promise(resolve => setTimeout(resolve, 1000));

    //             const canvasBlob = await captureCanvas();
    //             if (canvasBlob) {
    //                 const imageUUID = uuidv4();

    //                 try {
    //                     const callModelAIthumbnail = httpsCallable(functions, 'callModelAIthumbnail');
    //                     const thumbnailResponse = await callModelAIthumbnail({
    //                         image: canvasBlob,
    //                         filename: `thumb-${imageUUID}-${auth.currentUser.uid}.png`
    //                     });

    //                     const modelThumbnailUrl = thumbnailResponse.data.thumbnailUrl;

    //                     console.log('modelThumbnailUrl', modelThumbnailUrl);
    //                     console.log(thumbnailResponse);

    //                     const modelData = {
    //                         category: 'user',
    //                         guid: uuidv4(),
    //                         model: modelUrl,
    //                         modelThumbnail: modelThumbnailUrl,
    //                         name: 'modelname',
    //                         org: { key: 0 },
    //                         pos: { x: 0, y: 0, z: 0 },
    //                         rot: { x: 0, y: 0, z: 0 },
    //                         scale: 1,
    //                         owner: auth.currentUser.uid,
    //                     };

    //                     const modelDoc = await addDoc(collection(db, "models"), modelData);

    //                     logCustomEvent('gen_3d_model', {});

    //                     props.selectSceneComponent(modelData, props.index);
    //                 } catch (err) {
    //                     console.error('Error saving thumbnail:', err);
    //                 } finally {
    //                     setLoading(false);
    //                 }
    //             }
    //         };

    //         captureAndUploadThumbnail();
    //     }
    // }, [modelLoaded, modelUrl, captureCanvas]);

    const uploadFile = async (file) => {
        if (!file) return;

        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post('https://us-central1-brevit3-57b00.cloudfunctions.net/callModelAI', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setModelUrl(response.data.modelUrl);

            // Wait for the model to load before capturing the canvas
            setModelLoaded(true);

        } catch (err) {
            console.error('Error saving model:', err);
            //toast.error('Error saving model. Please try again.');
            setLoading(false);
        }
    };

    const uploadFileB64 = async (base64String) => {
        if (!base64String) return;

        setLoading(true);
        setError(null);

        // Convert base64 string to Blob
        const byteCharacters = atob(base64String.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        // Create a File object from the Blob
        const file = new File([blob], 'image.png', { type: 'image/png' });

        setThumbnail(base64String);

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post('https://us-central1-brevit3-57b00.cloudfunctions.net/callModelAI', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setModelUrl(response.data.modelUrl);
            setModelLoaded(true);
        } catch (err) {
            console.error('Error saving model:', err);
            setError('Error saving model. Please try again.');
            setLoading(false);
        }
    };


    const loaderFacts = [
        {
            "fact": "By 2030, it's estimated that 41 urban areas will have transformed into megacities, each housing more than 10 million residents.",
            "title": "Megacities on the Move"
        },
        {
            "fact": "Mauna Kea in Hawaii is technically the world’s tallest mountain when measured from base (underwater) to peak. It's over 33,000 feet tall, surpassing Mount Everest.",
            "title": "Tallest Mountain"
        },
        {
            "fact": "The Atacama Desert in Chile is so dry that some weather stations have never recorded a single drop of rain.",
            "title": "The Driest Place"
        },
        {
            "fact": "South Africa is unique in having three capital cities: Pretoria (executive), Bloemfontein (judicial), and Cape Town (legislative).",
            "title": "Two Capitals"
        },
        {
            "fact": "Russia is the largest country in the world, covering over 17 million square kilometers, spanning 11 time zones.",
            "title": "Largest Country"
        },
        {
            "fact": "Vatican City is the smallest country in the world, measuring just 44 hectares (110 acres).",
            "title": "Smallest Country"
        },
        {
            "fact": "Australia moves northward at a rate of about 7 centimeters per year due to tectonic movements.",
            "title": "Moving Tectonic Plates"
        },
        {
            "fact": "Lake Baikal in Siberia is the world’s oldest and deepest freshwater lake, estimated to be around 25 million years old.",
            "title": "The Oldest Lake"
        },
        {
            "fact": "Indonesia has the most active volcanoes of any country, with more than 130.",
            "title": "Volcanic Activity"
        },
        {
            "fact": "The Caspian Sea, despite its name, is the largest lake in the world, bordered by five countries.",
            "title": "Largest Lake"
        },
        {
            "fact": "Papua New Guinea is the most linguistically diverse country in the world, with over 800 languages spoken.",
            "title": "Most Languages Spoken"
        },
        {
            "fact": "The International Date Line causes the time and date to be different on islands that are sometimes only miles apart.",
            "title": "International Date Line Anomalies"
        },
        {
            "fact": "The Nördlinger Ries crater in Germany was caused by a meteorite impact about 14.5 million years ago, and it’s a circular basin almost 25 kilometers across.",
            "title": "Country Split by a Meteorite"
        },
        {
            "fact": "La Rinconada in Peru is considered the highest permanent settlement in the world, situated at over 5,100 meters (16,700 feet) above sea level.",
            "title": "The Highest Village"
        },
        {
            "fact": "The shoreline of the Dead Sea Depression in Israel and Jordan is the lowest point on Earth's surface, sitting at 430 meters (1,411 feet) below sea level.",
            "title": "Lowest Point on Land"
        },
        {
            "fact": "The tallest sand dune in the world is found in Namibia. Known as Dune 7, it stands around 383 meters (1,256 feet) tall.",
            "title": "Sand Dune Extremes"
        },
        {
            "fact": "Africa is the largest continent that is entirely in the eastern hemisphere and is expected to split into two continents eventually due to the shifting tectonic plates.",
            "title": "Independent Continent"
        },
        {
            "fact": "The Waihilau Falls in Hawaii, once considered one of the tallest waterfalls in the world, is often dry due to a diversion of its water upstream.",
            "title": "Disappearing Waterfall"
        },
        {
            "fact": "Sweden has one of the highest numbers of islands of any country, with around 267,570 islands.",
            "title": "Island Density"
        },
        {
            "fact": "Paraguay is unique as the only country that is landlocked in both the Northern and Southern Hemispheres.",
            "title": "Only Landlocked in Two Hemispheres"
        },
        {
            "fact": "The oldest known chair dates back to around 3100 BC and was found in the tomb of Pharaoh Tutankhamun.",
            "title": "Oldest Known Chair"
        },
        {
            "fact": "The term 'chair' originally referred to the seat of authority for a leader or person of high status.",
            "title": "Origin of 'Chair'"
        },
        {
            "fact": "The iconic Eames Lounge Chair, designed by Charles and Ray Eames, was first introduced in 1956 and remains a design classic.",
            "title": "Eames Lounge Chair"
        },
        {
            "fact": "Rocking chairs were invented in the early 18th century in North America and are often associated with Benjamin Franklin, though he didn't actually invent them.",
            "title": "Rocking Chairs"
        },
        {
            "fact": "The world's most expensive chair is the Dragon's Chair by Eileen Gray, which sold at auction for $28 million in 2009.",
            "title": "Most Expensive Chair"
        },
        {
            "fact": "The term 'chairman' comes from the medieval practice of the leader sitting in a chair while others sat on benches or stood.",
            "title": "Chairman"
        },
        {
            "fact": "Chairs were once considered a luxury item, and only the wealthy or people of high rank had them.",
            "title": "Chairs as Luxury"
        },
        {
            "fact": "The famous Windsor chair originated in England in the early 18th century and is known for its spindle back and splayed legs.",
            "title": "Windsor Chair"
        },
        {
            "fact": "The ergonomic office chair was designed to support the body and reduce strain, with significant advancements made in the 1970s.",
            "title": "Ergonomic Office Chair"
        },
        {
            "fact": "A throne is a type of chair used by monarchs and deities, often highly decorated and symbolizing power and authority.",
            "title": "Throne"
        },
        {
            "fact": "The first folding chairs were invented by the ancient Egyptians and were used primarily for military campaigns.",
            "title": "Folding Chairs"
        },
        {
            "fact": "A papasan chair, also known as a bowl chair, originated in Southeast Asia and became popular in the West in the mid-20th century.",
            "title": "Papasan Chair"
        },
        {
            "fact": "The Barcelona chair, designed by Ludwig Mies van der Rohe and Lilly Reich, was created for the German Pavilion at the 1929 International Exposition in Barcelona, Spain.",
            "title": "Barcelona Chair"
        },
        {
            "fact": "The term 'chaise longue' is French for 'long chair,' and it refers to an upholstered chair with an extended seat to support the legs.",
            "title": "Chaise Longue"
        },
        {
            "fact": "The Adirondack chair, with its distinctive slanted seat and back, was first designed by Thomas Lee in the early 1900s for his summer home in the Adirondack Mountains.",
            "title": "Adirondack Chair"
        },
        {
            "fact": "The kneeling chair, designed to promote better posture, was invented in Norway in the 1970s.",
            "title": "Kneeling Chair"
        },
        {
            "fact": "The Aeron chair, a highly ergonomic office chair designed by Bill Stumpf and Don Chadwick, became a symbol of the dot-com boom in the late 1990s.",
            "title": "Aeron Chair"
        },
        {
            "fact": "The Louis XVI chair is characterized by its straight lines, rectangular or oval back, and fluted legs, reflecting the neoclassical style of the late 18th century.",
            "title": "Louis XVI Chair"
        },
        {
            "fact": "The ghost chair, designed by Philippe Starck, is made from a single piece of transparent polycarbonate and is known for its modern, minimalist design.",
            "title": "Ghost Chair"
        },
        {
            "fact": "Chair design can significantly impact health, with poorly designed chairs contributing to back pain, poor posture, and other musculoskeletal issues.",
            "title": "Impact on Health"
        },
        {
            "fact": "The oldest known noodles were found in China and are over 4,000 years old, made from millet grass.",
            "title": "Oldest Noodles"
        },
        {
            "fact": "Instant noodles were invented by Momofuku Ando in Japan in 1958, revolutionizing the food industry.",
            "title": "Instant Noodles Invention"
        },
        {
            "fact": "There is a type of noodle called 'Shirataki' that is made from the konjac yam and is almost calorie-free.",
            "title": "Shirataki Noodles"
        },
        {
            "fact": "In Italy, there are over 350 different types of pasta, each with a unique name and shape.",
            "title": "Italian Pasta Varieties"
        },
        {
            "fact": "Noodles are a staple food in many Asian countries, but they are also very popular in parts of Europe and the Middle East.",
            "title": "Global Popularity"
        },
        {
            "fact": "The word 'noodle' comes from the German word 'nudel,' which means a strip of dough.",
            "title": "Etymology of 'Noodle'"
        },
        {
            "fact": "Noodles are often made from wheat, rice, buckwheat, and other grains, but they can also be made from vegetables like zucchini (zoodles).",
            "title": "Noodle Ingredients"
        },
        {
            "fact": "There is a popular South Korean noodle dish called 'Jjajangmyeon' that features black bean paste.",
            "title": "Jjajangmyeon"
        },
        {
            "fact": "In Japan, there is a tradition of eating soba noodles on New Year's Eve for good luck in the coming year.",
            "title": "New Year's Soba"
        },
        {
            "fact": "Ramen noodles were once considered a luxury item in China, but now they are a cheap and convenient meal worldwide.",
            "title": "Ramen Noodles"
        },
        {
            "fact": "Pho, a Vietnamese noodle soup, is made with flat rice noodles and is often served with fresh herbs and lime.",
            "title": "Pho"
        },
        {
            "fact": "Hand-pulled noodles, known as 'La Mian,' are a traditional Chinese technique that requires great skill and strength.",
            "title": "La Mian"
        },
        {
            "fact": "In Italy, pasta is often categorized by its shape, such as spaghetti (long and thin) and penne (short and tubular).",
            "title": "Italian Pasta Shapes"
        },
        {
            "fact": "Noodles have been used in space! Japanese astronaut Soichi Noguchi enjoyed instant noodles during his mission aboard the International Space Station.",
            "title": "Space Noodles"
        },
        {
            "fact": "The longest noodle ever made was over 3,000 meters (9,840 feet) long, created in China in 2017.",
            "title": "Longest Noodle"
        },
        {
            "fact": "Pad Thai, a popular Thai noodle dish, was promoted by the Thai government in the 1930s to encourage national unity and the use of local ingredients.",
            "title": "Pad Thai"
        },
        {
            "fact": "Spätzle is a type of egg noodle that is a specialty of Germany and Austria, often served with cheese and onions.",
            "title": "Spätzle"
        },
        {
            "fact": "Noodles are a symbol of longevity in Chinese culture and are often eaten during birthday celebrations.",
            "title": "Longevity Noodles"
        },
        {
            "fact": "Glass noodles, also known as cellophane or bean thread noodles, are made from mung bean starch and are translucent when cooked.",
            "title": "Glass Noodles"
        },
        {
            "fact": "The world's first noodle museum, the Cup Noodles Museum, is located in Yokohama, Japan, and showcases the history of instant noodles.",
            "title": "Cup Noodles Museum"
        },
        {
            "fact": "The word 'taco' originally referred to gunpowder wrapped in paper used by miners in Mexico.",
            "title": "Origin of 'Taco'"
        },
        {
            "fact": "The first recorded taco party was held by Hernán Cortés in the early 16th century for his soldiers.",
            "title": "First Taco Party"
        },
        {
            "fact": "Tacos are believed to have been eaten in some form since the ancient Aztec civilization.",
            "title": "Ancient Roots"
        },
        {
            "fact": "The world's largest taco was made in Mexico in 2011 and measured over 246 feet long.",
            "title": "Largest Taco"
        },
        {
            "fact": "There is a National Taco Day celebrated annually on October 4th in the United States.",
            "title": "National Taco Day"
        },
        {
            "fact": "The 'Taco Tuesday' tradition has become a popular weekly event in many parts of the world.",
            "title": "Taco Tuesday"
        },
        {
            "fact": "Tacos al Pastor, a popular type of taco, were inspired by Lebanese immigrants who brought shawarma to Mexico.",
            "title": "Tacos al Pastor"
        },
        {
            "fact": "The U.S. is the largest consumer of tacos outside of Mexico, with Americans eating over 4.5 billion tacos annually.",
            "title": "Taco Consumption in the U.S."
        },
        {
            "fact": "The 'Doritos Locos Taco' from Taco Bell sold over 100 million units within the first 10 weeks of its release in 2012.",
            "title": "Doritos Locos Taco"
        },
        {
            "fact": "The world's most expensive taco is served at the Grand Velas Los Cabos resort in Mexico and costs $25,000.",
            "title": "Most Expensive Taco"
        },
        {
            "fact": "In Mexico, traditional tacos are usually served on soft corn tortillas, while in the U.S., hard shell tacos are also popular.",
            "title": "Soft vs. Hard Shells"
        },
        {
            "fact": "There is a variation of tacos known as 'tacos de canasta' or 'basket tacos,' which are often sold by street vendors in Mexico.",
            "title": "Tacos de Canasta"
        },
        {
            "fact": "Fish tacos originated in Baja California and are typically made with fried or grilled fish, cabbage, and a creamy sauce.",
            "title": "Fish Tacos"
        },
        {
            "fact": "The taco is such a beloved dish that there is even a taco emoji (🌮) in the Unicode standard.",
            "title": "Taco Emoji"
        },
        {
            "fact": "Tacos can be filled with a wide variety of ingredients, including meats, seafood, vegetables, and even insects like grasshoppers (chapulines).",
            "title": "Diverse Fillings"
        },
        {
            "fact": "The Guinness World Record for the most tacos made in an 8-hour period is held by the University of Texas at Arlington, where over 35,000 tacos were made.",
            "title": "Most Tacos Made"
        },
        {
            "fact": "There is a museum dedicated to tacos called 'Taco Bell: The Bell' located in Las Vegas, Nevada.",
            "title": "Taco Museum"
        },
        {
            "fact": "In Mexico, it's common to have tacos for breakfast, known as 'tacos de desayuno,' which often include fillings like eggs and chorizo.",
            "title": "Breakfast Tacos"
        },
        {
            "fact": "Barbacoa tacos are made from slow-cooked, tender meat, often lamb or beef, traditionally cooked in an underground pit.",
            "title": "Barbacoa Tacos"
        },
        {
            "fact": "Taco trucks and street vendors, known as 'taqueros,' are a staple in Mexican cities and play a crucial role in taco culture.",
            "title": "Taqueros"
        },
        {
            "fact": "Cheese is one of the oldest foods in the world, with evidence suggesting it was made over 7,200 years ago in what is now Poland.",
            "title": "Oldest Food"
        },
        {
            "fact": "There are over 1,800 different types of cheese, categorized by their textures, flavors, and production methods.",
            "title": "Variety of Cheeses"
        },
        {
            "fact": "The most expensive cheese in the world is Pule, made from the milk of Balkan donkeys in Serbia, costing about $1,000 per kilogram.",
            "title": "Most Expensive Cheese"
        },
        {
            "fact": "The characteristic holes in Swiss cheese, known as 'eyes,' are formed by carbon dioxide gas released by bacteria during fermentation.",
            "title": "Swiss Cheese Holes"
        },
        {
            "fact": "Cheese can be made from the milk of cows, goats, sheep, buffalo, yaks, reindeer, and even camels.",
            "title": "Diverse Milk Sources"
        },
        {
            "fact": "Roquefort, a famous blue cheese from France, is aged in limestone caves that provide a unique environment for the growth of Penicillium roqueforti mold.",
            "title": "Roquefort Cheese"
        },
        {
            "fact": "Cheese was traditionally stored in caves to mature because the stable temperature and humidity provided ideal aging conditions.",
            "title": "Cave Aging"
        },
        {
            "fact": "Mozzarella cheese was originally made from the milk of water buffaloes in Italy and is a key ingredient in traditional Neapolitan pizza.",
            "title": "Mozzarella Origins"
        },
        {
            "fact": "The largest cheese ever made weighed over 57,000 pounds and was produced in Wisconsin for the 1964 New York World's Fair.",
            "title": "Largest Cheese"
        },
        {
            "fact": "Cheddar cheese is named after the Cheddar Gorge caves in Somerset, England, where it was first made and aged.",
            "title": "Cheddar Cheese"
        },
        {
            "fact": "Parmigiano-Reggiano, also known as Parmesan, is often referred to as the 'King of Cheeses' and must be aged for at least 12 months by law.",
            "title": "Parmigiano-Reggiano"
        },
        {
            "fact": "Feta cheese, a staple in Greek cuisine, is traditionally made from sheep's milk or a mixture of sheep and goat's milk.",
            "title": "Feta Cheese"
        },
        {
            "fact": "The blue veins in blue cheeses are created by piercing the cheese with needles to allow air to penetrate and stimulate the growth of mold.",
            "title": "Blue Cheese Veins"
        },
        {
            "fact": "Queso Blanco, a type of fresh cheese, is popular in Latin American cuisine and is often used for frying because it doesn't melt easily.",
            "title": "Queso Blanco"
        },
        {
            "fact": "In some cultures, cheese is considered a dessert and is enjoyed at the end of a meal with fruits and nuts.",
            "title": "Cheese as Dessert"
        },
        {
            "fact": "The world's largest cheese sculpture was created in Wisconsin, USA, weighing 2,000 pounds and depicting a dairy farm scene.",
            "title": "Largest Cheese Sculpture"
        },
        {
            "fact": "In Sardinia, Italy, there is a traditional cheese called Casu Marzu that contains live insect larvae, which is considered a delicacy.",
            "title": "Casu Marzu"
        },
        {
            "fact": "Gorgonzola, one of the world's oldest blue cheeses, dates back to the year 879 AD and originates from the town of Gorgonzola in Italy.",
            "title": "Gorgonzola"
        },
        {
            "fact": "The term 'cheese' is often used in photography to encourage people to smile because the word forces the mouth into a smile-like shape.",
            "title": "Say Cheese!"
        },
        {
            "fact": "Cheese production is a significant industry worldwide, with the United States being the largest producer of cheese, followed by Germany and France.",
            "title": "Cheese Production"
        },
        {
            "fact": "The concept of toasting bread dates back to ancient Egypt, where it was used as a method to preserve bread.",
            "title": "Ancient Egyptian Toast"
        },
        {
            "fact": "The word 'toast' comes from the Latin word 'tostum,' which means 'to burn' or 'scorch.'",
            "title": "Etymology of 'Toast'"
        },
        {
            "fact": "Toasters were first invented in the early 20th century, with the first electric toaster patented by Albert Marsh in 1906.",
            "title": "Invention of the Toaster"
        },
        {
            "fact": "The first commercially successful toaster, the Toastmaster, was introduced in 1926 and could toast both sides of the bread simultaneously.",
            "title": "The Toastmaster"
        },
        {
            "fact": "Burnt toast has been studied for its potential carcinogenic properties due to the formation of acrylamide, a chemical produced when starchy foods are cooked at high temperatures.",
            "title": "Burnt Toast and Health"
        },
        {
            "fact": "French toast, a popular breakfast dish, is believed to have originated in ancient Rome and was called 'Pan Dulcis.'",
            "title": "Origins of French Toast"
        },
        {
            "fact": "In the UK, beans on toast is a traditional and beloved dish, often enjoyed as a quick meal or snack.",
            "title": "Beans on Toast"
        },
        {
            "fact": "Toast is commonly used as a base for various toppings, from simple butter and jam to elaborate avocado and poached eggs.",
            "title": "Versatile Toppings"
        },
        {
            "fact": "Cinnamon toast, made with butter, sugar, and cinnamon, is a classic sweet treat often enjoyed as a breakfast or snack.",
            "title": "Cinnamon Toast"
        },
        {
            "fact": "In Japan, thick-cut toast called 'shokupan' is often served with sweet or savory toppings, making it a popular breakfast choice.",
            "title": "Shokupan"
        },
        {
            "fact": "Toasting bread can reduce its glycemic index, making it a slightly better option for people managing blood sugar levels.",
            "title": "Glycemic Index"
        },
        {
            "fact": "Toast art, where images or designs are imprinted onto toast, has become a quirky and popular trend in recent years.",
            "title": "Toast Art"
        },
        {
            "fact": "The phrase 'to raise a toast' comes from the 16th-century tradition of flavoring drinks with spiced toast.",
            "title": "Raising a Toast"
        },
        {
            "fact": "In 2002, the internet sensation 'eBay Toast' featured a piece of toast that appeared to have an image of the Virgin Mary, which sold for $28,000.",
            "title": "eBay Toast"
        },
        {
            "fact": "The world record for the most slices of toast buttered in one minute is held by Antony Britton, who buttered 27 slices in 2013.",
            "title": "Butter Toast Record"
        },
        {
            "fact": "In some cultures, toast is used as a traditional remedy for upset stomachs due to its simplicity and ease of digestion.",
            "title": "Toast as Remedy"
        },
        {
            "fact": "Avocado toast, a modern favorite, became a popular dish in the 2010s and is often associated with health-conscious millennials.",
            "title": "Avocado Toast"
        },
        {
            "fact": "Toasters have evolved to include features like adjustable browning settings, defrost options, and even bagel modes.",
            "title": "Modern Toasters"
        },
        {
            "fact": "In Spain, a common breakfast dish is 'pan con tomate,' which involves rubbing tomato, olive oil, and garlic onto toasted bread.",
            "title": "Pan con Tomate"
        },
        {
            "fact": "The world's largest piece of toast was created in the UK in 2018, measuring over 6 feet long and weighing 70 pounds.",
            "title": "Largest Toast"
        },
        {
            "fact": "The world's first adhesive postage stamp, the Penny Black, was issued in the United Kingdom on May 1, 1840.",
            "title": "First Postage Stamp"
        },
        {
            "fact": "The most valuable stamp in the world is the British Guiana 1c Magenta, which sold for $9.5 million at auction in 2014.",
            "title": "Most Valuable Stamp"
        },
        {
            "fact": "The first U.S. postage stamps were issued in 1847 and featured Benjamin Franklin and George Washington.",
            "title": "First U.S. Stamps"
        },
        {
            "fact": "Some stamps have errors that make them extremely valuable, such as the Inverted Jenny, which features an upside-down airplane.",
            "title": "Stamp Errors"
        },
        {
            "fact": "The study and collection of stamps are known as philately, and those who collect stamps are called philatelists.",
            "title": "Philately"
        },
        {
            "fact": "Stamps were originally introduced to prevent mail fraud by showing that postage had been paid.",
            "title": "Purpose of Stamps"
        },
        {
            "fact": "In 1969, Bhutan issued the world's first 3D stamps, which featured images that appeared to move when tilted.",
            "title": "3D Stamps"
        },
        {
            "fact": "The smallest stamp ever issued was by the Chinese province of Jiangxi in 1945, measuring just 2.5 millimeters by 3 millimeters.",
            "title": "Smallest Stamp"
        },
        {
            "fact": "The world's largest stamp was issued by China in 2013 and measures 210 millimeters by 65 millimeters.",
            "title": "Largest Stamp"
        },
        {
            "fact": "Stamps can be made from a variety of materials, including paper, silk, wood, and even gold foil.",
            "title": "Stamp Materials"
        },
        {
            "fact": "The first self-adhesive stamp was issued by Sierra Leone in 1964 to combat the problem of stamps losing their adhesive properties in humid climates.",
            "title": "Self-Adhesive Stamps"
        },
        {
            "fact": "The first airmail stamp was issued by Italy in 1917, and it featured an image of an airplane flying over the Colosseum.",
            "title": "First Airmail Stamp"
        },
        {
            "fact": "Some countries have issued stamps that can be smelled, featuring scents like chocolate, roses, and coffee.",
            "title": "Scented Stamps"
        },
        {
            "fact": "In 2001, Austria issued a stamp made from woven fabric to commemorate its textile industry.",
            "title": "Fabric Stamp"
        },
        {
            "fact": "In 2007, the Netherlands issued the world's first animated stamp, which displayed a moving image when viewed through a special lens.",
            "title": "Animated Stamp"
        },
        {
            "fact": "Stamps often feature famous people, landmarks, animals, and events, making them miniature pieces of art and history.",
            "title": "Stamp Subjects"
        },
        {
            "fact": "The first personalized stamp, where people could have their own images printed, was issued by Australia in 1999.",
            "title": "Personalized Stamps"
        },
        {
            "fact": "In 2004, Switzerland issued a stamp made of lace to celebrate the country's lace-making tradition.",
            "title": "Lace Stamp"
        },
        {
            "fact": "Some stamps have been issued with hidden security features, like ultraviolet ink, to prevent counterfeiting.",
            "title": "Security Features"
        },
        {
            "fact": "The most famous stamp collector was King George V of the United Kingdom, who had an extensive and valuable collection.",
            "title": "Famous Collector"
        },
        {
            "fact": "There are more stars in the universe than grains of sand on all the Earth's beaches combined.",
            "title": "Stars vs. Sand"
        },
        {
            "fact": "The largest volcano in the solar system is Olympus Mons on Mars, which is about 13.6 miles (22 kilometers) high and 370 miles (600 kilometers) in diameter.",
            "title": "Largest Volcano"
        },
        {
            "fact": "A day on Venus is longer than a year on Venus; it takes 243 Earth days to rotate once and 225 Earth days to orbit the Sun.",
            "title": "Day Longer than Year"
        },
        {
            "fact": "Neutron stars are so dense that a sugar-cube-sized amount of their material would weigh about a billion tons on Earth.",
            "title": "Density of Neutron Stars"
        },
        {
            "fact": "The hottest planet in our solar system is not Mercury but Venus, with surface temperatures reaching up to 900 degrees Fahrenheit (475 degrees Celsius).",
            "title": "Hottest Planet"
        },
        {
            "fact": "There is a giant cloud of alcohol in space; it's called Sagittarius B2 and contains enough ethyl alcohol to supply every person on Earth with 300,000 pints of beer each day for a billion years.",
            "title": "Alcohol Cloud"
        },
        {
            "fact": "In space, astronauts' height can increase by about 2 inches (5 centimeters) due to the lack of gravity, which allows the spine to stretch.",
            "title": "Astronaut Height"
        },
        {
            "fact": "The International Space Station (ISS) travels at approximately 17,500 miles per hour (28,000 kilometers per hour), orbiting Earth about every 90 minutes.",
            "title": "ISS Speed"
        },
        {
            "fact": "A day on the dwarf planet Ceres is only about 9 hours long, making it one of the shortest days of any object in our solar system.",
            "title": "Shortest Day"
        },
        {
            "fact": "The Sun makes up about 99.86% of the total mass of the solar system.",
            "title": "Mass of the Sun"
        },
        {
            "fact": "The Great Wall of China is not visible from space with the naked eye, contrary to popular belief.",
            "title": "Great Wall Visibility"
        },
        {
            "fact": "There is no sound in space because there is no atmosphere for sound waves to travel through.",
            "title": "Silence in Space"
        },
        {
            "fact": "If two pieces of metal touch in space, they can become permanently stuck together due to a process called cold welding.",
            "title": "Cold Welding"
        },
        {
            "fact": "The largest known star, UY Scuti, is about 1,700 times the diameter of the Sun.",
            "title": "Largest Star"
        },
        {
            "fact": "A light year, which is the distance light travels in one year, is about 5.88 trillion miles (9.46 trillion kilometers).",
            "title": "Light Year Distance"
        },
        {
            "fact": "There are more trees on Earth than stars in the Milky Way; Earth has over 3 trillion trees, while the Milky Way contains about 100-400 billion stars.",
            "title": "Trees vs. Stars"
        },
        {
            "fact": "Jupiter's moon Ganymede is the largest moon in the solar system and is even larger than the planet Mercury.",
            "title": "Largest Moon"
        },
        {
            "fact": "The surface of Mars is covered in iron oxide, or rust, which gives the planet its reddish color.",
            "title": "Mars' Red Color"
        },
        {
            "fact": "Saturn's rings are made mostly of ice particles, with some rocky debris and dust.",
            "title": "Saturn's Rings"
        },
        {
            "fact": "Black holes are not actual holes; they are regions of space where the gravitational pull is so strong that not even light can escape.",
            "title": "Black Holes"
        }
    ]

    const getRandomFact = (facts) => {
        return facts[Math.floor(Math.random() * facts.length)];
    };

    const LoaderFactDisplay = () => {
        const [fact, setFact] = useState(getRandomFact(loaderFacts));
        const [fade, setFade] = useState(true);

        useEffect(() => {
            const interval = setInterval(() => {
                setFade(false);
                setTimeout(() => {
                    setFact(getRandomFact(loaderFacts));
                    setFade(true);
                }, 1000); // Duration of the fade-out transition
            }, 7500);

            return () => clearInterval(interval);
        }, []);

        return (
            <div className="  items-center justify-center h-40  rounded-md border-1 border-gray-800 px-8  ">
                <div className="text-base text-center mb-4 text-zinc-200">Trivia</div>
                <div className={`transition-opacity duration-1000  w-full ${fade ? 'opacity-100' : 'opacity-0'}`}>
                    <h1 className="text-base text-center font-semibold mb-4 text-zinc-100">{fact.title}</h1>
                    <p className="text-sm text-gray-300 text-center">{fact.fact}</p>
                </div>
            </div>
        );
    };


    const generateTextFromPrompt = async () => {
        //try {

        setLoading(true);
        setError(null);

        const fullQuery = genQuery + ' as a simple 3d model';

        const response = fetch(
            `https://us-central1-brevit3-57b00.cloudfunctions.net/generateImageResponse?imageDescription=${encodeURIComponent(fullQuery)}`)
            .then((response) => response.json())
            .then((data) => {
                // setAILoading(false)
                // setAIerror(null)
                //            setGeneratedImage(`data:image/jpeg;base64,${data.photo}`)
                uploadFileB64(`data:image/jpeg;base64,${data.photo}`)
                //setLoading(false);
            }
            ).catch(function (error) {                        // catch
                //console.log('Request failed', error);
                // setAIerror(1)
                // setAILoading(false)
                console.error('Error creating image:', error);
                setLoading(false);
            });
    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            generateTextFromPrompt();
        }
    };

    useEffect(() => {
        if (modelLoaded && modelUrl) {
            const captureAndUploadThumbnail = async () => {
                // Wait a bit to ensure the model is rendered
                //await new Promise(resolve => setTimeout(resolve, 1000));
                await new Promise(resolve => setTimeout(resolve, 1000));

                const canvasBlob = await captureCanvas();
                if (canvasBlob) {
                    const thumbnailFormData = new FormData();

                    const imageUUID = uuidv4();

                    thumbnailFormData.append('image', canvasBlob, `thumb-${imageUUID}-${auth.currentUser.uid}.png`);

                    try {
                        const thumbnailResponse = await axios.post('https://us-central1-brevit3-57b00.cloudfunctions.net/callModelAIthumbnail', thumbnailFormData, {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        });

                        const modelThumbnailUrl = thumbnailResponse.data.thumbnailUrl;

                        console.log('modelThumbnailUrl', modelThumbnailUrl);
                        console.log(thumbnailResponse)

                        const modelData = {
                            category: 'user',
                            guid: uuidv4(),
                            model: modelUrl,
                            modelThumbnail: modelThumbnailUrl,
                            name: 'modelname',
                            org: { key: 0 },
                            pos: { x: 0, y: 5, z: 0 },
                            rot: { x: 0, y: -180, z: 0 },
                            scale: 1,
                            owner: auth.currentUser.uid,
                        }

                        const modelDoc = await addDoc(collection(db, "models"), modelData);

                        //track3DModelGeneration(modelData.guid);
                        logCustomEvent('gen_3d_model', {})

                        //setTimeout(() => {
                        props.selectSceneComponent(modelData, props.index);
                        //}, 1000);


                        //                        toast.success('Model and thumbnail saved successfully!');
                    } catch (err) {
                        console.error('Error saving thumbnail:', err);
                        //                        toast.error('Error saving thumbnail. Please try again.');
                    } finally {
                        setLoading(false);
                    }
                }
            };

            captureAndUploadThumbnail();
        }
    }, [modelLoaded, modelUrl, captureCanvas]);


    const onDrop = useCallback((acceptedFiles) => {
        const droppedFile = acceptedFiles[0];
        setFile(droppedFile);

        const reader = new FileReader();
        reader.onload = (e) => {
            setThumbnail(e.target.result);
        };
        reader.readAsDataURL(droppedFile);

        uploadFile(droppedFile);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        // accept: 'image/*',
        accept: {
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/webp': []
        },
        multiple: false
    });

    return (

        <div className="container mx-auto text-transparent">

            {!modelUrl & !loading && (
                <>
                    {/* <div className='w-full py-1 text-sky-300 text-center'>
                        or
                    </div> */}
                    <div {...getRootProps()} className={`p-6 mt-4 border text-zinc-50 text-sm h-56 text-zinc-100 border-dashed rounded-lg ${isDragActive ? 'border-sky-400 bg-zinc-800' : 'border-sky-600'}`}
                    // style={{ backgroundImage: thumbnail ? thumbnail : null }}
                    >
                        <input {...getInputProps()} />

                        <div className='mt-8 text-center'>
                            <img src="/util/app/b3-genai-v2.svg" alt="Upload" className="mx-auto mt-4 mb-6 h-8" />
                            {isDragActive ?
                                <p className="text-center">Drop the image here</p> :
                                <div className="text-center">Drag image here or click to select
                                    <br />
                                    <span className='text-zinc-500'>png, jpeg and webp accepted</span>
                                </div>
                            }
                            <div className='text-amber-300 mt-4 mx-aut text-xs'><span className=' '>please note - </span>experimental feature</div>
                        </div>

                    </div>
                    <div className='w-full py-4 text-sky-300 text-center'>
                        or
                    </div>
                    <div className='flex'>
                        <input
                            type="text"
                            value={genQuery}
                            // onChange={(e) => setGenQuery(e.target.value)}
                            onChange={(e) => setGenQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="describe the model you want"
                            className="block w-full  rounded-md border-0 px-4 py-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset text-sm
                                                        ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:leading-6 focus:outline-none"
                        />


                        {/* <div className="m-1 
                  relative mt-6 p-0.5 inline-flex items-center justify-center cursor-pointer overflow-hidden group rounded-md"
                                        onClick={() => {
                                            onClick={generateTextFromPrompt}
                                        }}>
                                        <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                                        <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400 text-center">
                                        <span className='text-zinc-100 relative mx-auto'>create</span>
                                        </span>
                                    </div> */}


                        <div
                            onClick={generateTextFromPrompt}
                            className="ml-2 mr-1 justify-center rounded-md bg-sky-500 px-3 py-1.5
                    text-l font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                    cursor-pointer 
                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            create
                        </div>
                    </div>
                </>
            )}


            {!modelUrl && (
                <>
                    {loading && (
                        <>
                            <LoaderFactDisplay />
                            
                                <div className="mt-4 bg-cover bg-center h-56 rounded-md bg-black/10 " style={{ backgroundImage: `url(${thumbnail})`, filter: "grayscale(0%)" }} >
                                </div>
                                <div className="flex space-x-2 justify-center mx-auto mt-4 w-full ml-12 -mt-24">
                                    <div className="container-audio">
                                        {[...Array(7)].map((_, i) => (
                                            <div key={i} className="colum1">
                                                <div className="row"></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className='text-left bg-black/50 p-4 rounded text-zinc-100 mt-4 w-full'>
                                {!thumbnail && !modelUrl && (
                                    <>
                                        <div className="text-sm  text-sky-300 text-center ">
                                            1. Thinking of how to draw your model
                                        </div>
                                        <div className="text-sm text-zinc-500 mt-2 text-center ">
                                            2. Generating 3D model
                                        </div>
                                    </>
                                )}
                                {thumbnail && !modelUrl && (
                                    <>
                                        <div className="text-sm text-zinc-500 text-center ">
                                            1. Thinking of how to draw your model
                                        </div>
                                        <div className="text-sm  text-sky-300 mt-2 text-center ">
                                            2. Generating 3D model
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}

            {modelUrl && (
                <div className="w-full col-span-2 h-96 mt-4 p-4 rounded-lg" style={{ background: "#161618" }}>
                    <Canvas camera={{ position: [0, .1, 1], fov: 85 }} ref={canvasRef} id="canvas" style={{ background: "#161618" }}>
                        <Stage adjustCamera intensity={0.5} shadows="contact" environment="city">
                            <ambientLight intensity={.25} />

                            <Model url={modelUrl} onLoad={() => setModelLoaded(true)} rotation={[0, 0, Math.PI]} />

                            <OrbitControls
                                enableZoom={false}
                                enablePan={false}
                            // minPolarAngle={Math.PI / 2}
                            // maxPolarAngle={Math.PI / 2}
                            />
                        </Stage>
                    </Canvas>
                </div>
            )}
            {error && <p className="text-red-400 mt-4">{error}</p>}


            <div className="mt-4"></div>
        </div>
    );
};

export default CompModelAI;