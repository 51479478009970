import React, { useRef, useMemo, useCallback, useState } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { HexColorPicker } from 'react-colorful';

const LIGHTING_PRESETS = [
    {
        name: "Soft Single",
        lights: [
            { intensity: 0.6, position: [1, 1, 1] }
        ]
    },
    {
        name: "Dramatic Duo",
        lights: [
            { intensity: 0.8, position: [5, 3, 2] },
            { intensity: 0.3, position: [-3, -2, 4] }
        ]
    },
    {
        name: "Tri-Light",
        lights: [
            { intensity: 0.5, position: [2, 2, 2] },
            { intensity: 0.3, position: [-2, -2, 2] },
            { intensity: 0.2, position: [0, 0, -3] }
        ]
    },
    {
        name: "Bright",
        lights: [
            { intensity: 1, position: [0, 0, 15] },
            { intensity: 0.5, position: [2, 2, 3] }
        ]
    },
    {
        name: "Overhead Spotlight",
        lights: [
            { intensity: 1.0, position: [0, 10, 1] },
            { intensity: 1.1, position: [0, -5, 1] }
        ]
    },
    {
        name: "Side Accent",
        lights: [
            { intensity: 1.6, position: [8, 0, 1] },
            { intensity: 0.4, position: [-4, 2, 3] }
        ]
    },
    {
        name: "Soft Box",
        lights: [
            //{ intensity: 4, position: [3, 3, 3] },
            { intensity: 3, position: [0, 0, 12] },
            { intensity: 4, position: [0, -3, 3] }
        ]
    },
    {
        name: "Rim Lighting",
        lights: [
            { intensity: 0.7, position: [0, 0, -5] },
            { intensity: 0.3, position: [3, 3, 3] },
            { intensity: 0.3, position: [-3, 3, 3] }
        ]
    },
    {
        name: "Moody Corner",
        lights: [
            { intensity: 0.8, position: [5, 5, 5] },
            { intensity: 0.1, position: [-3, -3, -3] }
        ]
    },
    {
        name: "Balanced Trio",
        lights: [
            { intensity: 1, position: [4, 4, 4] },
            { intensity: 1, position: [-4, 0, 4] },
            { intensity: 1, position: [0, -4, 4] }
        ]
    },
    {
        name: "Balanced Trio Bright",
        lights: [
            { intensity: 1.3, position: [4, 4, 4] },
            { intensity: 1.3, position: [-4, 0, 4] },
            { intensity: 1.3, position: [0, -4, 4] }
        ]
    },   
    {
        name: "Balanced Trio Brightest",
        lights: [
            { intensity: 2.6, position: [4, 4, 4] },
            { intensity: 2.6, position: [-4, 0, 4] },
            { intensity: 2.6, position: [0, -4, 4] }
        ]
    },   
    {
        name: "Balanced Trio Brightest 2",
        lights: [
            { intensity: 2, position: [4, 4, 4] },
            { intensity: 0, position: [-4, 0, 4] },
            { intensity: 6, position: [0, 0, 8] }
        ]
    },            
    {
        name: "Bottom",
        lights: [
            { intensity: 1, position: [0, 0, 10] },
            { intensity: 0.6, position: [0, -4, 4] }
        ]
    },
    {
        name: "Bottom 2",
        lights: [
            { intensity: .5, position: [0, 0, 10] },
            { intensity: 1, position: [0, -4, 4] }
        ]
    }
];

const MatcapRenderer = ({ size, materialProps, onCapture, lightingConfig }) => {
    const { gl, scene } = useThree();
    const renderTarget = useMemo(() => new THREE.WebGLRenderTarget(size, size), [size]);
    const camZoom = .57;
    const captureCamera = useMemo(() => new THREE.OrthographicCamera(-(camZoom), camZoom, camZoom, -(camZoom), 0.1, 10), []);

    useFrame(() => {
        gl.setRenderTarget(renderTarget);
        gl.render(scene, captureCamera);
        gl.setRenderTarget(null);
    });

    React.useEffect(() => {
        const sphereGeometry = new THREE.SphereGeometry(0.5725, 64, 64);
        const planeGeometry = new THREE.PlaneGeometry(9, 9);

        const material = new THREE.MeshStandardMaterial({
            ...materialProps,
            side: THREE.DoubleSide,
        });

        const sphere = new THREE.Mesh(sphereGeometry, material);
        const plane = new THREE.Mesh(planeGeometry, material);

        plane.position.z = -0.5;

        scene.add(sphere);
        scene.add(plane);

        const lightObjects = lightingConfig.lights.map(light => {
            const pointLight = new THREE.PointLight(0xffffff, light.intensity);
            pointLight.position.set(...light.position);
            scene.add(pointLight);
            return pointLight;
        });

        captureCamera.position.z = 1.5;

        // Set up the capture function
        onCapture(() => {
            gl.setRenderTarget(renderTarget);
            gl.render(scene, captureCamera);
            gl.setRenderTarget(null);

            const pixelBuffer = new Uint8Array(4 * size * size);
            gl.readRenderTargetPixels(renderTarget, 0, 0, size, size, pixelBuffer);

            const canvas = document.createElement('canvas');
            canvas.width = size;
            canvas.height = size;
            const context = canvas.getContext('2d');
            const imageData = context.createImageData(size, size);
            imageData.data.set(pixelBuffer);
            context.putImageData(imageData, 0, 0);

            return canvas.toDataURL('image/png');
        });

        return () => {
            scene.remove(sphere);
            scene.remove(plane);
            lightObjects.forEach(light => scene.remove(light));
            sphereGeometry.dispose();
            planeGeometry.dispose();
            material.dispose();
        };
    }, [scene, captureCamera, materialProps, gl, renderTarget, onCapture, size, lightingConfig]);

    return null;
};

const CompMatcapGen = (props) => {

    // TODO - load any presets from props

    const [color, setColor] = useState('#3a5e8c');
    const [hexInput, setHexInput] = useState('#3a5e8c');
    const [roughness, setRoughness] = useState(0);
    const [metalness, setMetalness] = useState(0);
    const [matcapSize] = useState(1024);
    const captureRef = useRef(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [lightingStyle, setLightingStyle] = useState(0);

    const materialProps = useMemo(() => ({
        color: new THREE.Color(color),
        roughness,
        metalness
    }), [color, roughness, metalness]);

    const onCapture = useCallback((captureFn) => {
        captureRef.current = captureFn;
    }, []);

    const saveImage = useCallback(() => {
        if (captureRef.current) {
            setIsGenerating(true);
            const dataURL = captureRef.current();

            // Convert data URL to Blob
            const fetchResponse = fetch(dataURL);
            fetchResponse.then(res => res.blob()).then(blob => {
                const timestamp = new Date().toISOString().replace(/[:.-]/g, '_');
                const fileName = `matcap_${timestamp}.png`;

                // Create FormData and append the file
                const formData = new FormData();
                formData.append('file', blob, fileName);

                fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/storyFileUpload', {
                    method: 'POST',
                    body: formData,
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data.fileUrl);
                        // UPDATE MATCAP!!!
                        
                        if (props.type == 'shape') {
                            //props.updateStory(props.updateAttribute, data.fileUrl);
                            props.setMatcapUrl(data.fileUrl)
                            console.log('shape matcap saved...')
                        }
                        else
                        {
                            props.updateStory(props.updateAttribute, data.fileUrl);   
                        }                        
                        setIsGenerating(false);
                    })
                    .catch(error => {
                        console.error('Error uploading file:', error);
                        setIsGenerating(false);
                    });
            });
        } else {
            console.error('Capture function not available');
        }
    }, [props]);

    const handleHexInputChange = (e) => {
        setHexInput(e.target.value);
    };

    const handleHexInputBlur = () => {
        // Validate hex input
        const isValidHex = /^#[0-9A-Fa-f]{6}$/i.test(hexInput);
        if (isValidHex) {
            setColor(hexInput);
        } else {
            // Reset to the current color if input is invalid
            setHexInput(color);
        }
    };

    const ButtonGroup = ({ label, value, setValue }) => {
        const buttons = [0, 0.25, 0.5, 0.75, 1];
        return (
            <div className="mb-2">
                <div className="">
                    <div className='flex rounded-md w-full p-1 bg-white/5 gap-1'>
                        <div className="flex-none w-10 px-2 py-1 text-sm">{label}</div>
                        {buttons.map((buttonValue) => (
                            <div
                                key={buttonValue}
                                className={`flex-auto text-center items-center rounded-md transition transition-all duration-250 px-2 py-1 text-sm cursor-pointer
                                ${value === buttonValue ? 'bg-sky-600 text-gray-200' : 'text-gray-300 hover:bg-sky-600/50'}`}
                                onClick={() => setValue(buttonValue)}
                            >
                                {buttonValue}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="text-zinc-300 text-sm mb-4">
            <div className="mb-4">
                <div className="flex items-center mb-2">
                    <HexColorPicker color={color} onChange={setColor} className="mr-2" />

                    <div className="w-12 h-12 border border-gray-300 rounded-md overflow-hidden mb-4">
                        <Canvas>
                            <MatcapRenderer size={matcapSize} materialProps={materialProps} onCapture={onCapture} lightingConfig={LIGHTING_PRESETS[lightingStyle]} />
                        </Canvas>
                        <span>{color}</span>
                    </div>
                </div>
                <input
                    type="text"
                    value={hexInput}
                    onChange={handleHexInputChange}
                    onBlur={handleHexInputBlur}
                    placeholder="Enter hex color"
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
                  text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
                  focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                />
            </div>

            <ButtonGroup label="Rough" value={roughness} setValue={setRoughness} />
            <ButtonGroup label="Metal" value={metalness} setValue={setMetalness} />

            <div className="mb-4">
                <label className="block mb-1">Lighting: {LIGHTING_PRESETS[lightingStyle].name}</label>
                <input
                    type="range"
                    min="0"
                    max={LIGHTING_PRESETS.length - 1}
                    step="1"
                    value={lightingStyle}
                    onChange={(e) => setLightingStyle(parseInt(e.target.value))}
                    className="w-full"
                />
            </div>

            {isGenerating ? (
                <button
                    className="bg-gradient-to-r from-green-400 to-blue-500  text-white   py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out cursor-wait animate-gradient w-full"
                    disabled
                >
                    Generating...
                </button>
            ) : (
                <button
                    className="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer w-full"
                    onClick={saveImage}
                >
                    Update Background
                </button>
            )}
        </div>
    );
};

export default CompMatcapGen;