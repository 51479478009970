import React, { useState, useEffect } from 'react';

// Replace with your actual Cloudflare Worker URL
const CORS_PROXY = "https://gentle-cake-d4fb.john-310.workers.dev/?url=";

//const appURL = "https://fa-ewki-saasfademo1.ds-fa.oraclepdemos.com/fscmUI/redwood/rwdref/ui/ordersref/edit-order?orderId=300000260283669";
const appURL = "https://www.news.com";

const WebAppEmbed = ({ url }) => {
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`${CORS_PROXY}${encodeURIComponent(url)}`);
        
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.status);
        }
        
        const html = await response.text();
        setContent(html);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchContent();
  }, [url]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Embedded Web App Content:</h2>
      <iframe
        src={`${CORS_PROXY}${encodeURIComponent(url)}`}
        style={{ width: '100%', height: '800px', border: 'none' }}
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation"
        title="Embedded Web App"
      />
    </div>
  );
};


function DEV_Embed() {
  return (
    <div className="mt-96">
      <h1>My React App with Embedded Web Content</h1>
      {/* <WebAppEmbed url="https://fa-ewki-saasfademo1.ds-fa.oraclepdemos.com/fscmUI/redwood/rwdref/ui/ordersref/edit-order?orderId=300000260283669" /> */}
      
      <iframe src="https://www.oracle.com" 
            width="800" 
            height="600" 
            allow="*" 
            allowfullscreen="true" 
            allowpaymentrequest="true" 
            allowtransparency="true" 
            csp="" 
            loading="eager" 
            name="open_iframe" 
            referrerpolicy="unsafe-url" 
            sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox allow-downloads allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-top-navigation">
    </iframe>


      {/* <iframe
        src={`https://gentle-cake-d4fb.john-310.workers.dev/?url=${appURL}`}
        style={{ width: '100%', height: '800px', border: 'none' }}
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation"
        title="Embedded Web App"
      /> */}
      
    </div>
  );
}

export default DEV_Embed;

//export default DEV_Embed;