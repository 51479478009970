import React, { useRef, useMemo } from 'react'
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { Cylinder, RoundedBox } from '@react-three/drei'
import * as THREE from 'three'

const MeshTypes = {
  CYLINDER: 1,
  SQUARE: 2,
  RECTANGLE: 3
}

export default function ExpMeshDecal({
  meshType = MeshTypes.CYLINDER,
  dimensions = [1, 1, 3], // [radius, height] for cylinder, [width, height, depth] for square/rectangle
  imageUrl = 'https://example.com/path/to/your/image.png',
  matcapUrl = 'https://example.com/path/to/your/matcap.png',
  roundedEdgeRadius = 0.1,
  roundedEdgeSegments = 8,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  scale = [1, 1, 1],
  imagePosition = [0, 0, 0],
  imageRotation = [0, 0, 0],
  imageScale = 1 // Parameter for scaling the image
}) {
  const meshRef = useRef()
  const imageRef = useRef()

  // Load textures
  const matcapTexture = useLoader(TextureLoader, matcapUrl)
  const imageTexture = useLoader(TextureLoader, imageUrl)

  // Calculate image scale based on mesh dimensions and user-defined scale
  const calculatedImageScale = useMemo(() => {
    const [width, height, depth] = dimensions
    const maxDimension = Math.max(width, height, depth)
    const baseScale = new THREE.Vector3(1, 1, 1).multiplyScalar(maxDimension * 0.5)
    return baseScale.multiplyScalar(imageScale)
  }, [dimensions, imageScale])

  // Render appropriate mesh based on type
  const renderMesh = () => {
    const MeshComponent = meshType === MeshTypes.CYLINDER ? Cylinder : RoundedBox
    const meshProps = meshType === MeshTypes.CYLINDER
      ? { args: [dimensions[0], dimensions[0], dimensions[1], 32] }
      : { args: dimensions, radius: roundedEdgeRadius, smoothness: roundedEdgeSegments }

    return (
      <MeshComponent ref={meshRef} {...meshProps}>
        <meshMatcapMaterial matcap={matcapTexture} />
      </MeshComponent>
    )
  }

  return (
    <group position={position} rotation={rotation} scale={scale}>
      {renderMesh()}
      <mesh
        ref={imageRef}
        position={imagePosition}
        rotation={imageRotation}
        scale={calculatedImageScale}
      >
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial map={imageTexture} transparent={true} side={THREE.DoubleSide} />
      </mesh>
    </group>
  )
}



// import React, { useRef, useMemo } from 'react'
// import { useLoader } from '@react-three/fiber'
// import { TextureLoader } from 'three/src/loaders/TextureLoader'
// import { Decal, Cylinder, RoundedBox } from '@react-three/drei'
// import * as THREE from 'three'

// const MeshTypes = {
//   CYLINDER: 1,
//   SQUARE: 2,
//   RECTANGLE: 3
// }

// export default function ExpMeshDecal({
//   meshType = MeshTypes.CYLINDER,
//   dimensions = [1, 1, 3], // [radius, height] for cylinder, [width, height, depth] for square/rectangle
//   decalUrl = 'https://example.com/path/to/your/decal.png',
//   matcapUrl = 'https://example.com/path/to/your/matcap.png',
//   roundedEdgeRadius = 0.1,
//   roundedEdgeSegments = 8,
//   position = [0, 0, 0],
//   rotation = [0, 0, 0],
//   scale = [1, 1, 1],
//   decalPosition = [0, 0, 0],
//   decalRotation = [0, 0, 0],
//   decalScale = 1 // New parameter for scaling the decal
// }) {
//   const meshRef = useRef()

//   // Load textures
//   const matcapTexture = useLoader(TextureLoader, matcapUrl)
//   const decalTexture = useLoader(TextureLoader, decalUrl)

//   // Calculate decal scale based on mesh dimensions and user-defined scale
//   const calculatedDecalScale = useMemo(() => {
//     const [width, height, depth] = dimensions
//     const maxDimension = Math.max(width, height, depth)
//     const baseScale = new THREE.Vector3(1, 1, 1).multiplyScalar(maxDimension * 0.5)
//     return baseScale.multiplyScalar(decalScale)
//   }, [dimensions, decalScale])

//   // Render appropriate mesh based on type
//   const renderMesh = () => {
//     const MeshComponent = meshType === MeshTypes.CYLINDER ? Cylinder : RoundedBox
//     const meshProps = meshType === MeshTypes.CYLINDER
//       ? { args: [dimensions[0], dimensions[0], dimensions[1], 32] }
//       : { args: dimensions, radius: roundedEdgeRadius, smoothness: roundedEdgeSegments }

//     return (
//       <MeshComponent ref={meshRef} {...meshProps}>
//         <meshMatcapMaterial matcap={matcapTexture} />
//         <Decal
//           position={decalPosition}
//           rotation={decalRotation}
//           scale={calculatedDecalScale}
//           map={decalTexture}
//         />
//       </MeshComponent>
//     )
//   }

//   return (
//     <group position={position} rotation={rotation} scale={scale}>
//       {renderMesh()}
//     </group>
//   )
// }